export default ({ app, store }, inject) => {
	const klaviyo = {
		apiUrl: {
			client: 'https://static.klaviyo.com/onsite/js/klaviyo.js',
			server: 'https://a.klaviyo.com/api/track',
		},
		apiKey:
			process.env.BF_ENV === 'production'
				? 'Uhg7j2'
				: 'RTxYtM',
		init() {
			if ('false' === 'true') {
				console.log(
					'[klaviyo-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					{
						init: this.apiKey,
					}
				);
			}

			if ('false' === 'true') {
				return;
			}

			const activeScript = document.createElement('script');
			const publicApiKey = this.apiKey;

			activeScript.async = true;
			activeScript.src = `${this.apiUrl.client}?company_id=${publicApiKey}`;
			document.getElementsByTagName('head')[0].appendChild(activeScript);
		},
		identify(email = store.state.user.personalInformation.email) {
			if (!email) {
				return;
			}

			if ('false' === 'true') {
				console.log(
					'[klaviyo-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					{
						identify: email,
					}
				);
			}

			if ('false' === 'true') {
				return;
			}

			const identifyUser = () => {
				clearInterval(checkingScript);
				window._learnq.push(['identify', { $email: email }]);
			};

			const checkingScript = setInterval(() => {
				if (typeof window._learnq !== 'undefined') {
					identifyUser();
				}
			}, 1000);
		},
		track(behavior, item) {
			if (!behavior || !item) {
				return;
			}

			if ('false' === 'true') {
				if (behavior !== 'Placed Order') {
					console.log(
						'[klaviyo-module]',
						`(${
							'false' === 'true'
								? 'disabled'
								: 'enabled'
						})`,
						{
							track: behavior,
							item,
						}
					);
				} else {
					item.token = this.apiKey;
					item.event = behavior;

					console.log('[klaviyo-module]', {
						item,
						req: `${this.apiUrl.server}?data=${encodeURIComponent(
							window.btoa(JSON.stringify(item))
						)}`,
					});
				}
			}

			if ('false' === 'true') {
				return;
			}
			const _learnq = window._learnq || [];

			if (behavior === 'trackViewedItem') {
				_learnq.push([behavior, item]);
			} else if (behavior !== 'Placed Order') {
				_learnq.push(['track', behavior, item]);
			} else {
				item.token = this.apiKey;
				item.event = behavior;

				app.$axios.$get(this.apiUrl.server, {
					params: {
						data: encodeURIComponent(
							window.btoa(JSON.stringify(item))
						),
					},
				});
			}
		},
		load() {
			this.init();
		},
	};

	inject('klaviyo', klaviyo);
};
