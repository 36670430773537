import LazyLoad from 'vanilla-lazyload';
import useObjectfit from '@/use/objectfit.js';

import 'intersection-observer';

class LazyLoadPrototype {
	constructor(selector) {
		this.params = {
			threshold: 400,
			elements_selector: selector,
			class_error: 'is-lazy-error',
			class_loading: 'is-lazy-loading',
			class_loaded: 'is-lazy-loaded',
			cancel_on_exit: false,
			callback_loaded: (element) => {
				useObjectfit.fallback(element);
			},
		};
	}

	init() {
		this.instance = new LazyLoad(this.params);
	}

	update() {
		setTimeout(() => {
			this.instance.update();
		}, 1);
	}

	load(el) {
		LazyLoad.load(el, this.params);
	}
}

export default {
	init() {
		this.generic.init();
	},
	generic: new LazyLoadPrototype('.is-lazy'),
};
