export default function ({ redirect, route, store }) {
	const addresses = store.state.user.address.entries;
	const section = route.params.section;

	switch (route.name) {
		case 'checkout-add-address':
			if (addresses.length < 1 && typeof section === 'undefined') {
				redirect('/checkout/add-address/shipping');
			}
			break;

		case 'checkout-edit-address':
			if (addresses.length < 2 && typeof section !== 'undefined') {
				redirect(`/checkout/edit-address/${route.params.id}`);
			}
			break;

		default:
	}
}
