<template>
	<div>
		<slot
			name="expander"
			:is-expanded="isExpanded"
			:toggle-expand="toggleExpand"
		/>
		<SlideUpDown
			:class="expandClass"
			:is-active="isExpanded"
			:duration="duration"
		>
			<slot name="expand" :is-expanded="isExpanded" />
		</SlideUpDown>
	</div>
</template>

<script>
	export default {
		props: {
			expandableOn: {
				type: String,
				default: 'ti-up',
			}, // active breakpoint(s)
			expandedOnLoad: {
				type: Boolean,
				default: false,
			}, // whether expand initially
			expandClass: {
				type: String,
				default: '',
			},
		},
		data() {
			return {
				isMounted: false,
				isExpanded: this.expandedOnLoad,
				duration: 250,
			};
		},
		mounted() {
			this.isMounted = true;
		},
		methods: {
			toggleExpand() {
				if (this.$mq.is(this.expandableOn)) {
					this.isExpanded = !this.isExpanded;
				}
				this.$emit('toggleExpand', this.isExpanded);
			},
		},
	};
</script>
