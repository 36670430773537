var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.dismiss),expression:"dismiss"}],ref:"Search",staticClass:"search",style:(_vm.style)},[_c('div',{staticClass:"search-container"},[_c('form',{staticClass:"search-form",attrs:{"role":"search","method":_vm.form.method,"action":_vm.formQueryPath},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('button',{staticClass:"search-form-button search-form-button--back",attrs:{"type":"button","title":_vm.$__('Back'),"aria-label":_vm.$__('Back')},on:{"click":function($event){return _vm.dismiss()}}}),_vm._v(" "),_c('div',{staticClass:"search-form-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.query),expression:"form.query"}],staticClass:"search-form-input",attrs:{"id":_vm.inputId,"autocomplete":"off","type":"search"},domProps:{"value":(_vm.form.query)},on:{"keydown":_vm.onKeydown,"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "query", $event.target.value)},function($event){_vm.form.query = $event.target.value}]}}),_vm._v(" "),_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.isInputLabelVisible),expression:"isInputLabelVisible"}],staticClass:"search-form-label",attrs:{"for":_vm.inputId},domProps:{"textContent":_vm._s(_vm.$__('Search'))}})]),_vm._v(" "),_c('button',{class:[
					'search-form-button',
					'search-form-button--start',
					{
						'is-loading':
							_vm.isLoading && _vm.formQuery.length > _vm.minQueryLength,
					},
				],attrs:{"type":"submit","title":_vm.$__('Search'),"aria-label":_vm.$__('Search')}},[_vm._m(0),_vm._v(" "),_c('Spinner',{staticClass:"btn-spinner"})],1)]),_vm._v(" "),_c('div',{ref:"SearchList",staticClass:"search-list"},[(_vm.hasSuggestions)?[(_vm.suggestions.brands.length > 0)?_c('div',{staticClass:"search-list-category search-list-category--brands"},[_c('h3',{staticClass:"search-list-category-heading"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$__('Brands'))+"\n\t\t\t\t\t")]),_vm._v(" "),_vm._l((_vm.suggestions.brands),function(brand,index){return _c('div',{key:`search-list-item-brand--${index}`,ref:"SearchListItem",refInFor:true,class:[
							'search-list-item',
							{
								'is-selected':
									_vm.selectedListItemIndex === index,
							},
						],attrs:{"data-index":index},on:{"mouseenter":function($event){return _vm.onMouseEnterListItem($event)},"mouseleave":function($event){return _vm.onMouseLeaveListItem($event)}}},[_c('NuxtLink',{staticClass:"search-list-item-link",attrs:{"to":brand.url},domProps:{"textContent":_vm._s(brand.title)},nativeOn:{"click":function($event){return _vm.navigate(brand)}}})],1)})],2):_vm._e(),_vm._v(" "),(_vm.suggestions.categories.length > 0)?_c('div',{staticClass:"search-list-category search-list-category--categories"},[_c('h3',{staticClass:"search-list-category-heading"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$__('Categories'))+"\n\t\t\t\t\t")]),_vm._v(" "),_vm._l((_vm.suggestions.categories),function(category,index){return _c('div',{key:`search-list-item-category--${index}`,ref:"SearchListItem",refInFor:true,class:[
							'search-list-item',
							{
								'is-selected':
									_vm.selectedListItemIndex ===
									_vm.suggestions.brands.length + index,
							},
						],attrs:{"data-index":_vm.suggestions.brands.length + index},on:{"mouseenter":function($event){return _vm.onMouseEnterListItem($event)},"mouseleave":function($event){return _vm.onMouseLeaveListItem($event)}}},[_c('NuxtLink',{staticClass:"search-list-item-link",attrs:{"to":category.url},domProps:{"textContent":_vm._s(category.title)},nativeOn:{"click":function($event){return _vm.navigate(category)}}})],1)})],2):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.hasRecent && _vm.formQuery.length < 1)?_c('div',{staticClass:"search-list-category search-list-category--recent"},[_c('h3',{staticClass:"search-list-category-heading"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$__('Recent search'))+"\n\t\t\t\t")]),_vm._v(" "),_vm._l((_vm.recentItems),function(item,index){return _c('div',{key:`search-list-item-recent--${index}`,ref:"SearchListItem",refInFor:true,class:[
						'search-list-item',
						{
							'is-selected':
								_vm.selectedListItemIndex ===
								_vm.suggestions.brands.length + index,
						},
					],attrs:{"data-index":index},on:{"mouseenter":function($event){return _vm.onMouseEnterListItem($event)},"mouseleave":function($event){return _vm.onMouseLeaveListItem($event)}}},[_c('NuxtLink',{staticClass:"search-list-item-link",attrs:{"to":item.url},domProps:{"textContent":_vm._s(item.title)},nativeOn:{"click":function($event){return _vm.navigate(item)}}}),_vm._v(" "),_c('button',{staticClass:"search-list-item-remove",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeFromRecentItems(item)}}},[_c('i',{staticClass:"icon-cross"})])],1)})],2):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"btn-inner"},[_c('i',{staticClass:"search-button--start-icon icon-search"})])
}]

export { render, staticRenderFns }