
	import Vue from 'vue';
	import type { PropType } from 'vue';
	import { ApiNavigationItem } from '@/types/common';
	export default Vue.extend({
		props: {
			item: {
				type: Object as PropType<ApiNavigationItem>,
				required: true,
			},
			isClickable: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			hasArrow() {
				return (
					Boolean(this.item.children?.length) ||
					Boolean(this.item.sections?.length) ||
					this.isClickable
				);
			},
			buttonComponent() {
				return (this.item.sections && this.item.sections.length > 0) ||
					(this.item.children && this.item.children.length > 0) ||
					this.item.isClickable
					? 'button'
					: 'NuxtLink';
			},
			isLink() {
				return this.buttonComponent === 'NuxtLink';
			},
		},
		methods: {
			getSectionItemLabel(item) {
				switch (item.label) {
					case 'isComingSoon':
						return 'Coming Soon';

					case 'isNew':
						return 'New';

					default:
						return '';
				}
			},
			getSectionItemClass(item) {
				return this.$case(item.label) || null;
			},
		},
	});
