export default ({ path, params, token }) => {
	const appId = '1597308965';

	const hostUrl = `${process.env.HOST_URL}/magic/`;

	let appArgument = new URL(hostUrl);

	const data = {
		...(!!token && { t: token }),
		...params,
		redirect: path,
	};

	if (Object.keys(data).length > 0) {
		const urlParams = new URLSearchParams(data);
		appArgument.search = `${urlParams}`;
	}

	return {
		name: 'apple-itunes-app',
		content: `app-id=${appId}, app-argument=${appArgument}`,
	};
};
