import { actionTree, getterTree, mutationTree } from 'typed-vuex';

export const state = () => ({
	isTabbing: false,
	isBodyfixOn: false,
	isBackdropOn: false,
	isOverlayOn: false,
	isOverlayLoading: false,
	overlaySwitchDelay: 500,
	overlayType: false,
	overlayData: false,
	isNavMobileOn: false,
	isNavTabletOn: false,
	isNavDropmenuOn: false,
	isBottomMenuMobileOn: true,
	dropmenuItem: null,
	isHelpDropmenu: false,
	isLocalizationDropmenu: false,
	isNavMobileBackdropOn: false,
	searchInputId: 'search-input',
	isSearchOn: false,
	isCartExpandableOn: false,
	isLocalizationLoading: false,
	filterUpdateOffset: 0,
	filterUpdatingPath: false,
	filterExpandedSections: false as Record<string, boolean> | false,
	filterHistory: [] as any[],
	isFilterPage: false,
	isFilterMobileOn: false,
	isFilterUpdating: false,
	pinnedTimeout: 750,
	pinnedTimeoutLong: 2500,
	pinnedTimeoutList: 2000,
	utsStartLoading: null,
	utsRefreshInterval: 1000,
	utsNow: Date.now(),
	previousRoute: null,
	device: {},
});

export const mutations = mutationTree(state, {
	RESET_FILTER_HISTORY(state) {
		state.filterHistory.splice(0, state.filterHistory.length);
	},
	POP_FILTER_HISTORY(state) {
		state.filterHistory.pop();
	},
	SET_ACTIVE_FILTER_PAGE(state, payload: any) {
		state.filterHistory.push(payload);
	},
	SET_DATA(state, data) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		state = Object.assign(state, data);
	},
	toggleLocalizationLoading(state) {
		state.isLocalizationLoading = !state.isLocalizationLoading;
	},
	toggleNavMobile(state) {
		state.isNavMobileOn = !state.isNavMobileOn;
	},
	toggleNavTablet(state) {
		state.isNavTabletOn = !state.isNavTabletOn;
	},
	toggleSearch(state) {
		state.isSearchOn = !state.isSearchOn;
	},
	toggleBottomMenuMobile(state) {
		state.isBottomMenuMobileOn = !state.isBottomMenuMobileOn;
	},
	setDropmenuItem(state, payload) {
		state.dropmenuItem = payload;
	},
	setIsHelpDropmenu(state, payload) {
		state.isHelpDropmenu = payload;
	},
	setIsLocalizationDropmenu(state, payload) {
		state.isLocalizationDropmenu = payload;
	},
	setPreviousRoute(state, payload) {
		state.previousRoute = payload;
	},
	setDevice(state, payload) {
		state.device = payload;
	},
});

export const getters = getterTree(state, {
	activeFilterPageHeader(state, getters, rootState) {
		const page = getters.getCurrentFilterPage;

		if (!page) {
			return {
				title: 'Filter',
				clearTitle: rootState.page.content.filter.headerButton?.title,
				url: rootState.page.content.filter.headerButton?.url,
			};
		}

		switch (page.type) {
			case 'designers':
			case 'category':
			case 'section':
				if (!page.headerButton) {
					return { title: page.headerTitle || page.title };
				}

				return {
					title: page.headerTitle || page.title,
					clearTitle: page.headerButton.title,
					url: page.headerButton.url,
				};

			case 'index':
				return {
					title: 'Filter',
					clearTitle:
						rootState.page.content.filter?.headerButton?.title,
					url: rootState.page.content.filter?.headerButton?.url,
				};
		}
	},
	getCurrentFilterPage(state, getters) {
		if (!state.filterHistory.length) {
			return {
				component: 'indexFilter',
				type: 'index',
			};
		}
		const currentItem = state.filterHistory[state.filterHistory.length - 1];
		const { id, name } = currentItem;

		return getters.getCurrentFilterPageId(id || name);
	},

	getCurrentFilterPageId:
		(state, getters, rootState, rootGetters) => (id: number | string) => {
			const { type, component } =
				state.filterHistory[state.filterHistory.length - 1];
			switch (type) {
				case 'index':
					return {
						component: 'indexFilter',
						type,
					};
				case 'section':
				case 'designers':
					return {
						...rootState.page.content.filter.sections.find(
							(e: any) => e.name === id
						),
						component,
						type,
					};

				case 'category':
					return {
						...rootGetters['page/getCategoryById'](id),
						component,
						type,
					};
			}
		},
});

export const actions = actionTree(
	{ state, mutations },
	{
		onFiltersBackButton({ state, dispatch, commit }) {
			commit('POP_FILTER_HISTORY');
		},
		toggleCartExpandable({ commit, state }, isCartExpandableOn) {
			commit('SET_DATA', {
				isCartExpandableOn:
					typeof isCartExpandableOn === 'undefined'
						? !state.isCartExpandableOn
						: isCartExpandableOn,
			});
		},
		startUtsNowTimer({ state, commit }) {
			setInterval(() => {
				commit('SET_DATA', {
					utsNow: Date.now(),
				});
			}, state.utsRefreshInterval);
		},
		toggleTabbing({ commit, state }, isTabbing) {
			commit('SET_DATA', {
				isTabbing:
					typeof isTabbing === 'undefined'
						? !state.isTabbing
						: isTabbing,
			});
		},
		toggleBackdrop({ commit, state }, isBackdropOn) {
			if (state.isBackdropOn === isBackdropOn) {
				return;
			}
			commit('SET_DATA', {
				isBackdropOn:
					typeof isBackdropOn === 'undefined'
						? !state.isBackdropOn
						: isBackdropOn,
			});
		},
		toggleBodyfix({ commit, state }, isBodyfixOn) {
			if (state.isBodyfixOn === isBodyfixOn) {
				return;
			}
			commit('SET_DATA', {
				isBodyfixOn:
					typeof isBodyfixOn === 'undefined'
						? !state.isBodyfixOn
						: isBodyfixOn,
			});

			// @ts-ignore
			if (this.$bodyfix != null) {
				// @ts-ignore
				this.$bodyfix.updateStyle();
			}
		},
		toggleOverlay({ commit, dispatch, state }, payload) {
			let timeout = 0;

			switch (true) {
				case state.isOverlayOn && payload:
					timeout = state.overlaySwitchDelay;
					dispatch('toggleOverlay', false);
					break;

				case state.isFilterMobileOn:
					dispatch('toggleFilterMobile', false);
					break;

				case state.isSearchOn:
					dispatch('toggleSearch', false);
					break;

				case state.isNavTabletOn:
					commit('toggleNavTablet');
					break;

				default:
			}

			setTimeout(() => {
				const hasPayload = !!payload;
				const hasValidType = hasPayload && payload.overlayType;
				const hasValidData = hasPayload && payload.overlayData;

				const data = {
					isOverlayOn: hasPayload,
					overlayType: false,
					overlayData: false,
				};

				if (hasValidType) {
					data.overlayType = payload.overlayType;
				}

				if (hasValidData) {
					data.overlayData = payload.overlayData;
				}

				commit('SET_DATA', data);
			}, timeout);
		},
		toggleNav({ commit, dispatch, state }, type = 'mobile') {
			if (state.isOverlayOn) {
				dispatch('toggleOverlay', false);
			} else if (state.isFilterMobileOn) {
				dispatch('toggleFilterMobile', false);
			} else if (state.isSearchOn) {
				dispatch('toggleSearch', false);
			}

			type === 'mobile'
				? commit('toggleNavMobile')
				: commit('toggleNavTablet');
		},
		toggleSearch({ commit, dispatch, state }, isSearchOn) {
			let timeout = 0;

			if (state.isOverlayOn) {
				timeout = state.overlaySwitchDelay;
				dispatch('toggleOverlay', false);
			} else if (state.isFilterMobileOn) {
				dispatch('toggleFilterMobile', false);
			} else if (state.isNavMobileOn || state.isNavTabletOn) {
				timeout = state.overlaySwitchDelay;
				commit('toggleNavMobile');
			}

			commit('SET_DATA', {
				isSearchOn:
					typeof isSearchOn === 'undefined'
						? !state.isSearchOn
						: isSearchOn,
			});
		},
		toggleFilterMobile(
			{ commit, dispatch, state },
			isFilterMobileOn: boolean
		) {
			let timeout = 0;

			if (state.isOverlayOn) {
				timeout = state.overlaySwitchDelay;
				dispatch('toggleOverlay', false);
			} else if (state.isSearchOn) {
				dispatch('toggleSearch', false);
			}

			if (timeout) {
				setTimeout(() => {
					exec();
				}, timeout);
			} else {
				exec();
			}

			function exec() {
				commit('toggleBottomMenuMobile');
				const isFilterMobileOnValue =
					typeof isFilterMobileOn === 'undefined'
						? !state.isFilterMobileOn
						: isFilterMobileOn;
				commit('SET_DATA', {
					isFilterMobileOn: isFilterMobileOnValue,
				});
				dispatch('toggleBodyfix', isFilterMobileOnValue);
			}
		},
		toggleFilterSection(
			{ commit, state },
			payload: {
				sectionName: string;
				isExpanded: boolean;
			}
		) {
			const filterExpandedSections = {
				...state.filterExpandedSections,
			} as Record<string, boolean>;

			// if (!payload.isOnMobile) {
			// 	filterExpandedSections[payload.sectionName] = !(
			// 		state.filterExpandedSections as Record<string, boolean>
			// 	)[payload.sectionName as string];
			// } else {
			// 	Object.keys(filterExpandedSections).forEach((section) => {
			// 		filterExpandedSections[section] = false;
			// 	});
			//
			// 	filterExpandedSections[payload.sectionName] = true;
			// }

			filterExpandedSections[payload.sectionName] = payload.isExpanded;

			commit('SET_DATA', {
				filterExpandedSections,
			});
			// @ts-ignore: TODO make page store ts and update vuex
			commit('page/SET_EXPANDED_FILTERS', filterExpandedSections, {
				root: true,
			});
		},

		updateFilter({ commit, state }, event) {
			if (state.isFilterUpdating) {
				return;
			}

			const link = event.currentTarget ?? event.target;

			link.classList.add('is-loading');
			commit('SET_DATA', {
				isFilterUpdating: true,
				filterUpdateOffset: window.pageYOffset,
				filterUpdatingPath: link.pathname,
			});
		},
		setFilterLoading({ commit }, { url, isLoading }) {
			commit('SET_DATA', {
				isFilterUpdating: isLoading,
				filterUpdateOffset: window.pageYOffset,
				filterUpdatingPath: url,
			});
		},
	}
);
