export default ({ app, store }) => {
	// UI clearance on each route change
	app.router.beforeEach((to, from, next) => {
		store.commit('ui/setPreviousRoute', from.name); // for back button
		if (store.state.ui.isOverlayOn) {
			store.dispatch('ui/toggleOverlay', false);
		}

		if (store.state.ui.isSearchOn) {
			store.dispatch('ui/toggleSearch', false);
		}

		if (store.state.ui.isNavMobileOn) store.commit('ui/toggleNavMobile');
		if (store.state.ui.isNavTabletOn) store.commit('ui/toggleTabletMobile');
		store.commit('mobileNavigation/RESET_MENU_HISTORY');

		next();
	});
};
