import Vue from 'vue';
import VueTranslate from 'vue-translate-plugin';

import en from '@/assets/json/translations/en.json';

const $__ = {
	methods: {
		$__(t, params = null) {
			let translation = this.$translate.text(t);

			if (params && params !== null && typeof params !== 'undefined') {
				Object.keys(params).forEach((key) => {
					translation = translation.replace(`%${key}%`, params[key]);
				});
			}
			return translation;
		},
	},
};

Vue.use(VueTranslate);
Vue.mixin($__);

Vue.locales({
	en: parseLocaleJson(en),
});

function parseLocaleJson(json) {
	const strings = Object.keys(json.locale_data.messages).filter((s) => {
		return s !== '';
	});
	const output = {};

	strings.forEach((s) => {
		output[s] = json.locale_data.messages[s][0];
	});

	return output;
}

export default $__;
