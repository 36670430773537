import animatedScrollTo from 'animated-scroll-to';

export default {
	$store: {},
	elId: '__strip',
	offset: 0,
	isAnimatedScrollToTop: false,
	init(store) {
		this.$store = store;
	},
	updateStyle() {
		const el = document.getElementById(this.elId);

		if (this.$store.state.ui.isBodyfixOn) {
			Object.assign(el.style, this.getStyle(this.calculateOffset()));
		} else {
			Object.assign(el.style, this.getStyle());
			window.scrollTo(0, this.offset);
			setTimeout(() => {
				if (this.isAnimatedScrollToTop) {
					this.isAnimatedScrollToTop = false;
					animatedScrollTo(0);
				}
				this.offset = 0;
			}, 0);
		}
	},
	getStyle(offset = '') {
		return {
			top: offset,
			position: offset ? 'fixed' : '',
		};
	},
	setAnimatedScrollToTop() {
		this.isAnimatedScrollToTop = true;
	},
	calculateOffset() {
		const body = document.scrollingElement || document.documentElement;
		const scrolledFromTop =
			document.getElementById(this.elId).offsetTop - body.scrollTop;

		this.offset = scrolledFromTop * -1;
		return scrolledFromTop + 'px';
	},
};
