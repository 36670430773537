var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav--mobile-menu-top"},[_c('nav',{staticClass:"nav--mobile-menu-top-wrapper"},[_c('ul',{staticClass:"nav--mobile-menu-top-list"},[_c('li',{staticClass:"nav--mobile-menu-top-item"},[_c('button',{class:[
						'nav--mobile-menu-top--back',
						{
							active:
								_vm.isMenuOn ||
								_vm.isNavMobileOn ||
								_vm.previousRouteExists,
						},
					],attrs:{"type":"button","title":_vm.$__('Back to main menu'),"aria-label":_vm.$__('Back to main menu')},on:{"click":function($event){return _vm.goBack()}}})]),_vm._v(" "),_c('li',{staticClass:"nav--mobile-menu-top-item"},[_c('NuxtLink',{staticClass:"nav--mobile-menu-top--logo",attrs:{"to":"/","title":"BAMBINIFASHION.COM","aria-label":"BAMBINIFASHION.COM"},nativeOn:{"click":function($event){return _vm.scrollTopIfSameRouteName('index')}}},[_c('i',{staticClass:"icon-logo"})])],1),_vm._v(" "),_c('li',{staticClass:"nav--mobile-menu-top-item"},[_c('NuxtLink',{staticClass:"nav--mobile-menu-top--wishlist",attrs:{"title":_vm.$__('Wishlist'),"aria-label":_vm.$__('Wishlist'),"to":"/wishlist"},nativeOn:{"click":function($event){return _vm.scrollTopIfSameRouteName('wishlist')}}},[_c('i',{staticClass:"icon-heart--empty"},[_c('TopCounter',{attrs:{"count":_vm.counters.wishlist}})],1)])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }