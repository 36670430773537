export default ({ app, store }) => {
	app.router.afterEach(async (to, from) => {
		await store.dispatch('user/getCounters');

		if (
			to.name === 'all' &&
			to.path.endsWith('.html') &&
			store.state.user.counters.wishlist > 0
		) {
			store.dispatch('user/getWishlist');
		}
	});
};
