const middleware = {}

middleware['api'] = require('../middleware/api.js')
middleware['api'] = middleware['api'].default || middleware['api']

middleware['checkout-guest-address'] = require('../middleware/checkout-guest-address.js')
middleware['checkout-guest-address'] = middleware['checkout-guest-address'].default || middleware['checkout-guest-address']

middleware['checkout-payment-return'] = require('../middleware/checkout-payment-return.js')
middleware['checkout-payment-return'] = middleware['checkout-payment-return'].default || middleware['checkout-payment-return']

export default middleware
