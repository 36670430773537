<template>
	<div id="__frontend">
		<div id="__strip" class="strip" :aria-hidden="isOverlayOn">
			<Nuxt />
		</div>
		<Overlay id="__overlay" />
	</div>
</template>

<script>
	import localeValidate from '@/assets/json/localization/validate.json';
	import { addClientMetaHeader } from '@/plugins/add-client-meta-header';
	export default {
		components: {
			Overlay: () =>
				import(
					'@/components/Overlay/Overlay.vue' /* webpackChunkName: "overlay" */
				),
		},
		head() {
			return {
				htmlAttrs: {
					lang: this.htmlLang,
					class: this.htmlClasses,
					dir: this.htmlDir,
				},
				bodyAttrs: {
					class: this.bodyClasses,
				},
				title: this.meta.title,
				meta: this.meta.entries,
				link: [
					{
						rel: 'canonical',
						href:
							this.meta.canonicalUrl ||
							`${process.env.HOST_URL}${this.$route.path}`,
					},
				],
			};
		},
		computed: {
			htmlLang() {
				return (
					this.$store.state.user.localization.language ||
					localeValidate.language[0]
				);
			},
			htmlDir() {
				return localeValidate.isRtl.includes(this.htmlLang)
					? 'rtl'
					: 'ltr';
			},
			htmlClasses() {
				const classes = [];

				classes.push('is-blank');

				return classes.join(' ');
			},
			meta() {
				return this.$store.getters['page/GET_META'](this.$route);
			},
			isOverlayOn() {
				return this.$store.state.ui.isOverlayOn;
			},
		},
		mounted() {
			const path = this.$router.currentRoute.path;
			const token = this.$store.state.user.token;

			const params = this.$store.state['ad-params'].params;
			addClientMetaHeader(path, token, params);
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/pages/category.scss';
</style>
