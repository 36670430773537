var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featured-categories"},_vm._l((_vm.data.categories),function(category,index){return _c('div',{key:`featured-category--${index}`,staticClass:"featured-category"},[_c('NuxtLink',{staticClass:"featured-category-link",attrs:{"to":category.linkUrl},nativeOn:{"click":function($event){return _vm.$track('featured-category', { category })}}},[_c('figure',{staticClass:"featured-category-inner has-objectfit"},[_c('picture',[_c('source',{attrs:{"media":"(min-width: 768px)","data-srcset":_vm.$dataSrc({
								url: category.image.src,
								size: 'desktop',
								isHiRes: true,
								extension: 'webp',
								hash: category.image.hash,
							}),"type":"image/webp"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 429px)","data-srcset":_vm.$dataSrc({
								url: category.image.src,
								size: 'tablet',
								isHiRes: true,
								extension: 'webp',
								hash: category.image.hash,
							}),"type":"image/webp"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 1px)","data-srcset":_vm.$dataSrc({
								url: category.image.src,
								size: 'mobile',
								isHiRes: true,
								extension: 'webp',
								hash: category.image.hash,
							}),"type":"image/webp"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 768px)","data-srcset":_vm.$dataSrc({
								url: category.image.src,
								size: 'desktop',
								isHiRes: true,
								hash: category.image.hash,
							}),"type":"image/jpeg"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 429px)","data-srcset":_vm.$dataSrc({
								url: category.image.src,
								size: 'tablet',
								isHiRes: true,
								hash: category.image.hash,
							}),"type":"image/jpeg"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 1px)","data-srcset":_vm.$dataSrc({
								url: category.image.src,
								size: 'mobile',
								isHiRes: true,
								hash: category.image.hash,
							}),"type":"image/jpeg"}}),_vm._v(" "),_c('LazyImg',{staticClass:"featured-category-image is-objectfit",attrs:{"data-src":_vm.$dataSrc({
								url: category.image.src,
								size: 'desktop',
								hash: category.image.hash,
							}),"alt":category.title,"data-object-position":"center center"}})],1),_vm._v(" "),_c('figcaption',{staticClass:"featured-category-caption"},[_c('h3',{staticClass:"featured-category-caption-title",domProps:{"textContent":_vm._s(category.title)}})])])])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }