export default {
	init(store) {
		const onFirstTab = (event) => {
			if (event.keyCode === 9) {
				store.dispatch('ui/toggleTabbing', true);

				window.removeEventListener('keydown', onFirstTab);
				window.addEventListener('mousedown', onMouseDown);
			}
		};
		const onMouseDown = () => {
			store.dispatch('ui/toggleTabbing', false);

			window.removeEventListener('mousedown', onMouseDown);
			window.addEventListener('keydown', onFirstTab);
		};

		window.addEventListener('keydown', onFirstTab);
	},
};
