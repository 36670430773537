<template>
	<footer class="footer">
		<div class="footer-middle">
			<div class="footer-container">
				<!-- TrustBox widget - Carousel -->
				<!-- TrustBox widget - Horizontal -->
				<div
					v-if="!device.isMobile"
					ref="trustbox-desktop"
					class="trustpilot-widget-footer"
					data-locale="en-US"
					data-template-id="5406e65db0d04a09e042d5fc"
					data-businessunit-id="5f9296d7433bcb00017ba5c8"
					data-style-height="28px"
					data-style-width="100%"
					data-theme="light"
				>
					<a
						href="https://www.trustpilot.com/review/bambinifashion.com"
						target="_blank"
						rel="noopener"
						>Trustpilot</a
					>
				</div>
				<!-- End TrustBox widget -->
				<div
					v-else
					ref="trustbox-mobile"
					class="trustpilot-widget-footer"
					data-locale="en-US"
					data-template-id="53aa8912dec7e10d38f59f36"
					data-businessunit-id="5f9296d7433bcb00017ba5c8"
					data-style-height="140px"
					data-style-width="100%"
					data-theme="light"
					data-stars="1,2,3,4,5"
					data-review-languages="en"
				>
					<a
						href="https://www.trustpilot.com/review/bambinifashion.com"
						target="_blank"
						rel="noopener"
						>Trustpilot</a
					>
				</div>

				<!-- End TrustBox widget -->
			</div>
		</div>
		<div class="footer-top">
			<div class="footer-container">
				<FooterExpandable
					name="about"
					:title="$__('About BAMBINIFASHION')"
				>
					{{
						$__(
							`BAMBINIFASHION.COM is a sophisticated children's boutique store and lifestyle destination in Europe. This is the place where the most prestigious designers and richest collections for the little ones blend seamlessly with the most outstanding and personalized service.`
						)
					}}
					<NuxtLink :to="'/info/about-us'" class="footer-inline-link">
						{{ $__('Read more...') }}
					</NuxtLink>
				</FooterExpandable>
				<FooterExpandable name="newsletter" :title="$__('Newsletter')">
					<NewsletterForm class="footer-newsletter-form" />
				</FooterExpandable>
				<FooterExpandable
					name="nav"
					:title="$__('Useful Information')"
					tag="nav"
				>
					<ul>
						<li
							v-for="page in infoNavigation"
							:key="`footer-nav--${page.name}`"
						>
							<NuxtLink
								:to="infoPageUrlPrefix + page.url"
								v-text="page.title"
							/>
						</li>
						<li>
							<nuxt-link to="/blog">Blog</nuxt-link>
						</li>
					</ul>
				</FooterExpandable>
				<FooterExpandable name="hours" :title="$__('Working Hours')">
					<WorkingHours />
				</FooterExpandable>
				<FooterExpandable name="contact" :title="$__('Contact')">
					<div class="footer-contact-heading">
						{{ $__('E-mail, call or chat with us:') }}
					</div>
					<div class="footer-contact-line">
						<i class="icon-mail" />
						<a
							:href="`mailto:${infoContact.email}`"
							v-text="infoContact.email"
						/>
					</div>
					<div class="footer-contact-line">
						<i class="icon-telephone" />
						<a
							dir="auto"
							:href="contactPhoneUsLink"
							v-text="infoContact.phone_us"
						/>
					</div>
					<div class="footer-contact-line">
						<i class="icon-telephone" />
						<a
							dir="auto"
							:href="contactPhoneLink"
							v-text="infoContact.phone"
						/>
					</div>
				</FooterExpandable>
			</div>
		</div>

		<div class="footer-bottom">
			<div class="footer-container">
				<div class="footer-block-flex">
					<GetApp position="footer" />
					<CategoryFooterMenu />
				</div>
				<div class="footer-container--methods">
					<div class="footer-block footer-block--payment">
						<h3 class="footer-block-heading">
							<span>{{ $__('Payment Methods') }}</span>
						</h3>
						<div class="footer-block-logos">
							<div
								v-for="(method, index) in methods.payment"
								:key="`footer-block--paymernt-logo--${index}`"
								class="footer-block-logo"
							>
								<img
									class="footer-block-logo-image"
									width="auto"
									height="32"
									:alt="method.title"
									:title="method.title"
									:src="methodImageSrcBase + method.imageSrc"
								/>
							</div>
						</div>
					</div>
					<div class="footer-block footer-block--delivery">
						<h3 class="footer-block-heading">
							{{ $__('Delivery Methods') }}
						</h3>
						<div class="footer-block-logos">
							<div
								v-for="(method, index) in methods.delivery"
								:key="`footer-block--delivery-logo--${index}`"
								class="footer-block-logo"
							>
								<img
									class="footer-block-logo-image"
									width="auto"
									height="32"
									:alt="method.title"
									:title="method.title"
									:src="methodImageSrcBase + method.imageSrc"
								/>
							</div>
						</div>
					</div>
					<div class="footer-block footer-block--social">
						<h3 class="footer-block-heading">
							{{ $__('Stay in Touch') }}
						</h3>
						<a
							v-for="network in contactNetworks"
							:key="`footer-block--social-icon--${network.name}`"
							:class="[
								'footer-block--social-icon',
								'footer-block--social-icon--' + network.name,
							]"
							:href="network.url"
							:title="`${$__('BAMBINIFASHION on')} ${
								network.title
							}`"
							:aria-label="network.title"
							rel="noopener noreferrer"
							target="_blank"
						>
							<i :class="'icon-' + network.name" />
						</a>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	import FooterExpandable from './FooterExpandable.vue';
	import NewsletterForm from './NewsletterForm.vue';
	import WorkingHours from './WorkingHours.vue';
	import footerItems from '@/mixins/footer-items';
	import CategoryFooterMenu from '@/components/CategoryFooterMenu.vue';
	import GetApp from '@/components/GetApp.vue';
	export default {
		components: {
			FooterExpandable,
			NewsletterForm,
			WorkingHours,
			CategoryFooterMenu,
			GetApp,
		},
		mixins: [footerItems],
		data() {
			return {
				methodImageSrcBase: `${process.env.CDN_URL}/static/images`,
				availabilityIntervalTrustPilot: null,
			};
		},
		computed: {
			isSafari() {
				return this.$platform?.is?.safari;
			},
			device() {
				return this.$store.state.ui.device;
			},
		},
		async mounted() {
			await this.checkTrustPilotAvaialble();

			await this.trustPilotInit();
		},
		methods: {
			async checkTrustPilotAvaialble() {
				return new Promise((resolve) => {
					this.availabilityIntervalTrustPilot = setInterval(() => {
						if (window.Trustpilot) {
							resolve(true);
						}
					}, 100);
				});
			},
			async trustPilotInit() {
				clearInterval(this.availabilityIntervalTrustPilot);
				if (this.device.isMobile) {
					window.Trustpilot.loadFromElement(
						this.$refs['trustbox-mobile'],
						true
					);
				} else {
					window.Trustpilot.loadFromElement(
						this.$refs['trustbox-desktop'],
						true
					);
				}
			},
		},
	};
</script>

<style lang="scss">
	.footer {
		&-top {
			@include media-breakpoint-down(md) {
				display: none;
			}

			background-color: $footer-bg--top;
			position: relative;
		}

		&-middle {
			@extend %has-paddings-y-md-down;
			background-color: $footer-bg--top;
			position: relative;
		}

		&-bottom {
			background-color: $footer-bg--bottom;
		}

		&-container {
			@extend %is-container;
			@extend %has-half-paddings-y-md-only;
			@extend %has-paddings-y-lg-up;
			@extend %has-half-paddings-x;
			@extend %is-row;

			position: relative;

			&--methods {
				@include media-breakpoint-down(md) {
					display: none;
				}
				@extend %is-row;
				flex: 1;
			}

			&--localization {
				@extend %is-hidden-lg-up;
				@extend %is-row;

				padding-bottom: 0;
			}

			& > .trustpilot-widget-footer {
				width: 100%;
			}
		}

		&-block {
			& {
				@include media-breakpoint-down(md) {
					display: none;
				}
				@extend %is-column;
				@extend %has-half-paddings-x;

				&::after {
					@extend %is-hidden-md-up;
					@extend %has-half-margins-x;
					@include hair(bottom);

					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					width: auto;

					.footer-container--methods & {
						display: none;
					}

					.footer-container--localization & {
						@include media-breakpoint-only(md) {
							display: block !important;
						}
					}
				}

				&--about {
					@extend %is-1-2-md-up;
				}

				&--newsletter {
					@extend %is-1-2-md-up;
				}

				&--nav {
					@extend %is-1-3-md-up;
					@extend %is-1-2-lg-up;

					ul {
						column-count: 2;
						column-gap: $spacer;

						> li {
							@extend %has-quater-paddings-b;

							display: inline-block;
							width: 100%;

							> a {
								@extend %is-break-word;
								@include btn-link;
							}
						}
					}
				}

				&--hours {
					@extend %is-1-3-md-up;
					@extend %is-1-4-lg-up;

					dl {
						display: flex;
						align-items: center;

						&.is-today {
							&::after {
								@extend %has-half-paddings-x;

								content: $icon-dot;
								font-family: $icomoon-font-family;
								display: inline-block;
								color: $gray;
								line-height: 1;
							}
						}

						&.is-open.is-today {
							&,
							&::after {
								color: $brand-success;
							}
						}
					}

					dt {
						@extend %has-half-paddings-r;

						@include rtl {
							@extend %has-half-paddings-l;

							padding-right: 0;
						}

						flex-basis: 3rem;
					}
				}

				&--contact {
					@extend %is-1-3-md-up;
					@extend %is-1-4-lg-up;

					&::after {
						display: none;
					}
				}

				&--payment {
					@extend %is-1-3-md-up;
					@extend %is-1-2-lg-up;
					@extend %has-paddings-b;

					@include media-breakpoint-up(lg) {
						border-right: 3rem transparent solid;

						@include rtl {
							border-right-width: 0;
							border-left: 3rem transparent solid;
						}
					}
				}

				&--delivery {
					@extend %is-1-2-sm-up;
					@extend %is-1-3-md-up;
					@extend %is-1-4-lg-up;
					@extend %has-paddings-b;

					@include media-breakpoint-down(sm) {
						align-self: flex-end;
					}
				}

				&--social {
					@extend %is-1-2-sm-up;
					@extend %is-1-3-md-up;
					@extend %is-1-4-lg-up;
					@extend %has-paddings-b;
					@extend %has-margins-b-xs-down;

					&-icon {
						font-size: $footer-icon-size;
						line-height: 0.8;

						&:not(:last-child) {
							@extend %has-half-margins-r;

							@include rtl {
								@extend %has-half-margins-l;

								margin-right: 0;
							}
						}
					}
				}

				&--localization {
					& {
						@extend %is-column;
						@extend %is-1-3-md-up;
					}

					&-select {
						& {
							width: 100%;
							display: flex;
							flex-direction: row-reverse;
							align-items: center;
							position: relative;

							&::before {
								content: $icon-arrow--down;
								font-family: $icomoon-font-family;
								font-size: $font-size--small;
								position: absolute;
								right: 0;
								pointer-events: none;

								@include rtl {
									left: 0;
									right: auto;
								}
							}

							select {
								@extend %has-paddings-y;
								@extend %has-paddings-r;

								flex: 1 0 auto;

								@include rtl {
									padding-right: 0;

									@extend %has-paddings-l;
								}
							}
							&#{&}--with-flag {
								select {
									@extend %has-double-paddings-l;

									@include rtl {
										@extend %has-paddings-l;
										@extend %has-double-paddings-r;
									}
								}
							}
						}

						&-flag {
							& {
								display: flex;
								width: 1.25rem;
								position: absolute;
								left: 0;
								top: 0;
								height: 100%;
								align-items: center;

								@include rtl {
									left: auto;
									right: 0;
								}
							}

							&-image {
								display: block;
								align-self: center;
								width: 100%;
								padding-bottom: 100%;
								border-radius: 50%;
								pointer-events: none;
								background-size: auto 100%;
								background-position: center;
							}
						}
					}
				}
			}

			&-flex {
				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}
				flex: 1 0 100%;
				display: flex;
				column-gap: 1rem;
			}

			&-logo {
				&s {
					display: flex;
					flex-wrap: wrap;
				}

				& {
					@extend %has-half-margins-t;

					@include rtl {
						@extend %has-half-margins-l;

						margin-right: 0;
					}

					display: inline-block;
					text-align: center;
					flex: 0 0 2.5rem;
				}

				&-image {
					display: inline-block;
					width: auto;
					height: $footer-icon-size;
				}
			}

			&-heading {
				& {
					@extend %is-bold;

					text-transform: uppercase;
					&#{&}--expander {
						padding: 0;
					}

					.footer-container--methods & {
						@extend %has-paddings-y;
					}
				}

				&-toggle {
					@extend %is-expander;

					@include media-breakpoint-up(md) {
						@include color-webtap;

						pointer-events: none;
						cursor: auto;

						& > span::after {
							display: none;
						}
					}
				}
			}
		}

		&-expand {
			@include media-breakpoint-up(md) {
				@include ignore-expand;
			}

			&-inner {
				@extend %has-paddings-b;

				@include media-breakpoint-up(md) {
					display: block !important;
				}

				.footer-block--about & {
					@extend %has-paddings-r-md-up;

					@include rtl {
						@extend %has-paddings-l-md-up;

						padding-right: 0;
					}
				}
			}
		}

		&-inline-link {
			@include btn-link;
		}

		&-newsletter-form {
			.form-block {
				padding: 0;
				background: none;
			}
		}

		&-contact {
			&-heading {
				@extend %has-quater-paddings-b;
			}

			&-line {
				@extend %has-quater-paddings-b;

				a {
					@include btn-link;
				}

				i {
					@extend %has-quater-paddings-r;

					@include rtl {
						@extend %has-quater-paddings-l;

						padding-right: 0;
					}
				}
			}
		}
	}
</style>
