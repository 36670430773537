<template>
	<Expandable
		:class="['footer-block', 'footer-block--' + name]"
		expand-class="footer-expand"
	>
		<h3
			slot="expander"
			slot-scope="expander"
			class="footer-block-heading footer-block-heading--expander"
		>
			<button
				type="button"
				:title="
					expander.isExpanded
						? $__('Collapse %title% section', { title })
						: $__('Expand %title% section', { title })
				"
				:aria-label="
					expander.isExpanded
						? $__('Collapse %title% section', { title })
						: $__('Expand %title% section', { title })
				"
				:aria-expanded="expander.isExpanded ? 'true' : 'false'"
				class="footer-block-heading-toggle"
				:class="{ 'has-expanded': expander.isExpanded }"
				@click="expander.toggleExpand()"
			>
				<span>{{ title }}</span>
			</button>
		</h3>
		<template
			:is="tag"
			slot="expand"
			ref="content"
			class="footer-expand-inner"
		>
			<slot />
		</template>
	</Expandable>
</template>

<script>
	import animatedScrollTo from 'animated-scroll-to';
	import Expandable from './Expandable.vue';
	export default {
		components: {
			Expandable,
		},
		props: {
			title: {
				type: String,
				default: '',
			},
			tag: {
				type: String,
				default: 'div',
			},
			name: {
				type: String,
				default: '',
			},
		},
		mounted() {
			this.addScrollToTop(this.$refs.content);
		},

		methods: {
			addScrollToTop(content) {
				const links = content.querySelectorAll('a');

				links.forEach((element) => {
					element.addEventListener('click', () => {
						if (this.$route.path === element.getAttribute('href')) {
							if (process.browser) {
								animatedScrollTo(0);
							}
						}
					});
				});
			},
		},
	};
</script>
