<script>
	import _debounce from 'lodash.debounce';
	import smoothscroll from 'smoothscroll-polyfill';
	const SHOW_OFFSET = 300;
	export default {
		data() {
			return {
				isVisible: false,
				debouncedScrollReference: null,
			};
		},
		destroyed() {
			window.removeEventListener('scroll', this.debouncedScrollReference);
		},
		mounted() {
			smoothscroll.polyfill();
			this.debouncedScrollReference = _debounce(this.handleScroll, 50);
			window.addEventListener('scroll', this.debouncedScrollReference);
		},
		methods: {
			scrollToTop() {
				const path = this.$route.fullPath;
				this.$track('scroll-to-top', path);
				window.scrollTo({ top: 0, behavior: 'smooth' });
			},
			handleScroll() {
				this.isVisible = window.scrollY > SHOW_OFFSET;
				this.$emit('toggle-visibility', this.isVisible);
			},
		},
	};
</script>
<template>
	<transition name="fade">
		<i
			v-show="isVisible"
			class="scroll-to-top-btn icon-arrow--thin-up"
			@click="scrollToTop()"
		/>
	</transition>
</template>

<style scoped lang="scss">
	.scroll-to-top-btn {
		position: fixed;
		right: 20px;
		bottom: 20px;
		border-radius: 50%;
		width: $btn-scroll-top-size;
		height: $btn-scroll-top-size;
		cursor: pointer;
		z-index: 2;
		background: $white;
		@include media-breakpoint-down(md) {
			position: sticky;
			bottom: 75px;
			left: 100%;
		}
		transition: all 0.25s;
		&:hover {
			background: darken(white, 2%);
		}
		&:before {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-size: 45px;
		}
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
