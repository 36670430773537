/**
 *  Heads up! Leave this file until PR is being pushed, afterwards just include 'vue-click-outside' w/ npm
 *  @link https://github.com/vue-bulma/click-outside/issues/15
 */
function validate(binding) {
	if (typeof binding.value !== 'function') {
		// eslint-disable-next-line
		console.warn(
			'[vue-click-outside] Provided expression',
			binding.expression,
			'is not a function.'
		);
		return false;
	}

	return true;
}

function isPopup(popupItem, elements) {
	if (!popupItem || !elements) {
		return false;
	}

	for (let i = 0, len = elements.length; i < len; i++) {
		try {
			if (popupItem.contains(elements[i])) {
				return true;
			}
			if (elements[i].contains(popupItem)) {
				return false;
			}
		} catch (error) {
			return false;
		}
	}

	return false;
}

function isServer(vNode) {
	return (
		typeof vNode.componentInstance !== 'undefined' &&
		vNode.componentInstance.$isServer
	);
}

// Since IE11 doesn't support e.composedPath()
function composedPath(el) {
	const path = [];
	while (el) {
		path.push(el);
		if (el.tagName === 'HTML') {
			path.push(document);
			path.push(window);
			return path;
		}
		el = el.parentNode;
	}
}

exports = module.exports = {
	bind(el, binding, vNode) {
		if (!validate(binding)) {
			return;
		}

		// Define Handler and cache it on the element
		function handler(e) {
			if (!vNode.context) {
				return;
			}

			// some components may have related popup item, on which we shall prevent the click outside event handler.
			const elements =
				e.path ||
				(e.composedPath && e.composedPath()) ||
				composedPath(e.target);
			elements && elements.length > 0 && elements.unshift(e.target);

			if (
				el.contains(e.target) ||
				isPopup(vNode.context.popupItem, elements)
			) {
				return;
			}

			el.__vueClickOutside__.callback(e);
		}

		// add Event Listeners
		el.__vueClickOutside__ = {
			handler,
			callback: binding.value,
		};
		const clickHandler =
			'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
		!isServer(vNode) && document.addEventListener(clickHandler, handler);
	},

	update(el, binding) {
		if (validate(binding)) {
			el.__vueClickOutside__.callback = binding.value;
		}
	},

	unbind(el, binding, vNode) {
		// Remove Event Listeners
		const clickHandler =
			'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
		!isServer(vNode) &&
			document.removeEventListener(
				clickHandler,
				el.__vueClickOutside__.handler
			);
		delete el.__vueClickOutside__;
	},
};
