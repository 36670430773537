export const process = ({ route, cookie }) => {
	const source = getCurrentSource(route.query);

	if (!source) {
		return null;
	}
	let sources = getPresentSources(cookie);
	if (cookie && cookie.length > 3072) {
		sources = [];
	}

	sources.push({ ...source, created_at: Date.now() });

	return JSON.stringify(sources);
};

const trackedParams = [
	'gclid',
	'fbclid',
	'pk_source',
	'pk_campaign',
	'pk_medium',
	'utm_source',
	'utm_campaign',
	'utm_medium',
	'catalog_product_link',
];

export const getCurrentSource = (query) => {
	const source = {};

	trackedParams.forEach((param) => {
		if (query.hasOwnProperty(param)) {
			source[param.replace(/^(pk_)/gm, 'utm_')] = query[param];
		}
	});

	return Object.keys(source).length > 0 ? source : null;
};

export const getPresentSources = (cookie) => {
	let sources = [];

	try {
		const parsed = JSON.parse(decodeURIComponent(cookie));

		if (Array.isArray(parsed)) {
			sources = parsed;
		}
	} catch (error) {}

	return sources;
};

export const getUtmSourceParams = (store, query) => {
	const params = getCurrentSource(query);
	const name = store.state.user.localStorage.names.utmParams;

	if (!params) {
		try {
			return JSON.parse(
				store.getters['user/GET_LOCAL_STORAGE']({
					name,
				})
			);
		} catch (e) {
			return null;
		}
	}

	const utmParams = Object.keys(params)
		.map((key) => `${[key]}=${params[key]}`)
		.join('|');

	store.commit('user/SET_LOCAL_STORAGE', {
		name,
		data: JSON.stringify(utmParams),
	});

	return utmParams;
};
