export default {
	methods: {
		dismissByEsc(event) {
			if (event.keyCode == 27) {
				this.dismiss();
			}
		},
		setEscDismission(val) {
			if (val) {
				window.addEventListener('keyup', this.dismissByEsc, true);
			} else {
				window.removeEventListener('keyup', this.dismissByEsc, true);
			}
		},
	},
};
