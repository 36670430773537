<template>
	<img alt="BAMBINIFASHION.COM" class="is-lazy" />
</template>

<script>
	import LazyLoad from 'vanilla-lazyload';
	export default {
		mounted() {
			this.forceLoadOnMounted();
		},
		methods: {
			forceLoad() {
				this.$lazyload.generic.load(this.$el);
			},
			forceLoadOnMounted() {
				const self = this.$el;

				if (!self.classList.contains('is-lazy-loaded')) {
					this.$lazyload.generic.update();
				}
			},
		},
	};
</script>
