var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
		'banner',
		`banner--${_vm.data.size}`,
		{ 'banner--category': _vm.isCategoryBanner },
		{ 'banner--caption-only': !_vm.hasMedia },
	],on:_vm._d({},[_vm.eventName,_vm.trackLink])},[_c(_vm.isLink ? 'NuxtLink' : 'div',{tag:"Component",staticClass:"banner-inner",attrs:{"to":_vm.data.linkUrl}},[_c('figure',{class:'banner-content'},[(_vm.hasMedia)?_c('div',{staticClass:"banner-media has-objectfit"},[(_vm.data.video.src !== null)?_c('video',{staticClass:"banner-video",attrs:{"muted":"","loop":"","autoplay":"","playsinline":"","aria-describedby":"bannerHeading","preload":"metadata"},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.$dataSrc({
								url: _vm.data.video.src,
								extension: 'webm',
							}),"type":"video/webm"}}),_vm._v(" "),_c('source',{attrs:{"src":_vm.$dataSrc({
								url: _vm.data.video.src,
								extension: 'mp4',
							}),"type":"video/mp4"}}),_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$__('Your browser does not support the video tag.'))+"\n\t\t\t\t")]):(_vm.data.image.src !== null)?_c('picture',[(_vm.device.isDesktop)?_c('source',{attrs:{"media":`(min-width: ${
							_vm.isCategoryBanner ? 992 : 768
						}px)`,"data-srcset":_vm.$dataSrc({
								url: _vm.data.image.src,
								size: 'desktop',
								isHiRes: true,
								extension: 'webp',
								hash: _vm.data.image.hash,
							}),"type":"image/webp"}}):_vm._e(),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 429px)","data-srcset":_vm.$dataSrc({
								url: _vm.data.image.src,
								size: 'tablet',
								isHiRes: true,
								extension: 'webp',
								hash: _vm.data.image.hash,
							}),"type":"image/webp"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 1px)","data-srcset":_vm.$dataSrc({
								url: _vm.data.image.src,
								size: 'mobile',
								isHiRes: true,
								extension: 'webp',
								hash: _vm.data.image.hash,
							}),"type":"image/webp"}}),_vm._v(" "),(_vm.device.isDesktop)?_c('source',{attrs:{"media":`(min-width: ${
							_vm.isCategoryBanner ? 992 : 768
						}px)`,"data-srcset":_vm.$dataSrc({
								url: _vm.data.image.src,
								size: 'desktop',
								isHiRes: true,
								hash: _vm.data.image.hash,
							}),"type":"image/jpeg"}}):_vm._e(),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 429px)","data-srcset":_vm.$dataSrc({
								url: _vm.data.image.src,
								size: 'tablet',
								isHiRes: true,
								hash: _vm.data.image.hash,
							}),"type":"image/jpeg"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 1px)","data-srcset":_vm.$dataSrc({
								url: _vm.data.image.src,
								size: 'mobile',
								isHiRes: true,
								hash: _vm.data.image.hash,
							}),"type":"image/jpeg"}}),_vm._v(" "),_c('img',{staticClass:"banner-image is-objectfit",attrs:{"srcset":_vm.$dataSrc({
								url: _vm.data.image.src,
								size: _vm.device.isMobile
									? 'mobile'
									: _vm.device.isTablet || _vm.isIpad
									? 'tablet'
									: 'desktop',
								extension: _vm.device.isWebp ? 'webp' : 'jpg',
								isHiRes:
									_vm.device.isMobile ||
									_vm.device.isTablet ||
									_vm.isIpad,
								hash: _vm.data.image.hash,
							}),"alt":_vm.data.caption.heading.text ||
							_vm.$__('Designer Clothing and Accessories Sale'),"data-object-position":"center center"}})]):_vm._e()]):_vm._e(),_vm._v(" "),_c('figcaption',{class:[
					'banner-caption',
					'banner-caption--x-' +
						(_vm.data.caption.position.x || 'center'),
					'banner-caption--y-' +
						(_vm.data.caption.position.y || 'bottom'),
					{ 'banner-caption--inverted': _vm.data.caption.isInverted },
					{ 'banner-caption--product-list': _vm.isProductList },
				]},[_c('h1',{class:[
						'banner-caption-heading',
						{
							'banner-caption-heading--hidden':
								_vm.data.caption.heading.isHidden,
						},
						{
							'banner-caption-heading--product-list':
								_vm.isProductList,
						},
					],attrs:{"id":"bannerHeading"},domProps:{"textContent":_vm._s(
						_vm.data.caption.heading.text ||
						_vm.$__('Designer Clothing and Accessories Sale')
					)}}),_vm._v(" "),(_vm.hasDescription)?[(_vm.isCategoryBanner)?[_c('SlideUpDown',{staticClass:"banner-caption-description-expand",attrs:{"is-enabled":_vm.isDescriptionExpandable,"is-active":_vm.isDescriptionExpanded,"duration":_vm.descriptionExpandDuration,"style-when-hidden":_vm.descriptionHiddenStyle}},[_c('div',{ref:"BannerCaptionExpandableDescription",staticClass:"banner-caption-description",class:{
									'banner-caption-description--product-list':
										_vm.isProductList,
								},on:{"click":_vm.toggleDescriptionExpand}},[_c('div',{domProps:{"textContent":_vm._s(_vm.data.caption.description)}}),_vm._v(" "),_c('transition',{attrs:{"name":"banner-caption-description-expander-transition"}},[(
											_vm.isDescriptionExpandable &&
											!_vm.isDescriptionExpanded
										)?_c('button',{staticClass:"banner-caption-description-expander",attrs:{"type":"button"}},[_c('span',{staticClass:"banner-caption-description-expander-text"},[_vm._v(_vm._s(_vm.$__('...More')))])]):_vm._e()])],1)])]:_c('div',{staticClass:"banner-caption-description",domProps:{"textContent":_vm._s(_vm.data.caption.description)}})]:_vm._e(),_vm._v(" "),(_vm.isProductList)?_c('span',{staticClass:"banner-caption-amount",domProps:{"textContent":_vm._s(_vm.countTitle)}}):_vm._e(),_vm._v(" "),(_vm.data.caption.cta.text)?_c('span',{class:[
						'banner-caption-cta',
						{
							'banner-caption-cta--custom':
								_vm.hasCustomCaptionCtaStyle,
						},
					],style:(_vm.customCaptionCtaStyle),attrs:{"role":"button"},domProps:{"textContent":_vm._s(_vm.data.caption.cta.text)}}):_vm._e()],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }