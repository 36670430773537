import InApp from 'detect-inapp';

export default ({ app }) => {
	app.router.beforeEach((to, from, next) => {
		if (typeof from !== 'undefined' && from.query.inapp) {
			const inApp = new InApp(
				navigator.userAgent || navigator.vendor || window.opera
			);

			if (inApp.isInApp) {
				next(false);
				window.open(to.fullPath, '_self');
			} else {
				next();
			}
		} else {
			next();
		}
	});
};
