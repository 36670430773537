import { actionTree, getAccessorType, mutationTree } from 'typed-vuex';

// Import all your submodules
import * as common from '@/store/common';
import * as page from '@/store/page.js';
import * as ui from '@/store/ui';
import * as user from '@/store/user';
import * as productVariants from '@/store/productVariants';
import * as mobileNavigation from '@/store/mobileNavigation';
import * as productCrosssale from '@/store/productCrosssale';

export const state = () => ({
	next: {}, // temporary stores upcoming API data fetched in middleware/api.js
});

export const mutations = mutationTree(state, {
	SET_NEXT_API_DATA(state, data) {
		state.next = data;
	},
});

export const actions = actionTree(
	{ state, mutations },
	{
		syncWithNext({ state, commit }) {
			Object.keys(state.next).forEach((storeModule) => {
				// @ts-ignore
				if (typeof state.next[storeModule] !== 'undefined') {
					// @ts-ignore
					commit(`${storeModule}/SET_DATA`, state.next[storeModule]);
				}
			});
		},
	}
);

// This compiles to nothing and only serves to return the correct type of the accessor

export const accessorType = getAccessorType({
	state,
	// getters,
	mutations,
	actions,
	modules: {
		// The key (submodule) needs to match the Nuxt namespace (e.g. ~/store/submodule.ts)
		common,
		page,
		ui,
		user,
		productVariants,
		mobileNavigation,
		productCrosssale,
	},
});
