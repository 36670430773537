import { actionTree, mutationTree } from 'typed-vuex';

export const state = () => ({
	variantId: null,
});

export const mutations = mutationTree(state, {
	SET_VARIANT_ID(state, variantId) {
		state.variantId = variantId;
	},
});
export const actions = actionTree(
	{ state },
	{
		setVariantId({ commit }, variantId) {
			commit('SET_VARIANT_ID', variantId);
		},
	}
);
