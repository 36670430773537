<template>
	<div class="error">
		<div class="error-container">
			<section
				v-for="section in error.components"
				:key="`error-section--${section.name}`"
				class="error-section"
			>
				<Component
					:is="getComponentName(section)"
					:data="getComponentData(section)"
				/>
			</section>
		</div>
	</div>
</template>

<script>
	import Banner from '@/components/Banner.vue';
	import FeaturedCategories from '@/components/FeaturedCategories.vue';

	import useCase from '@/use/case.js';
	export default {
		components: {
			Banner,
			FeaturedCategories,
		},
		props: ['customBannerText'],
		computed: {
			error() {
				return this.$store.state.common.error;
			},
		},
		methods: {
			getComponentName(section) {
				return useCase(section.name, 'pascal');
			},
			getComponentData(section) {
				let data;

				if (
					section.name === 'banner' &&
					typeof this.customBannerText !== 'undefined'
				) {
					data = this.$detach(section.data);
					if (typeof this.customBannerText.title !== 'undefined') {
						data.caption.heading.text = this.customBannerText.title;
					}
					if (
						typeof this.customBannerText.description !== 'undefined'
					) {
						data.caption.description =
							this.customBannerText.description;
					}
				} else {
					data = section.data;
				}
				return data;
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/pages/error.scss';
</style>
