import { actionTree, getterTree, mutationTree } from 'typed-vuex';
import { ApiNavigationItem, MenuHistory } from '@/types/common';

export const state = () => ({
	isMenuOn: false,
	navigation: [],
	menuNavigationHistory: [] as MenuHistory[],
	level: 0,
	parent: null as null | ApiNavigationItem,
});

export const getters = getterTree(state, {
	getLatestHistoryEntry: (state, getters, rootState) => {
		if (!state.menuNavigationHistory.length) {
			return { items: rootState.common.navigation, type: 'index' };
		}
		return state.menuNavigationHistory[
			state.menuNavigationHistory.length - 1
		] as MenuHistory;
	},
	isMainPage: (state) => state.level === 0,
});

export const mutations = mutationTree(state, {
	RESET_MENU_HISTORY(state) {
		state.menuNavigationHistory.splice(
			0,
			state.menuNavigationHistory.length
		);
		state.level = 0;
		state.isMenuOn = false;
		state.parent = null;
	},
	POP_MENU_FILTER_HISTORY(state) {
		state.menuNavigationHistory.pop();
		state.level--;
	},
	SET_ACTIVE_MENU(state, payload: MenuHistory) {
		state.menuNavigationHistory.push({
			items: payload.items,
			type: payload.type,
		});
		state.level++;
	},
	TOGGLE_NAV_MOBILE(state) {
		state.isMenuOn = !state.isMenuOn;
	},
	SET_MENU_PARENT(state, payload: ApiNavigationItem | null) {
		state.parent = payload;
	},
});

export const actions = actionTree(
	{ state, mutations },
	{
		navigateMenu({ commit, state }, item: ApiNavigationItem) {
			if (item.children || item.sections) {
				if (state.level === 0) {
					commit('SET_MENU_PARENT', item);
				}

				commit('SET_ACTIVE_MENU', {
					items: item.children ?? item.sections,
					type: 'category',
				});
			}
		},
	}
);
