import { platform } from './detect-platform.client';

export default ({ app, store }) => {
	const localStorageName = 'subscribe-popup';
	const localStorageValue = Date.now();
	const oneDay = 24 * 60 * 60 * 1000;
	const delay = platform.is.desktop ? 3000 : 20000;
	const stopList = [
		'checkout',
		'account',
		'shopping-bag',
		'Instagram-Influencer',
		'utm_source=attentive',
	];
	const path = window.location.href;

	const inStopList = stopList.some((word) => path.includes(word));

	const lastShowDate = store.getters['user/GET_LOCAL_STORAGE']({
		name: localStorageName,
	});

	const hasBeenShownToday = localStorageValue - lastShowDate < oneDay;

	const hasEmail = store.state.user.personalInformation.email !== '';
	if (inStopList || hasBeenShownToday || hasEmail) {
		return;
	}

	setTimeout(() => {
		show();
	}, delay);

	const show = () => {
		const overlayType = 'subscribe-popup';

		if (store.state.ui.isOverlayOn) {
			setTimeout(() => {
				show();
			}, delay);
			return;
		}

		store.dispatch('ui/toggleOverlay', {
			overlayType,
			overlayData: {},
		});

		store.commit('user/SET_LOCAL_STORAGE', {
			name: localStorageName,
			data: encodeURIComponent(localStorageValue),
		});
	};
};
