import smartlookClient from 'smartlook-client';

export default ({ store }, inject) => {
	const smartlook = {
		isInit: false,
		quote: 1 / 10,
		init() {
			this.isInit = true;

			if ('false' === 'true') {
				console.log(
					'[smartlook-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					'init'
				);
			}

			if ('false' === 'true') {
				return;
			}

			smartlookClient.init('a053af46b98ed0fd157c96d16ddfe9aa87ad8590');
			this.record(true);
		},
		identify(email = store.state.user.personalInformation.email) {
			if (!this.isInit) {
				return;
			}
			const hash = store.state.user.hash;
			const env = 'production';

			if ('false' === 'true') {
				console.log(
					'[smartlook-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					'identify',
					hash,
					{
						...(env && {
							env,
						}),
						...(email && {
							email,
						}),
					}
				);
			}

			if ('false' === 'true') {
				return;
			}

			smartlookClient.identify(hash, {
				...(env && {
					env,
				}),
				...(email && {
					email,
				}),
			});
		},
		anonymize() {
			if (!this.isInit) {
				return;
			}

			if ('false' === 'true') {
				console.log(
					'[smartlook-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					'anonymize'
				);
			}

			if ('false' === 'true') {
				return;
			}

			smartlookClient.anonymize();
		},
		record() {
			const consentText =
				'The cookies will be used for personalized content and ads';

			if ('false' === 'true') {
				console.log(
					'[smartlook-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					'recordApi',
					consentText
				);
			}

			smartlookClient.record({
				emails: true,
				forms: true,
				numbers: true,
				ips: true,
			});
		},
		load() {
			if (this.isInQuoteTier()) {
				this.init();
			}
		},
		trigger() {
			if (this.isInit) {
				return;
			}

			this.init();
		},
		isInQuoteTier() {
			const random = Math.random();
			const isInQuoteTier = random < this.quote;

			if ('false' === 'true') {
				console.log(
					'[smartlook-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					'isInQuoteTier',
					isInQuoteTier
				);
			}

			return isInQuoteTier;
		},
	};

	inject('smartlook', smartlook);
};
