export default {
	getIndexByKey(key, all = 0, current = null) {
		let selected = current;

		if (all > 0) {
			switch (key) {
				case 'ArrowUp':
					if (selected === null || selected === 0) {
						selected = all - 1;
					} else {
						selected--;
					}
					break;
				case 'ArrowDown':
					if (selected === null || selected === all - 1) {
						selected = 0;
					} else {
						selected++;
					}

					break;
			}
		}

		return selected;
	},
};
