import { apiClient } from '@/plugins/axios';

export function addProductToWishlist(productId, productVariationId) {
	const url = productVariationId
		? `user:wishlist&action=add&productId=${productId}&productVariationId=${productVariationId}`
		: `user:wishlist&action=add&productId=${productId}`;
	return apiClient.$get(url);
}

export function addProductToWishlistModeId(productId, productVariationId) {
	const url = productVariationId
		? `user:wishlist&action=add&productId=${productId}&productVariationId=${productVariationId}&mode=id`
		: `user:wishlist&action=add&productId=${productId}&mode=id`;
	return apiClient.$get(url);
}

export function removeProductFromWishlist(productId, productVariationId) {
	const url = productVariationId
		? `user:wishlist&action=remove&productId=${productId}&productVariationId=${productVariationId}`
		: `user:wishlist&action=remove&productId=${productId}`;
	return apiClient.$get(url);
}

export function removeProductFromWishlistModeId(productId, productVariationId) {
	const url = productVariationId
		? `user:wishlist&action=remove&productId=${productId}&productVariationId=${productVariationId}&mode=id`
		: `user:wishlist&action=remove&productId=${productId}&mode=id`;
	return apiClient.$get(url);
}

export function addVariationToWishlist(productId, variationId) {
	return apiClient.$get(
		`user:wishlist&action=addVariation&productId=${productId}&productVariationId=${variationId}`
	);
}

export function updateVariationInWishlist(
	productId,
	newVariationId,
	oldVariationId
) {
	const url = oldVariationId
		? `user:wishlist&action=update&productId=${productId}&productVariationId=${oldVariationId}&updateProductId=${productId}&updateProductVariationId=${newVariationId}`
		: `user:wishlist&action=update&productId=${productId}&updateProductId=${productId}&updateProductVariationId=${newVariationId}`;
	return apiClient.$get(url);
}
