export default ({ store }) => {
	setLocalizationCookie();

	store.subscribe((mutation) => {
		if (mutation.type === 'user/SET_LOCALIZATION') {
			setLocalizationCookie();
		}
	});

	function setLocalizationCookie() {
		store.commit('user/SET_COOKIE', {
			name: store.state.user.cookie.names.localization,
			value: JSON.stringify(store.state.user.localization),
		});
	}
};
