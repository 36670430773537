<template>
	<div class="countdown">
		<div v-if="count" class="countdown-active">
			<span v-html="countdown.content.activeBefore" />
			<strong
				v-if="!countdown.isCountHidden"
				class="countdown-active-counter"
				v-text="count"
			/>
			<span v-html="countdown.content.activeAfter" />
		</div>
		<div
			v-else
			class="countdown-finished"
			v-html="countdown.content.finished"
		/>
	</div>
</template>

<script>
	import { getFormattedCount } from '@/use/countdown.js';
	import { replaceCurrency } from '~/use/replaceCurrency';

	export default {
		props: {
			countdown: {
				type: Object,
				default() {
					return {};
				},
			},
		},
		computed: {
			utsNow() {
				return this.$store.state.ui.utsNow;
			},
			parsedTo() {
				if (typeof this.countdown.to === 'string') {
					return Date.parse(this.countdown.to);
				} else {
					return this.countdown.to;
				}
			},
			count() {
				const hasGlitch = this.countdown.hasGlitch;
				const duration = (this.parsedTo - this.utsNow) / 1000;

				return duration > 0
					? getFormattedCount({
							duration,
							hasGlitch,
					  })
					: false;
			},
		},
		watch: {
			count(val, oldVal) {
				if (typeof val === 'string' && oldVal === false) {
					this.$emit('start');
				} else if (typeof oldVal === 'string' && val === false) {
					this.$emit('finish');
				}
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/countdown.scss';
</style>
