const TIMEOUT = 3 * 60 * 60 * 1000;

export default ({ store }, inject) => {
	const ym = {
		ymId: '85696466',
		init() {
			const ymId = this.ymId;
			const init = {
				defer: true,
				clickmap: true,
				trackLinks: true,
				accurateTrackBounce: true,
				ecommerce: 'dataLayer',
				userParams: {
					...getCustomDimensions({ store }),
				},
			};

			if ('false' === 'true') {
				console.log(
					'[yandex-metrika-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					{
						ymId,
						init,
					}
				);
			}

			if ('false' === 'true') {
				return;
			}

			(function (m, e, t, r, i, k, a) {
				m[i] =
					m[i] ||
					function () {
						(m[i].a = m[i].a || []).push(arguments);
					};
				m[i].l = 1 * new Date();
				(k = e.createElement(t)),
					(a = e.getElementsByTagName(t)[0]),
					(k.async = 1),
					(k.src = r),
					a.parentNode.insertBefore(k, a);
			})(
				window,
				document,
				'script',
				'https://mc.yandex.ru/metrika/tag.js',
				'ym'
			);

			if (window.ym && ymId) {
				window.ym(ymId, 'init', init);
				setTimeout(
					() =>
						window.ym(ymId, 'params', {
							...getCustomDimensions({ store }),
						}),
					1000
				);
			}
		},
		hit({ from, to }) {
			const ymId = this.ymId;
			const url = to.fullPath;
			const options = {};

			if (from.name !== null) {
				options.referer = from.fullPath;
			}

			if ('false' === 'true') {
				console.log(
					'[yandex-metrika-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					{
						hit: {
							url,
							options,
						},
					}
				);
			}

			if ('false' === 'true') {
				return;
			}

			if (window.ym) {
				window.ym(ymId, 'hit', url, options);
			}
		},
		reachGoal({ goalId, price }) {
			let params;
			if (price) {
				params = { order_price: price, currency: 'EUR' };
			}
			const ymId = this.ymId;

			if ('false' === 'true') {
				console.log(
					'[yandex-metrika-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					{
						reachGoal: goalId,
					}
				);
			}

			if ('false' === 'true') {
				return;
			}

			if (window.ym && goalId) {
				window.ym(ymId, 'reachGoal', goalId, params);
			}
		},
		ecommerce({ action, products, order }) {
			const push = getEcommerceData({ action, products, order });

			if ('false' === 'true') {
				console.log(
					'[yandex-metrika-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					{ push: { ...push } }
				);
			}

			if ('false' === 'true') {
				return;
			}

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push(push);
		},
		viewCart({ cart }) {
			const eventSessionName = 'view-cart-session';
			const lastShownDate = getLocalStorageValueExpire(eventSessionName);
			const hasBeenShown = Date.now() - lastShownDate < TIMEOUT;
			if (!lastShownDate || !hasBeenShown) {
				this.reachGoal({
					goalId: eventSessionName,
					price: cart.total.amounts.totalEuroRaw,
				});
				setLocalStorageValueExpire(eventSessionName, Date.now());
			}
		},
		addToCart({ product }) {
			const eventSessionName = 'add-to-cart-session';
			const lastShownDate = getLocalStorageValueExpire(eventSessionName);
			const hasBeenShown = Date.now() - lastShownDate < TIMEOUT;
			if (!lastShownDate || !hasBeenShown) {
				this.reachGoal(eventSessionName, {
					goalId: eventSessionName,
					price: product.variation.priceEuroReducedRaw,
				});
				setLocalStorageValueExpire(eventSessionName, Date.now());
			}
		},
		load() {
			this.init();
		},
	};

	inject('ym', ym);
};

export const getEcommerceData = ({ action, products, order }) => {
	const ecommerceData = {
		ecommerce: {
			[action]: {
				products: getEcommerceProducts({ action, products }),
			},
		},
	};

	if (action === 'purchase') {
		ecommerceData.ecommerce.purchase = {
			...ecommerceData.ecommerce.purchase,
			actionField: {
				id: order.summary.reference,
				goal_id: 'purchase',
				revenue: order.summary.total.amounts.totalEuroRaw,
			},
		};
	}

	return ecommerceData;
};
export const getEcommerceProducts = ({ action, products }) => {
	return products.map((product) => {
		let ecommerceProduct = {
			id: product.id,
			name: product.title,
			brand: product.brand.title,
		};

		if (action === 'detail') {
			ecommerceProduct = {
				...ecommerceProduct,
				price: product.priceEuroReducedRaw,
			};
		} else {
			ecommerceProduct = {
				...ecommerceProduct,
				price: product.variation.priceEuroReducedRaw,
				quantity: product.variation.quantity,
				category: product.categories.join('/'),
				variant: product.variation.title,
			};
		}

		return ecommerceProduct;
	});
};

export const getCustomDimensions = ({ store }) => {
	const tests = store.state.user.deltaImp;
	const dimensions = {};

	tests.forEach((test) => {
		dimensions[`delta_imp${test.id}`] = test.value;
	});

	return dimensions;
};

const setLocalStorageValueExpire = (name, value) => {
	localStorage.setItem(name, JSON.stringify(value));
};

const getLocalStorageValueExpire = (name) => {
	return JSON.parse(localStorage.getItem(name));
};
