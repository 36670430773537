import Vue from 'vue';

// Global components
import LazyHydrate from 'vue-lazy-hydration';
import LazyImg from '../components/LazyImg';
import SlideUpDown from '../components/SlideUpDown.vue';
import Spinner from '../components/Spinner.vue';

// Global use
import useCase from '../use/case.js';
import useDate from '../use/date.js';
import useDetach from '../use/detach.js';
import useDataSrc from '../use/data-src.js';

Vue.component('LazyHydrate', LazyHydrate);
Vue.component('LazyImg', LazyImg);
Vue.component('SlideUpDown', SlideUpDown);
Vue.component('Spinner', Spinner);

export default (_, inject) => {
	inject('case', useCase);
	inject('date', useDate);
	inject('detach', useDetach);
	inject('dataSrc', useDataSrc);
};
