<template>
	<div class="top">
		<transition name="top-container-transition" mode="out-in">
			<NuxtLink
				v-if="isCheckoutPage"
				to="/"
				title="BAMBINIFASHION.COM"
				aria-label="BAMBINIFASHION.COM"
				class="top-container top-logo top-button"
			>
				<i class="icon-logo top-logo-icon" />
			</NuxtLink>
			<div v-else class="top-container">
				<NavTabletToggle class="top-button top-nav">
					<i class="icon-hamburger" />
				</NavTabletToggle>
				<SearchToggle
					:title="$__('Search')"
					:aria-label="$__('Search')"
					class="top-button top-user-search"
				>
					<i class="icon-search" />
				</SearchToggle>
				<ul class="top-localization">
					<li
						v-for="prop in localizationProps"
						:key="`top-localization--${prop}`"
					>
						<LocalizationDropdown :name="prop" />
					</li>
				</ul>
				<NuxtLink
					to="/"
					title="BAMBINIFASHION.COM"
					aria-label="BAMBINIFASHION.COM"
					class="top-logo top-logo--catalogue top-button"
					@click.native="scrollTopIfSameRouteName('index')"
				>
					<i class="icon-logo top-logo-icon" />
				</NuxtLink>
				<ul class="top-user">
					<li>
						<NuxtLink
							:title="account.title"
							:aria-label="account.title"
							:to="account.url"
							class="top-button top-user-account"
						>
							<i class="icon-account" />
						</NuxtLink>
					</li>
					<li>
						<NuxtLink
							:title="$__('Wishlist')"
							:aria-label="$__('Wishlist')"
							to="/wishlist"
							class="top-button top-user-wishlist"
							@click.native="scrollTopIfSameRouteName('wishlist')"
						>
							<i class="icon-heart--empty top-button-icon">
								<TopCounter :count="counters.wishlist" />
							</i>
						</NuxtLink>
					</li>
					<li>
						<NuxtLink
							:title="$__('Shopping bag')"
							:aria-label="$__('Shopping bag')"
							to="/shopping-bag"
							class="top-button top-user-cart"
							@click.native="
								scrollTopIfSameRouteName('shopping-bag')
							"
						>
							<i class="icon-bag top-button-icon">
								<TopCounter :count="counters.cart" />
							</i>
						</NuxtLink>
						<TopUserCartTooltip />
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script>
	import animatedScrollTo from 'animated-scroll-to';
	import TopCounter from '@/components/TopCounter.vue';
	import LocalizationDropdown from '@/components/LocalizationDropdown.vue';
	import TopUserCartTooltip from '@/components/TopUserCartTooltip.vue';
	import NavTabletToggle from '@/components/Navigation/NavTabletToggle.vue';
	import SearchToggle from '@/components/SearchToggle.vue';

	export default {
		components: {
			TopCounter,
			LocalizationDropdown,
			TopUserCartTooltip,
			NavTabletToggle,
			SearchToggle,
		},
		props: {
			isCheckoutPage: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			localizationProps() {
				return this.$store.getters[
					'user/GET_LOCALIZATION_PROPS'
				].filter((p) => p !== 'language');
			},
			account() {
				return {
					title: this.$__('My Account'),
					name: 'account',
					url: this.$store.getters['user/GET_NAV_ITEM_ACCOUNT_PATH'],
				};
			},
			counters() {
				return this.$store.state.user.counters;
			},
		},
		methods: {
			scrollTopIfSameRouteName(routeName) {
				if (this.$route.name === routeName) {
					animatedScrollTo(0);
				}
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/top.scss';
</style>
