import { apiClient } from '@/plugins/axios';
import { Cart } from 'types/cart';

export interface CartDdpParams {
	assignDeliveryZoneId?: number | null;
	assignShippingAddressId?: number | null;
	assignBillingAddressId?: number | null;
	assignIsDdpCustomerPaid?: boolean | null;
	countryIsoCode?: string | null;
	isWallet?: boolean;
}

export async function setCartWithDdp({
	assignDeliveryZoneId,
	assignShippingAddressId,
	assignBillingAddressId,
	assignIsDdpCustomerPaid,
	countryIsoCode,
	isWallet,
}: CartDdpParams) {
	const data = {
		...(assignDeliveryZoneId && {
			assignDeliveryZoneId: String(assignDeliveryZoneId || ''),
		}),
		...(assignShippingAddressId && {
			assignShippingAddressId: String(assignShippingAddressId || ''),
		}),
		...(assignBillingAddressId && {
			assignBillingAddressId: String(assignBillingAddressId || ''),
		}),
		...(assignIsDdpCustomerPaid !== undefined && {
			assignIsDdpCustomerPaid: assignIsDdpCustomerPaid ? '1' : '0',
		}),
		...(countryIsoCode && {
			countryOverride: String(countryIsoCode),
		}),

		...(isWallet && { isWallet: isWallet ? '1' : '0' }),
	};

	const params = new URLSearchParams(data);

	const response = await apiClient.$get<{
		user: {
			cart: Cart;
		};
	}>(`user:cart?${params}`);

	return response;
}
