<template>
	<div class="featured-categories">
		<div
			v-for="(category, index) in data.categories"
			:key="`featured-category--${index}`"
			class="featured-category"
		>
			<NuxtLink
				:to="category.linkUrl"
				class="featured-category-link"
				@click.native="$track('featured-category', { category })"
			>
				<figure class="featured-category-inner has-objectfit">
					<picture>
						<source
							media="(min-width: 768px)"
							:data-srcset="
								$dataSrc({
									url: category.image.src,
									size: 'desktop',
									isHiRes: true,
									extension: 'webp',
									hash: category.image.hash,
								})
							"
							type="image/webp"
						/>
						<source
							media="(min-width: 429px)"
							:data-srcset="
								$dataSrc({
									url: category.image.src,
									size: 'tablet',
									isHiRes: true,
									extension: 'webp',
									hash: category.image.hash,
								})
							"
							type="image/webp"
						/>
						<source
							media="(min-width: 1px)"
							:data-srcset="
								$dataSrc({
									url: category.image.src,
									size: 'mobile',
									isHiRes: true,
									extension: 'webp',
									hash: category.image.hash,
								})
							"
							type="image/webp"
						/>
						<source
							media="(min-width: 768px)"
							:data-srcset="
								$dataSrc({
									url: category.image.src,
									size: 'desktop',
									isHiRes: true,
									hash: category.image.hash,
								})
							"
							type="image/jpeg"
						/>
						<source
							media="(min-width: 429px)"
							:data-srcset="
								$dataSrc({
									url: category.image.src,
									size: 'tablet',
									isHiRes: true,
									hash: category.image.hash,
								})
							"
							type="image/jpeg"
						/>
						<source
							media="(min-width: 1px)"
							:data-srcset="
								$dataSrc({
									url: category.image.src,
									size: 'mobile',
									isHiRes: true,
									hash: category.image.hash,
								})
							"
							type="image/jpeg"
						/>
						<LazyImg
							class="featured-category-image is-objectfit"
							:data-src="
								$dataSrc({
									url: category.image.src,
									size: 'desktop',
									hash: category.image.hash,
								})
							"
							:alt="category.title"
							data-object-position="center center"
						/>
					</picture>
					<figcaption class="featured-category-caption">
						<h3
							class="featured-category-caption-title"
							v-text="category.title"
						/>
					</figcaption>
				</figure>
			</NuxtLink>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/featured-categories.scss';
</style>
