import { Request } from 'express';
import { LocalizationCountry } from './../types/common';
import { platform } from './detect-platform.client';
import { accessorType } from '@/store';

declare interface AB_TEST_PROBABILITIES {
	id: number;
	probability: number[];
}
export declare interface AB_TEST extends AB_TEST_PROBABILITIES {
	value: number;
}

export default (
	{
		req,
		$accessor,
		store,
	}: {
		req: Request;
		$accessor: typeof accessorType;
	},
	inject: (param: string, obj: any) => void
) => {
	const cookieName = $accessor.user.cookie.names.deltaImp;

	const deltaImp = {
		is(id: number, value: number) {
			const storeTests = $accessor.user.deltaImp;
			const storeTest = getTest({ tests: storeTests, id });

			if (typeof storeTest === 'undefined') {
				if (process.env.BF_ENV === 'development') {
					console.warn(`Delta-imp with ID ${id} is not present.`);
				}

				return false;
			}

			return storeTest.value === value;
		},
		currentTest(id: number) {
			const storeTests = $accessor.user.deltaImp;
			const storeTest = getTest({ tests: storeTests, id });

			if (typeof storeTest === 'undefined') {
				if (process.env.BF_ENV === 'development') {
					console.warn(`Delta-imp with ID ${id} is not present.`);
				}

				return false;
			}

			return storeTest.value;
		},
		parseForStore,
		parseForCookie,
	};

	inject('deltaImp', deltaImp);

	if (!process.browser) {
		const tests = generateTests([
			{
				id: 0, // control group
				probability: [1, 1],
			},
			{
				id: 1, // control group
				probability: [1, 1, 1],
			},
			{
				id: 2, // control group
				probability: [1, 1, 1, 1],
			},
			{
				id: 17, // KAN-2708
				probability: [0, 1],
			},
			{
				id: 30,
				probability: [0, 1],
			},
			{
				id: 32,
				probability: [0, 1, 0],
			},
			{
				id: 36,
				probability: [0, 1],
			},
			{
				id: 37,
				probability: [1, 1],
			},
			{
				id: 39,
				probability: [1, 1],
			},
			{
				id: 42,
				probability: [0, 1],
			},

			// Always increment id for a new test!
			// {
			// id: 15
			// probability: [8, 1, 1]
			// }
		]);

		// Getting cookie and setting $store
		const cookieValue = $accessor.user.GET_COOKIE({
			name: cookieName,
			req,
		});

		$accessor.user.SET_DATA({
			deltaImp: parseForStore({ tests, cookieValue, store }),
		});
	} else {
		// Set cookie if values present
		const cookieValue = parseForCookie(store);

		if (cookieValue !== null) {
			$accessor.user.SET_COOKIE({
				name: cookieName,
				value: cookieValue,
			});
		}
	}
};

export const parseForStore = ({
	tests = [],
	cookieValue = '',

	store,
}: {
	tests: AB_TEST[];
	cookieValue: string | undefined;

	store: any;
}) => {
	const storeTests = [...tests];
	const cookieTests = cookieValue.split('-');

	cookieTests.forEach((cookieTest) => {
		const splits = cookieTest.split('.');

		const cookieTestId = parseInt(splits[0]);
		const cookieTestValue = parseInt(splits[1]);
		const device = store.state.ui.device;
		const storeTest = getTest({ tests: storeTests, id: cookieTestId });
		if (
			typeof storeTest !== 'undefined' &&
			storeTest.probability[cookieTestValue - 1] > 0
		) {
			if (storeTest.id === 17) {
				storeTest.value = device.isIphone ? 2 : 2;
			} else if (storeTest.id === 37) {
				storeTest.value = device.isIphone ? 1 : 2;
			} else {
				storeTest.value = cookieTestValue;
			}
			// for future use, change deltaImp based on country
			// if (storeTest.id === 38) {
			// 	const usdCountries = ['US'];
			// 	const gbpCountries = ['GB'];

			// 	if (
			// 		usdCountries.includes(store.state.user.localization.country)
			// 	) {
			// 		storeTest.value = 1;
			// 	} else if (
			// 		gbpCountries.includes(store.state.user.localization.country)
			// 	) {
			// 		storeTest.value = 2;
			// 	} else {
			// 		storeTest.value = 3;
			// 	}
			// }
		}
	});

	return storeTests;
};

export const parseForCookie = (store: any) => {
	const storeTests: AB_TEST[] = store.state.user.deltaImp;
	if (storeTests.length < 1) {
		return null;
	}
	// const localizationCountry = store.state.user.localization.country;
	const device = store.state.ui.device;

	// const usdCountries = ['US'];
	// const gbpCountries = ['GB'];
	const tests: string[] = [];

	storeTests.forEach((test) => {
		switch (test.id) {
			case 17:
				const value17 = device.isIphone ? 2 : 2;
				tests.push(`17.${value17}`);

				break;
			case 37:
				const value37 = device.isIphone ? 1 : 2;
				tests.push(`37.${value37}`);

				break;
			// for future use, change deltaImp based on country
			// case 38:
			// 	if (usdCountries.includes(localizationCountry)) {
			// 		tests.push(`38.1`);
			// 		store.commit('user/forceSetDeltaImpValue', {
			// 			id: 38,
			// 			value: 1,
			// 		});
			// 	} else if (gbpCountries.includes(localizationCountry)) {
			// 		tests.push(`38.2`);
			// 		store.commit('user/forceSetDeltaImpValue', {
			// 			id: 38,
			// 			value: 2,
			// 		});
			// 	} else {
			// 		tests.push(`38.3`);
			// 		store.commit('user/forceSetDeltaImpValue', {
			// 			id: 38,
			// 			value: 3,
			// 		});
			// 	}
			// 	break;
			default:
				tests.push(`${test.id}.${test.value}`);
		}
	});

	return tests.join('-');
};

export const getTest = ({ tests, id }: { tests: AB_TEST[]; id: number }) => {
	return tests.find((test) => test.id === id);
};

export const generateTests = (tests: AB_TEST_PROBABILITIES[] = []) => {
	return tests.map((test) => {
		return {
			...test,
			value: getRandomByProbability(test.probability),
		};
	});
};

export const getRandomOutOf = (max = 1) => {
	if (!Number.isInteger(max)) {
		return 1;
	}

	const min = 1;

	return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getRandomByProbability = (
	probabilities: AB_TEST_PROBABILITIES['probability'] = []
) => {
	let variant = 1;

	if (
		Array.isArray(probabilities) &&
		probabilities.length > 1 &&
		probabilities.every((probability) => Number.isInteger(probability))
	) {
		const pool = probabilities.reduce((a, b) => a + b);
		const random = getRandomOutOf(pool);

		let progress = 1;

		probabilities.every((probability, index) => {
			if (
				probability > 0 &&
				random >= progress &&
				random < progress + probability
			) {
				variant = index + 1;

				return false;
			} else {
				progress += probability;

				return true;
			}
		});
	}

	return variant;
};
