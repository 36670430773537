<script>
	export default {
		props: {
			type: {
				type: String,
				required: false,
				default: 'category',
			},
		},
		computed: {
			breadcrumbs() {
				return this.$store.state.page.content.breadcrumbs;
			},
		},
	};
</script>

<template>
	<div
		v-if="breadcrumbs && breadcrumbs.length > 0"
		:class="['breadcrumbs', { 'breadcrumbs--product': type === 'product' }]"
	>
		<ul class="breadcrumbs-list">
			<li
				v-for="(item, idx) in breadcrumbs"
				:key="item.url"
				class="breadcrumbs-item"
			>
				<NuxtLink :to="item.url">
					{{ item.title }}
				</NuxtLink>
				<i
					v-if="idx !== breadcrumbs.length - 1"
					class="icon-arrow--right breadcrumbs-item-icon"
				/>
			</li>
		</ul>
	</div>
</template>

<style scoped lang="scss">
	.breadcrumbs {
		@extend %has-paddings-t-lg-up;
		@extend %has-paddings-x-lg-up;

		&-list {
			display: flex;
			flex-wrap: wrap;
		}
		&-item {
			@include media-breakpoint-down(md) {
				font-size: 0.9rem;
			}
			&-icon {
				margin: 0 5px 0 3px;
				vertical-align: initial;
				font-size: 12px;
			}
		}

		&--product {
			@include media-breakpoint-down(md) {
				.breadcrumbs-list {
					justify-content: center;
					padding-top: 1rem;
				}
			}
		}
	}
</style>
