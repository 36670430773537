<template>
	<transition :name="transitionName">
		<div
			v-if="
				isFilterPage &&
				isInFilterMobile === isFilterMobileOn &&
				!isCategoryEmpty &&
				showed
			"
			:class="[
				'category-filter-toggle--mobile-container',
				{ 'is-in-filter--mobile': isInFilterMobile },
			]"
		>
			<button
				type="button"
				:disabled="isCategoryEmpty"
				:class="[
					'category-filter-toggle--mobile',
					{ 'is-loading': isFilterUpdating },
				]"
				@click="toggleFilterMobile()"
			>
				<span class="btn-inner">
					<i
						class="category-filter-toggle--mobile-icon icon-filter"
					/>
					{{ filterToggleLabel }}
				</span>
				<Spinner class="btn-spinner" />
			</button>
		</div>
	</transition>
</template>

<script>
	export default {
		props: {
			isInFilterMobile: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
				showed: false,
			};
		},
		computed: {
			productCount() {
				return this.$store.state.page.content.productCount;
			},
			isCategoryEmpty() {
				return this.productCount < 1;
			},
			isFilterPage() {
				return this.$store.state.ui.isFilterPage;
			},
			isFilterMobileOn() {
				return this.$store.state.ui.isFilterMobileOn;
			},
			isFilterUpdating() {
				return this.$store.state.ui.isFilterUpdating;
			},
			filterToggleLabel() {
				if (!this.isFilterMobileOn) {
					return this.$__('Filter products');
				}

				return this.productCount > 1
					? this.$__('Show %products% products', {
							products: this.productCount,
					  })
					: this.$__('Show %products% product', {
							products: this.productCount,
					  });
			},
			transitionName() {
				return `category-filter-toggle--mobile-transition--${
					this.isFilterMobileOn ? 'inside' : 'outside'
				}`;
			},
		},
		mounted() {
			this.showed = true; //  toggle value for smooth transition on first load
		},
		methods: {
			toggleFilterMobile() {
				this.$track('filter-open', this.$route.path);
				this.$store.dispatch(
					'ui/toggleFilterMobile',
					!this.isFilterMobileOn
				);
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/category-filter-toggle.scss';
</style>
