import InApp from 'detect-inapp';

export default ({ app }) => {
	app.router.beforeEach((to, from, next) => {
		const inApp = new InApp(
			navigator.userAgent || navigator.vendor || window.opera
		);
		if (
			inApp.isInApp &&
			Object.keys(to.query).length === 0 &&
			Object.keys(from.query).length > 0
		) {
			next({ ...to, query: from.query });
		}
		next();
	});
};
