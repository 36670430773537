<template>
	<transition name="gdpr-sheet-transition">
		<div v-if="isVisible" class="gdpr-sheet">
			<div class="gdpr-sheet-container">
				<div class="gdpr-sheet-text-and-icon">
					<i class="gdpr-sheet-icon icon-cookie" />
					<div class="gdpr-sheet-text">
						{{
							$__(
								'In order to give you a better service BAMBINIFASHION.COM uses cookies. By clicking "I agree" you are agreeing to our'
							)
						}}
						<NuxtLink
							class="gdpr-sheet-more"
							to="/info/privacy-policy"
						>
							{{ $__('use of cookies') }} </NuxtLink
						>.
						{{
							$__(
								'The cookies will be used for personalized content and ads'
							)
						}}.
						<button
							class="gdpr-sheet-decline"
							type="button"
							@click="setConsent(false)"
						>
							{{ $__('Decline') }}</button
						>.
					</div>
				</div>
				<button
					type="button"
					class="gdpr-sheet-cta"
					@click="setConsent(true)"
					v-text="$__('I agree')"
				/>
			</div>
		</div>
	</transition>
</template>

<script>
	import {
		get as getStoredConsent,
		set as storeConsent,
		isValid as isConsentValid,
		isGranted as isConsentGranted,
	} from '@/use/gdpr-consent.js';

	export default {
		data() {
			return {
				isVisible: false,
				intervalDuration: 3000,
				interval: null,
			};
		},
		mounted() {
			this.interval = setInterval(
				this.evaluateConsent,
				this.intervalDuration
			);
		},
		methods: {
			switchVisiblity(val) {
				this.isVisible = val;
			},
			evaluateConsent() {
				const storedConsent = getStoredConsent(this.$store);

				if (!isConsentValid(storedConsent)) {
					this.isVisible = true;
				} else if (isConsentGranted(storedConsent)) {
					this.setGlobalVar();
				}

				clearInterval(this.interval);
			},
			setConsent(isGranted) {
				this.isVisible = false;

				storeConsent(this.$store, isGranted);

				if (isGranted) {
					this.$gtag.consent(true);
					this.$smartlook.record(true);

					this.setGlobalVar();
				}
			},
			setGlobalVar() {
				window.__rmcp = [1, 2, 3, 4, 5];
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/gdpr-sheet.scss';
</style>
