export default ({ route, store }) => {
	const params = getApiPathParams({ route, store });

	return constructApiPath({ params, route });
};

export const getApiPathParams = ({ route, store }) => {
	const { page, common, user } = setDefaults();
	// Add params according to route

	switch (true) {
		case route.name === 'index':
			page.add('type=landing');
			break;

		case route.name === 'all' && route.path.endsWith('.html'):
			const productId = getProductId({ route });

			['type=product', `id=${productId}`].forEach((param) =>
				page.add(param)
			);
			[
				'delivery',
				'returns',
				'shipping',
				'proline',
				'tax',
				'hasEmail',
			].forEach((param) => user.add(param));
			break;

		case route.name === 'all':
			const categoryQueryParams = getCategoryQueryParams({ route });

			['type=category', ...categoryQueryParams].forEach((param) =>
				page.add(param)
			);
			['wishlist&mode=id', 'hasEmail'].forEach((param) =>
				user.add(param)
			);
			break;

		case route.name === 'info-subroute':
			const infoSubroute = getSubroute({ route });

			['type=info', `name=${infoSubroute}`].forEach((param) =>
				page.add(param)
			);

			const infoCommonParams = getInfoCommonParams({
				infoSubroute,
				store,
			});

			infoCommonParams.forEach((param) => common.add(param));
			break;

		case route.name.startsWith('account-'):
			const accountSubroute = getSubroute({
				route,
				namespace: 'account-',
			});
			const accountUserParams = getAccountUserParams({
				route,
				namespace: 'account-',
			});

			['type=account', `name=${accountSubroute}`].forEach((param) =>
				page.add(param)
			);

			accountUserParams.forEach((param) => user.add(param));
			break;

		case route.name.startsWith('checkout-'):
			const checkoutSubroute = getSubroute({
				route,
				namespace: 'checkout-',
			});
			const checkoutUserParams = getCheckoutUserParams({
				route,
				namespace: 'checkout-',
			});

			['type=checkout', `name=${checkoutSubroute}`].forEach((param) =>
				page.add(param)
			);

			checkoutUserParams.forEach((param) => user.add(param));
			break;

		case route.name === 'shopping-bag':
			['cart', 'tax'].forEach((param) => user.add(param));

			page.add(`type=${route.name}`);
			break;

		case route.name === 'wishlist':
			['wishlist'].forEach((param) => user.add(param));

			user.add('hasEmail');

			page.add(`type=${route.name}`);
			break;

		case route.name === 'wishlist-sign-in':
			break;

		case route.name === 'getapp':
			break;

		case route.name.includes('blog'):
			break;

		case route.name === 'magic-query':
			break;

		case route.name !== '':
			page.add(`type=${route.name}`);
			break;

		default:
	}

	return {
		page: [...page],
		common: [...common],
		user: [...user],
	};
};

export const setDefaults = () => {
	// Set defaults
	const page = new Set();
	const common = new Set();
	const user = new Set();

	// Get common data when only when starting an app
	if (!process.browser) {
		[
			'localizations',
			'navigation',
			'methods',
			'error',
			'sizeGuide',
			'infoNavigation',
			'checkoutNavigation',
			'workingHours',
			'states',
			'infoContact',
			'footerMenu',
		].forEach((param) => common.add(param));

		[
			'delivery',
			'returns',
			'shipping',
			'tax',
			'proline',
			'welcomePopup',
			'accountNavigation',
			'personalInformation',
		].forEach((param) => user.add(param));
	} else {
		user.add('personalInformation');
	}

	return { page, common, user };
};

export const getSubroute = ({ route, namespace }) => {
	let subroute = route.params.subroute;

	if (subroute == null && namespace != null) {
		subroute = route.name.slice(namespace.length);
	}

	return subroute;
};

export const getProductId = ({ route }) => {
	return route.path.match(/(\d+)[^-]*$/g)[0].split('.')[0];
};

export const getSearchQueryMatches = ({ route }) => {
	return /^search\/(?:((.+?)(\/.+))|(?:((?!page=).+?))$)/gm.exec(
		route.params.pathMatch
	);
};

export const getCategoryQueryParams = ({ route }) => {
	const params = [];
	let categoryQuery;
	let searchQuery;

	const searchQueryMatches = getSearchQueryMatches({ route });

	switch (true) {
		case searchQueryMatches == null:
			categoryQuery = `/${route.params.pathMatch}`;
			break;

		case searchQueryMatches[4] != null:
			categoryQuery = '/search';
			searchQuery = encodeURIComponent(searchQueryMatches[4]);
			break;

		case searchQueryMatches[2] != null && searchQueryMatches[3] != null:
			categoryQuery = '/search' + searchQueryMatches[3];
			searchQuery = encodeURIComponent(searchQueryMatches[2]);
			break;

		default:
			console.log('mimo');
	}

	categoryQuery = `${categoryQuery
		.slice(1)
		.split('/page')
		.join('&page')
		.split('/')
		.join('|')
		.split(',')
		.join(';')}`;

	params.push(`query=${categoryQuery}`);

	if (searchQuery) {
		params.push(`searchQuery=${searchQuery}`);
	}

	return params;
};

export const getInfoCommonParams = ({ infoSubroute, store }) => {
	const params = [];

	if (!store.state.common.infoPage.hasOwnProperty(infoSubroute)) {
		params.push(`infoPage=${infoSubroute}`);
	}

	if (
		infoSubroute === 'jobs' &&
		typeof store.state.common.infoJobs === 'undefined'
	) {
		params.push('infoJobs');
	}

	return params;
};

export const getAccountUserParams = ({ route, namespace }) => {
	const params = ['accountNavigation', 'personalInformation'];

	const accountSubroute = getSubroute({ route, namespace });

	switch (true) {
		case accountSubroute === 'orders' && route.params.reference != null:
			params.push(
				'order',
				`orderReference=${route.params.reference.toUpperCase()}`
			);
			break;

		case accountSubroute === 'orders':
			params.push('orders');
			break;

		default:
			params.push('address');
	}

	return params;
};

export const getCheckoutUserParams = ({ route, namespace }) => {
	const params = [];

	const checkoutSubroute = getSubroute({ route, namespace });

	switch (checkoutSubroute) {
		case 'shipping':
			params.push('cart', 'delivery', 'address', 'tax');
			break;

		case 'payment':
			params.push('cart', 'delivery', 'address', 'tax', 'payment');
			break;

		case 'thank-you':
			params.push('personalInformation');
			break;

		default:
			params.push('cart', 'address');
	}

	return params;
};

export const constructApiPath = ({ params, route }) => {
	const modulePathFragments = [];
	let path = '';

	Object.keys(params).forEach((module) => {
		if (params[module].length < 1) {
			return;
		}

		modulePathFragments.push(`${module}:${params[module].join('&')}`);
	});

	path = modulePathFragments.join(',');

	if (Object.keys(route.query).length !== 0) {
		path += `?${route.fullPath.split('?')[1]}`;
	}

	return path;
};
