export default ({
	url,
	size = '',
	isHiRes = false,
	extension = 'jpg',
	hash = '',
}) => {
	const formattedSize = size ? `--${size}` : '';
	const formattedHash = hash ? `?v=${hash}` : '';

	if (isHiRes) {
		return `${url}${formattedSize}@2x.${extension}${formattedHash} 2x, ${url}${formattedSize}.${extension}${formattedHash} 1x`;
	} else {
		return `${url}${formattedSize}.${extension}${formattedHash}`;
	}
};
