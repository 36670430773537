const usdCounties = ['US'];
const gbpCountries = ['GB'];

const countriesTextMap = new Map([
	[usdCounties, 'Text for US'],
	[gbpCountries, 'Text for GB'],
]);

export const textByCountry = (country) => {
	for (let key of countriesTextMap.keys()) {
		if (key.includes(country)) {
			return countriesTextMap.get(key);
		}
	}
	return 'Text for other Countries';
};
export const replaceCurrency = (text, currency, currentTest) => {
	const currencies = [
		'BGN',
		'CZK',
		'DKK',
		'EUR',
		'GBP',
		'HUF',
		'NOK',
		'PLN',
		'RON',
		'RUB',
		'SEK',
		'CHF',
	];

	return currencies.includes(currency)
		? text.replace('$', '€')
		: text.replace('€', '$');
};
