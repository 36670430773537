import https from 'https';
import { NuxtAppOptions } from '@nuxt/types/app';
import { AxiosError } from 'axios';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { Context } from '@nuxt/types';
import { configureScope, captureException } from '@sentry/vue';
import { getUtmSourceParams } from '@/use/utm-source';

// eslint-disable-next-line import/no-mutable-exports
export let apiClient: NuxtAxiosInstance;

export function setClient(newClient: NuxtAxiosInstance) {
	apiClient = newClient;
}

type NuxtContext = Context & {
	app: NuxtAppOptions;
};

export default function ({
	app,
	$config,
	$axios,
	redirect,
	error,
	store,
	req,
}: NuxtContext) {
	setClient($axios);
	const agent = new https.Agent({
		rejectUnauthorized: false,
	});

	if (req !== undefined) {
		$axios.setBaseURL(`${$config.SSR_API_URL || $config.API_URL}/json/`);
	} else {
		$axios.setBaseURL(`${$config.API_URL}/json/`);
	}

	$axios.onRequest((config) => {
		const localization = store.state.user.localization;

		const markers = getUtmSourceParams(
			store,
			app.router!.currentRoute.query
		);

		let queryId = '';
		if (process.browser) {
			const localQueryId = localStorage.getItem('queryId');
			if (localQueryId) {
				queryId = localQueryId;
			}
		}

		const headers: Record<string, string | string[]> = {
			'bf-localization': `${localization.country},${localization.currency},${localization.language}`,
			'bf-origin-path': app.router!.currentRoute.fullPath,
			'bf-customer-hash': store.state.user.hash,
			...(markers && { 'bf-markers': markers }),
			'bf-action': 'trap',
			...($config.IS_PREFLIGHT && {
				'bf-preflight': $config.IS_PREFLIGHT,
			}),
		};

		if (store.state.user.referer) {
			headers['bf-referer'] = store.state.user.referer;
		}

		if (queryId) {
			headers['bf-query-id'] = queryId;
		}

		// Inject deltaImp
		const deltaImp = app.$deltaImp.parseForCookie(store);

		if (deltaImp) {
			headers['bf-delta-imp'] = deltaImp;
		}

		if (req !== undefined) {
			const ip =
				req.headers['x-forwarded-for'] || req.socket.remoteAddress;

			if (ip != null) {
				headers['x-forwarded-for'] = ip;
			}

			if (req.headers['user-agent'] != null) {
				headers['user-agent'] = req.headers['user-agent'];
			}
		}

		// Set arbitary headers
		Object.keys(headers).forEach((header) => {
			config.headers.common[header] = headers[header];
		});

		// Modify HTTPS agent
		config.httpsAgent = agent;

		// Don't use nuxt loader for "async" requests
		config.progress = false;

		// Server log
		if (process.server && process.env.NODE_ENV !== 'production') {
			Object.keys(headers).forEach((header) => {
				console.log(` HEADER  ${header}:`, headers[header]);
			});
			console.log(' FETCH  url:', `${config.baseURL}${config.url}`);
		}
	});

	$axios.onResponse((response) => {
		// Redirect if corresponding header is available

		if (typeof response.headers['bf-redirect-path'] !== 'undefined') {
			// don't redirect queries within search page
			if (
				response.config?.url?.includes('search') &&
				response.config?.url?.includes('searchQuery')
			) {
				return;
			}
			redirect(response.headers['bf-redirect-path']);
			return;
		}

		// Override localization from API response
		if (typeof response.headers['bf-localization'] !== 'undefined') {
			const currentLocalization = store.state.user.localization;
			const headerLocalization =
				response.headers['bf-localization'].split(',');
			const localization = {
				country: headerLocalization[0],
				currency: headerLocalization[1],
				language: headerLocalization[2],
			};
			const hasLocalizationMismatch = Object.keys(
				currentLocalization
			).some(
				(prop, index) =>
					headerLocalization[index] !== currentLocalization[prop]
			);

			if (hasLocalizationMismatch) {
				store.commit('user/SET_LOCALIZATION', localization);
				store.commit('user/SET_DATA', { recentCategory: null });
			}
		}
	});

	$axios.onError((err) => {
		// Pass specified errors for further handling
		if (err.response && err.response.status) {
			switch (true) {
				case [400].includes(err.response.status):
					error({
						statusCode: err.response.status,
						message: err.response.data.message,
					});
					return;
				case [404, 406, 422, 424, 429].includes(err.response.status):
					return;

				default:
			}
		}

		// Expose the error otherwise
		if (process.server) {
			console.log(err);
		} else {
			const message = getErrorResponseMessage(err);

			if (!isOffline(err)) {
				console.warn(err.response);

				// Report to Sentry
				configureScope((scope) => {
					if (err.response) {
						scope.setExtra('Data', err.response.data);
						scope.setExtra(
							'Status',
							`${err.response.status} ${err.response.statusText}`
						);
						scope.setExtra('Headers', err.response.headers);
						captureException(err);
					}
				});
			}

			store.dispatch('ui/toggleOverlay', {
				overlayData: {
					content: message,
				},
			});
		}
	});

	$axios.defaults.withCredentials = true;
}

export const isOffline = (error: any) => {
	return !error.response || error.code === 'ECONNABORTED';
};

export const getErrorResponseMessage = (error: AxiosError) => {
	if (isOffline(error)) {
		return 'It seems that your internet connection has been interrupted. Please refresh the page once you go back online.';
	}

	if (!error.response) {
		return 'General axios error. No response found.';
	}

	if (error.response.data) {
		return error.response.data.message || error.response.data;
	}
	return error.response.statusText || error.response;
};
