<script>
	export default {
		name: 'SlideUpDown',
		props: {
			isEnabled: {
				type: Boolean,
				default: true,
			},
			duration: {
				type: Number,
				default: 250,
			},
			tag: {
				type: String,
				default: 'div',
			},
			styleWhenHidden: {
				type: Object,
				default() {
					return {};
				},
			},
			isActive: Boolean,
		},
		data: () => ({
			style: {},
			isInit: false,
		}),
		computed: {
			el() {
				return this.$refs.container;
			},
			styleHidden() {
				return {
					height: '0',
					overflow: 'hidden',
					...this.styleWhenHidden,
				};
			},
		},
		watch: {
			isActive() {
				this.layout();
			},
		},
		mounted() {
			this.layout();
			this.isInit = true;
		},
		methods: {
			layout() {
				if (!this.isEnabled) {
					this.style = {};
					return;
				}

				if (this.isActive) {
					this.$bus.$emit('slide-down-start');
					if (this.isInit) {
						this.setHeight(
							this.styleHidden.height,
							() => this.el.scrollHeight + 'px'
						);
					}
				} else {
					this.$bus.$emit('slide-up-start');
					this.setHeight(
						this.el.scrollHeight + 'px',
						() => this.styleHidden.height
					);
				}
			},
			asap(callback) {
				if (!this.isInit) {
					callback();
				} else {
					this.$nextTick(callback);
				}
			},
			setHeight(beforeRelayout, afterRelayout) {
				this.style = { height: beforeRelayout };

				this.asap(() => {
					this.__ = this.el.scrollHeight; // force relayout so the animation will run

					this.style = {
						height: afterRelayout(),
						overflow: 'hidden',
						'transition-property': 'height',
						'transition-duration': this.duration + 'ms',
					};
				});
			},
			onTransitionEnd(event) {
				if (event.target !== this.$el) {
					return;
				} // filter out transitionends from children

				if (this.isActive) {
					this.style = {};
					this.$bus.$emit('slide-down-end');
				} else {
					this.style = this.styleHidden;
					this.$bus.$emit('slide-up-end');
				}
			},
		},
		render(h) {
			let self = this;

			return h(
				this.tag,
				{
					style:
						!this.isInit && !this.isActive
							? this.styleHidden
							: this.style,
					ref: 'container',
					attrs: {
						'aria-hidden': !this.isActive,
					},
					class: {
						'is-expanded': this.isActive,
						'remove-flickering': true,
					},
					on: {
						transitionend($event) {
							self.onTransitionEnd($event);
						},
					},
				},
				this.$slots.default
			);
		},
	};
</script>

<style scoped lang="scss">
	.remove-flickering {
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
</style>
