import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _83dfe29a = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _7a97f203 = () => interopDefault(import('../pages/account/_.vue' /* webpackChunkName: "pages/account/_" */))
const _1f54e7d5 = () => interopDefault(import('../components/AsideNav.vue' /* webpackChunkName: "components/AsideNav" */))
const _67ed178c = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _652682c0 = () => interopDefault(import('../pages/blog/_postOrCategory/index.vue' /* webpackChunkName: "pages/blog/_postOrCategory/index" */))
const _1cb34a30 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _64411d1d = () => interopDefault(import('../pages/checkout/thank-you.vue' /* webpackChunkName: "pages/checkout/thank-you" */))
const _854b7b80 = () => interopDefault(import('../pages/checkout/_.vue' /* webpackChunkName: "pages/checkout/_" */))
const _95b6083c = () => interopDefault(import('../components/Checkout/CheckoutHeader.vue' /* webpackChunkName: "components/Checkout/CheckoutHeader" */))
const _2849d26e = () => interopDefault(import('../pages/designers.vue' /* webpackChunkName: "pages/designers" */))
const _12c161f5 = () => interopDefault(import('../pages/getapp.vue' /* webpackChunkName: "pages/getapp" */))
const _c4c89e74 = () => interopDefault(import('../pages/health.vue' /* webpackChunkName: "pages/health" */))
const _cc1232d0 = () => interopDefault(import('../pages/info.vue' /* webpackChunkName: "pages/info" */))
const _0faf36b0 = () => interopDefault(import('../pages/info/_.vue' /* webpackChunkName: "pages/info/_" */))
const _46089c9a = () => interopDefault(import('../pages/magic.vue' /* webpackChunkName: "pages/magic" */))
const _105708ae = () => interopDefault(import('../pages/magic-query.vue' /* webpackChunkName: "pages/magic-query" */))
const _3460678e = () => interopDefault(import('../pages/not-found.vue' /* webpackChunkName: "pages/not-found" */))
const _70e63b8d = () => interopDefault(import('../pages/shopping-bag.vue' /* webpackChunkName: "pages/shopping-bag" */))
const _15468272 = () => interopDefault(import('../pages/wishlist/index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _31913155 = () => interopDefault(import('../pages/wishlist/sign-in.vue' /* webpackChunkName: "pages/wishlist/sign-in" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _638cc3d4 = () => interopDefault(import('../components/CategoryBanner.vue' /* webpackChunkName: "components/CategoryBanner" */))
const _3c1d7416 = () => interopDefault(import('../components/CategoryFilterV2/CategoryFilterV2.vue' /* webpackChunkName: "components/CategoryFilterV2/CategoryFilterV2" */))
const _447a9f74 = () => interopDefault(import('../components/CategoryList.vue' /* webpackChunkName: "components/CategoryList" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _83dfe29a,
    redirect: "/account/overview",
    pathToRegexpOptions: {"strict":true},
    name: "account",
    children: [{
      path: ":subroute(overview|sign-in|register|upgrade|forgot-password|reset-password|addresses|add-address|payment|discounts|personal)",
      components: {
        default: _7a97f203,
        nav: _1f54e7d5
      },
      pathToRegexpOptions: {"strict":true},
      name: "account-subroute"
    }, {
      path: "orders/:reference?",
      components: {
        default: _7a97f203,
        nav: _1f54e7d5
      },
      pathToRegexpOptions: {"strict":true},
      name: "account-orders"
    }, {
      path: "edit-address/:id(\\d+)",
      components: {
        default: _7a97f203,
        nav: _1f54e7d5
      },
      pathToRegexpOptions: {"strict":true},
      name: "account-edit-address"
    }, {
      path: "*",
      components: {
        default: _7a97f203,
        nav: _1f54e7d5
      },
      redirect: "/account/overview",
      pathToRegexpOptions: {"strict":true},
      name: "account-all"
    }]
  }, {
    path: "/blog",
    component: _67ed178c,
    pathToRegexpOptions: {"strict":true},
    name: "blog",
    children: [{
      path: ":postOrCategory",
      component: _652682c0,
      pathToRegexpOptions: {"strict":true},
      name: "blog-postOrCategory"
    }]
  }, {
    path: "/checkout",
    component: _1cb34a30,
    redirect: "/checkout/shipping",
    pathToRegexpOptions: {"strict":true},
    name: "checkout",
    children: [{
      path: "thank-you",
      component: _64411d1d,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-thank-you"
    }, {
      path: ":subroute(shipping|sign-in|forgot-password|payment)",
      components: {
        default: _854b7b80,
        nav: _95b6083c
      },
      pathToRegexpOptions: {"strict":true},
      name: "checkout-subroute"
    }, {
      path: "add-address/:section(shipping|billing)?",
      components: {
        default: _854b7b80,
        nav: _95b6083c
      },
      pathToRegexpOptions: {"strict":true},
      name: "checkout-add-address"
    }, {
      path: "edit-address/:section(shipping|billing)?/:id(\\d+)",
      components: {
        default: _854b7b80,
        nav: _95b6083c
      },
      pathToRegexpOptions: {"strict":true},
      name: "checkout-edit-address"
    }, {
      path: "*",
      components: {
        default: _854b7b80,
        nav: _95b6083c
      },
      redirect: "/checkout/shipping",
      pathToRegexpOptions: {"strict":true},
      name: "checkout-all"
    }]
  }, {
    path: "/designers",
    component: _2849d26e,
    pathToRegexpOptions: {"strict":true},
    name: "designers"
  }, {
    path: "/getapp",
    component: _12c161f5,
    pathToRegexpOptions: {"strict":true},
    name: "getapp"
  }, {
    path: "/health",
    component: _c4c89e74,
    pathToRegexpOptions: {"strict":true},
    name: "health"
  }, {
    path: "/info",
    component: _cc1232d0,
    redirect: "/info/about-us",
    pathToRegexpOptions: {"strict":true},
    name: "info",
    children: [{
      path: ":subroute(about-us|delivery|return-policy|terms-and-conditions|privacy-policy|affiliates|jobs|imprint)",
      components: {
        default: _0faf36b0,
        nav: _1f54e7d5
      },
      pathToRegexpOptions: {"strict":true},
      name: "info-subroute"
    }, {
      path: "*",
      components: {
        default: _0faf36b0,
        nav: _1f54e7d5
      },
      redirect: "/info/about-us",
      pathToRegexpOptions: {"strict":true},
      name: "info-all"
    }]
  }, {
    path: "/magic",
    component: _46089c9a,
    pathToRegexpOptions: {"strict":true},
    name: "magic"
  }, {
    path: "/magic-query",
    component: _105708ae,
    pathToRegexpOptions: {"strict":true},
    name: "magic-query"
  }, {
    path: "/not-found",
    component: _3460678e,
    pathToRegexpOptions: {"strict":true},
    name: "not-found"
  }, {
    path: "/shopping-bag",
    component: _70e63b8d,
    pathToRegexpOptions: {"strict":true},
    name: "shopping-bag"
  }, {
    path: "/wishlist",
    component: _15468272,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist"
  }, {
    path: "/wishlist/sign-in",
    component: _31913155,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist-sign-in"
  }, {
    path: "/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/*",
    components: {
      default: _2efaf336,
      banner: _638cc3d4,
      filter: _3c1d7416,
      products: _447a9f74
    },
    pathToRegexpOptions: {"strict":true},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
