<template>
	<li
		v-click-outside="onClickOutsideDropmenu"
		:class="[
			'nav--desktop-list-item',
			'nav--desktop-list-item--' + item.name,
			{ 'has-hover': hasHover },
		]"
		@mouseenter="onHover(true)"
		@mouseleave="onHover(false)"
		@touchstart="onTouchStart"
	>
		<div class="nav--desktop-list-item-mask">
			<NuxtLink
				:id="'nav--desktop-list-item-link--' + item.name"
				:to="item.url"
				:aria-haspopup="item.dropmenu ? 'true' : 'false'"
				:aria-expanded="isNavDropmenuOn ? 'true' : 'false'"
				:class="[
					'nav--desktop-list-item-link',
					'nav--desktop-list-item-link--' + item.name,
				]"
				event=""
				:style="{ ...(item.color && { color: item.color }) }"
				@click.native="onClickNavLink($event, true)"
				v-text="item.title"
			/>
		</div>
		<template v-if="item.sections">
			<transition name="nav--desktop-dropmenu-transition">
				<div
					v-show="isNavDropmenuOn"
					class="nav--desktop-dropmenu"
					:aria-labelledby="
						'nav--desktop-list-v2-item-link--' + item.name
					"
				>
					<div class="nav--desktop-dropmenu-sections">
						<div
							v-for="section in item.sections"
							:key="`nav--desktop-dropmenu-section--${section.name}`"
							:class="[
								'nav--desktop-dropmenu-section',
								'nav--desktop-dropmenu-section--' +
									section.name,
							]"
						>
							<div
								class="nav--desktop-dropmenu-title"
								v-text="section.title"
							/>
							<LazyHydrate when-visible when-idle>
								<ul
									:class="[
										'nav--desktop-dropmenu-list-v2',
										'nav--desktop-dropmenu-list-v2--' +
											section.name,
									]"
								>
									<li
										v-for="(
											sectionItem, index
										) in section.children"
										:key="`nav--desktop-dropmenu-list-v2-item--${index}`"
										:class="
											'nav--desktop-dropmenu-list-v2-item--' +
											section.name
										"
									>
										<NuxtLink
											:to="sectionItem.url"
											:class="
												getSectionItemClass(sectionItem)
											"
											:data-label="
												getSectionItemLabel(
													sectionItem
												) || false
											"
											@click.native="
												onClickNavLink($event, false)
											"
										>
											<span
												:class="[
													'nav--desktop-dropmenu-list-v2-item-title',
													'nav--desktop-dropmenu-list-v2-item-title--' +
														section.name,
												]"
												>{{
													sectionItem.title
														? sectionItem.title
														: sectionItem.name
												}}</span
											>
										</NuxtLink>
										<ul
											v-if="sectionItem.children"
											class="nav--desktop-dropmenu-list-v2-children"
										>
											<template
												v-for="(
													subSectionItem, index
												) in sectionItem.children"
											>
												<li
													v-if="
														!subSectionItem.isBold
													"
													:key="`nav--desktop-dropmenu-list-v2-children--${index}`"
													class="nav--desktop-dropmenu-list-v2-children-item"
												>
													<NuxtLink
														:to="subSectionItem.url"
														:class="
															getSectionItemClass(
																subSectionItem
															)
														"
														:data-label="
															getSectionItemLabel(
																subSectionItem
															) || false
														"
														@click.native="
															onClickNavLink(
																$event,
																false
															)
														"
													>
														<span
															class="nav--desktop-dropmenu-list-v2-children-name"
															>{{
																subSectionItem.title
															}}</span
														>
													</NuxtLink>
												</li>
											</template>
										</ul>
									</li>
									<li
										class="nav--desktop-dropmenu-list-v2-item--all"
									>
										<NuxtLink
											:to="item.url"
											class="nav--desktop-dropmenu-list-v2-all"
											@click.native="
												onClickNavLink($event, false)
											"
										>
											<strong>
												{{
													section.name === 'brand'
														? section.viewAllButton
																.title
																.desktopLong
														: section.name !==
														  'size'
														? section.viewAllButton
																.title
																.desktopLong
														: section.viewAllButton
																.title
																.desktopShort
												}}
											</strong>
										</NuxtLink>
									</li>
								</ul>
							</LazyHydrate>
						</div>
					</div>
					<NuxtLink
						v-if="item.banner"
						:to="item.banner.url"
						class="nav--desktop-dropmenu-section nav--desktop-dropmenu--banner has-objectfit"
						@click.native="onClickNavLink($event, false)"
					>
						<figure>
							<picture>
								<source
									:data-srcset="
										$dataSrc({
											url: item.banner.image.src,
											isHiRes: true,
											extension: 'webp',
										})
									"
									type="image/webp"
								/>
								<source
									:data-srcset="
										$dataSrc({
											url: item.banner.image.src,
											isHiRes: true,
										})
									"
									type="image/jpeg"
								/>
								<LazyImg
									:alt="item.title"
									:data-src="
										$dataSrc({
											url: item.banner.image.src,
										})
									"
									class="nav--desktop-dropmenu--banner-image is-objectfit"
								/>
							</picture>
							<figcaption
								class="nav--desktop-dropmenu--banner-caption"
								v-html="item.banner.caption"
							/>
						</figure>
					</NuxtLink>
				</div>
			</transition>
		</template>
	</li>
</template>

<script>
	import animatedScrollTo from 'animated-scroll-to';

	export default {
		props: {
			item: {
				type: Object,
				default() {
					return {};
				},
			},
		},
		data() {
			return {
				hasHover: false,
				isNavDropmenuOn: false,
				hasClickClearance: true,
			};
		},
		watch: {
			isNavDropmenuOn(val) {
				this.$store.commit('ui/SET_DATA', {
					isNavDropmenuOn: val,
				});
				if (val) {
					this.$lazyload.generic.update();
				}
			},
			$route() {
				this.toggleDropmenu(false);
				this.hasHover = false;
			},
		},
		methods: {
			navigate(event) {
				const element = event.currentTarget;

				this.$router.push(element.pathname);
				this.$track('nav-desktop', { element });
			},
			toggleDropmenu(isOn) {
				this.isNavDropmenuOn = isOn;
			},
			onClickOpenDropmenu(event) {
				if (this.item.dropmenu) {
					if (!this.isNavDropmenuOn) {
						this.toggleDropmenu(true);
					}
					if (this.hasClickClearance) {
						this.navigate(event);
					}
				} else {
					this.navigate(event);
				}
			},
			onClickNavLink(event, isTopLevelLink) {
				const element = event.currentTarget;

				if (
					this.$route.path === element.pathname &&
					(!this.item.sections || this.hasClickClearance)
				) {
					if (process.browser) {
						animatedScrollTo(0);
					}
					this.$nextTick(() => {
						this.onHover(false);
					});
				}

				if (isTopLevelLink) {
					this.onClickOpenDropmenu(event);
				} else {
					this.$track('nav-desktop', { element });
				}
			},
			onHover(isHover) {
				this.hasHover = isHover;

				if (this.item.sections && this.$mq.is('lg-up')) {
					this.toggleDropmenu(isHover);
				}
			},
			onTouchStart() {
				this.hasClickClearance = this.isNavDropmenuOn;
			},
			onClickOutsideDropmenu() {
				if (this.$mq.is('lg-up')) {
					this.toggleDropmenu(false);
				}
			},
			getSectionItemLabel(item) {
				switch (item.label) {
					case 'isComingSoon':
						return 'Coming Soon';

					case 'isNew':
						return 'New';

					default:
						return '';
				}
			},
			getSectionItemClass(item) {
				return this.$case(item.label) || null;
			},
			getDropmenuItemTitle(item) {
				return item.label !== ''
					? `<span class="nav--desktop-dropmenu-list-v2-item-title">${item.title}</span>`
					: item.title;
			},
		},
	};
</script>
