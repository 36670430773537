<template>
	<button type="button" @click="onClick">
		<slot />
	</button>
</template>

<script>
	export default {
		computed: {
			inputId() {
				return this.$store.state.ui.searchInputId;
			},
		},
		methods: {
			onClick() {
				this.$store.dispatch('ui/toggleSearch', true);
				this.setInputInFocus(this.inputId);
			},
			setInputInFocus(inputId) {
				if (!process.browser) {
					return;
				}

				this.$nextTick(() => {
					const input = document.getElementById(inputId);

					this.$el.blur();
					input.focus();
				});
				setTimeout(() => {
					if (this.$mq.is('md-down')) {
						window.scrollTo(0, 0);
					}
					window.onResize = function () {
						document.body.height = window.innerHeight;
					};
					window.onResize();
				}, 75);
			},
		},
	};
</script>
