class Errors {
	/**
	 * Create a new Errors instance.
	 */
	constructor() {
		this.errors = {};
	}

	/**
	 * Determine if an errors exists for the given field.
	 */
	has(field) {
		return this.errors.hasOwnProperty(field);
	}

	/**
	 * Determine if we have any errors.
	 */
	any() {
		return Object.keys(this.errors).length;
	}

	/**
	 * Retrieve the error message for a field.
	 *
	 * @param {string} field
	 */
	get(field) {
		if (this.errors[field]) {
			return this.errors[field].join(' ');
		}
	}

	/**
	 * Record the new errors.
	 */
	record(errors) {
		Object.keys(errors).forEach((field) => {
			if (!errors[field].length) {
				delete errors[field];
			}
		});

		this.errors = errors;
	}

	/**
	 * Clear one or all error fields.
	 */
	clear(field) {
		if (field) {
			delete this.errors[field];

			return;
		}

		this.errors = {};
	}
}

class Form {
	/**
	 * Create a new Form instance.
	 *
	 * @param {object} data
	 */
	constructor(data) {
		this.$axios = data.$axios;
		this.url = null;
		this.method = null;
		this.fields = data.fields;
		this.isLoading = false;
		this.isPinned = false;
		this.onSuccessHook = data.onSuccess;
		this.onErrorHook = data.onError;
		this.errors = new Errors();
	}

	/**
	 * Fetch all relevant data for the form submission via GET
	 */
	buildDataForGet() {
		const data = {
			params: {},
		};

		for (const field in this.fields) {
			if (typeof this.fields[field] !== 'undefined') {
				Object.assign(data.params, {
					[field]: this.fields[field],
				});
			}
		}

		return data;
	}

	/**
	 * Fetch all relevant data for the form submission via POST
	 */
	buildDataForPost() {
		const data = new FormData();

		for (const field in this.fields) {
			data.append(
				field,
				typeof this.fields[field] !== 'undefined'
					? this.fields[field]
					: ''
			);
		}

		return data;
	}

	/**
	 * Reset the form.
	 */
	reset() {
		this.clear();
		this.errors.clear();
	}

	/**
	 * Clear the form fields.
	 */
	clear() {
		for (const field in this.fields) {
			if (Object.prototype.hasOwnProperty.call(this.fields, field)) {
				this.fields[field] = '';
			}
		}
	}

	/**
	 * Submit the form.
	 */
	submit(method, url) {
		this.isLoading = true;

		this.method = method;
		this.url = url;

		this.$axios['$' + (this.method || 'get')](
			this.url,
			this.method === 'get'
				? this.buildDataForGet()
				: this.buildDataForPost()
		)
			.then((response) => {
				this.onSuccess(response);
			})
			.catch((error) => {
				this.onError(error);
			});
	}

	/**
	 * Handle a successful form submission.
	 */
	onSuccess(response) {
		if (typeof this.onSuccessHook !== 'undefined') {
			this.onSuccessHook(response);
		}
	}

	/**
	 * Handle a failed form submission.
	 */
	onError(error) {
		this.isLoading = false;

		if (error.response && error.response.status === 422) {
			const errors = this.getErrors(error.response.data);

			if (errors) {
				this.errors.record(errors);

				return;
			}
		}

		if (typeof this.onErrorHook !== 'undefined') {
			this.onErrorHook(error);
		}
	}

	getErrors(response) {
		let errors = [];

		if (this.url == null) {
			return errors;
		}

		switch (true) {
			case this.url.startsWith('user:login'):
				errors = response.user.login.errors;
				break;
			case this.url.startsWith('user:register'):
				errors = response.user.register.errors;
				break;
			case this.url.startsWith('user:upgrade'):
				errors = response.user.upgrade.errors;
				break;
			case this.url.startsWith('user:update'):
				errors = response.user.update.errors;
				break;
			case this.url.startsWith('user:resetPassword'):
				errors = response.user.resetPassword.errors;
				break;
			case this.url.startsWith('user:forgotPassword'):
				errors = response.user.forgotPassword.errors;
				break;
			case this.url.startsWith('user:subscribeNewsletter'):
				errors = response.user.subscribeNewsletter.errors;
				break;
			case this.url.startsWith('user:requestSize'):
				errors = response.user.requestSize.errors;
				break;
			case this.url.startsWith('user:address'):
				errors = response.user.address.errors;
				break;
			case this.url.startsWith('user:voucher'):
				errors = response.user.voucher.errors;
				break;
			case this.url.startsWith('user:orderEmail'):
				errors = response.user.orderEmail.errors;
				break;

			default:
		}

		return errors;
	}
}

export default Form;
