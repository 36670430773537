export default async function ({ app, redirect, route, store, router }) {
	let payment = null;
	const paypalToken = route.query.token;
	const paypalPayerId = route.query.PayerID;

	const { merchantOrderReference, sofortTransaction } = route.query;

	const { payment_intent_client_secret, payment_intent } = route.query;

	const { status, orderToken } = route.query;

	if (paypalToken && paypalPayerId) {
		payment = {
			paymentMethod: 'paypal',
			payerId: paypalPayerId,
			token: paypalToken,
		};
	}

	if (sofortTransaction && merchantOrderReference) {
		payment = {
			paymentMethod: 'sofort',
			sofortTransaction,
			merchantOrderReference,
		};
	}

	if (payment_intent) {
		payment = {
			paymentMethod: 'card',
			paymentIntentId: payment_intent,
		};
	}

	if (orderToken && status === 'SUCCESS') {
		payment = {
			paymentMethod: 'afterpay',
			status,
			orderToken,
		};
	}

	if (payment) {
		try {
			const response = await app.$axios.post('payment', payment);
			if (response.data.status === 'succeeded') {
				await redirect('/checkout/thank-you');
			}
		} catch (error) {
			if (payment.paymentMethod === 'afterpay') {
				await redirect({
					path: '/checkout/payment',
					query: {
						error: error.response.data.message,
					},
				});
			}
		}
	}
}
