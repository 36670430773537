import { process } from '@/use/utm-source.js';

export default ({ app, store }) => {
	app.router.beforeEach(async (to, from, next) => {
		if (to.name === 'magic') {
			next();
		}

		const cookieName = store.state.user.cookie.names.utmSource;

		const cookie = store.getters['user/GET_COOKIE']({
			name: cookieName,
		});

		const processed = process({
			route: to,
			cookie,
		});

		if (processed !== null) {
			store.commit('user/SET_COOKIE', {
				name: cookieName,
				value: processed,
			});
		}
		next();
	});
};
