<template>
	<div
		:class="[
			'banner',
			`banner--${data.size}`,
			{ 'banner--category': isCategoryBanner },
			{ 'banner--caption-only': !hasMedia },
		]"
		@[eventName]="trackLink"
	>
		<Component
			:is="isLink ? 'NuxtLink' : 'div'"
			:to="data.linkUrl"
			class="banner-inner"
		>
			<figure :class="'banner-content'">
				<div v-if="hasMedia" class="banner-media has-objectfit">
					<video
						v-if="data.video.src !== null"
						class="banner-video"
						muted
						loop
						autoplay
						playsinline
						aria-describedby="bannerHeading"
						preload="metadata"
					>
						<source
							:src="
								$dataSrc({
									url: data.video.src,
									extension: 'webm',
								})
							"
							type="video/webm"
						/>
						<source
							:src="
								$dataSrc({
									url: data.video.src,
									extension: 'mp4',
								})
							"
							type="video/mp4"
						/>
						{{
							$__('Your browser does not support the video tag.')
						}}
					</video>
					<picture v-else-if="data.image.src !== null">
						<source
							v-if="device.isDesktop"
							:media="`(min-width: ${
								isCategoryBanner ? 992 : 768
							}px)`"
							:data-srcset="
								$dataSrc({
									url: data.image.src,
									size: 'desktop',
									isHiRes: true,
									extension: 'webp',
									hash: data.image.hash,
								})
							"
							type="image/webp"
						/>
						<source
							media="(min-width: 429px)"
							:data-srcset="
								$dataSrc({
									url: data.image.src,
									size: 'tablet',
									isHiRes: true,
									extension: 'webp',
									hash: data.image.hash,
								})
							"
							type="image/webp"
						/>
						<source
							media="(min-width: 1px)"
							:data-srcset="
								$dataSrc({
									url: data.image.src,
									size: 'mobile',
									isHiRes: true,
									extension: 'webp',
									hash: data.image.hash,
								})
							"
							type="image/webp"
						/>
						<source
							v-if="device.isDesktop"
							:media="`(min-width: ${
								isCategoryBanner ? 992 : 768
							}px)`"
							:data-srcset="
								$dataSrc({
									url: data.image.src,
									size: 'desktop',
									isHiRes: true,
									hash: data.image.hash,
								})
							"
							type="image/jpeg"
						/>
						<source
							media="(min-width: 429px)"
							:data-srcset="
								$dataSrc({
									url: data.image.src,
									size: 'tablet',
									isHiRes: true,
									hash: data.image.hash,
								})
							"
							type="image/jpeg"
						/>
						<source
							media="(min-width: 1px)"
							:data-srcset="
								$dataSrc({
									url: data.image.src,
									size: 'mobile',
									isHiRes: true,
									hash: data.image.hash,
								})
							"
							type="image/jpeg"
						/>
						<img
							class="banner-image is-objectfit"
							:srcset="
								$dataSrc({
									url: data.image.src,
									size: device.isMobile
										? 'mobile'
										: device.isTablet || isIpad
										? 'tablet'
										: 'desktop',
									extension: device.isWebp ? 'webp' : 'jpg',
									isHiRes:
										device.isMobile ||
										device.isTablet ||
										isIpad,
									hash: data.image.hash,
								})
							"
							:alt="
								data.caption.heading.text ||
								$__('Designer Clothing and Accessories Sale')
							"
							data-object-position="center center"
						/>
					</picture>
				</div>
				<figcaption
					:class="[
						'banner-caption',
						'banner-caption--x-' +
							(data.caption.position.x || 'center'),
						'banner-caption--y-' +
							(data.caption.position.y || 'bottom'),
						{ 'banner-caption--inverted': data.caption.isInverted },
						{ 'banner-caption--product-list': isProductList },
					]"
				>
					<h1
						id="bannerHeading"
						:class="[
							'banner-caption-heading',
							{
								'banner-caption-heading--hidden':
									data.caption.heading.isHidden,
							},
							{
								'banner-caption-heading--product-list':
									isProductList,
							},
						]"
						v-text="
							data.caption.heading.text ||
							$__('Designer Clothing and Accessories Sale')
						"
					/>
					<template v-if="hasDescription">
						<template v-if="isCategoryBanner">
							<SlideUpDown
								class="banner-caption-description-expand"
								:is-enabled="isDescriptionExpandable"
								:is-active="isDescriptionExpanded"
								:duration="descriptionExpandDuration"
								:style-when-hidden="descriptionHiddenStyle"
							>
								<div
									ref="BannerCaptionExpandableDescription"
									class="banner-caption-description"
									:class="{
										'banner-caption-description--product-list':
											isProductList,
									}"
									@click="toggleDescriptionExpand"
								>
									<div v-text="data.caption.description" />
									<transition
										name="banner-caption-description-expander-transition"
									>
										<button
											v-if="
												isDescriptionExpandable &&
												!isDescriptionExpanded
											"
											type="button"
											class="banner-caption-description-expander"
										>
											<span
												class="banner-caption-description-expander-text"
												>{{ $__('...More') }}</span
											>
										</button>
									</transition>
								</div>
							</SlideUpDown>
						</template>
						<div
							v-else
							class="banner-caption-description"
							v-text="data.caption.description"
						/>
					</template>
					<span
						v-if="isProductList"
						class="banner-caption-amount"
						v-text="countTitle"
					/>
					<span
						v-if="data.caption.cta.text"
						role="button"
						:class="[
							'banner-caption-cta',
							{
								'banner-caption-cta--custom':
									hasCustomCaptionCtaStyle,
							},
						]"
						:style="customCaptionCtaStyle"
						v-text="data.caption.cta.text"
					/>
				</figcaption>
			</figure>
		</Component>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		data() {
			return {
				isDescriptionExpandable: true,
				isDescriptionExpanded: false,
				descriptionExpandDuration: 500,
				descriptionHiddenStyle: {
					height: '25px', // dirty hack to prevent jumps, adjust manually
				},
			};
		},
		head() {
			return {
				link: [
					{
						rel: 'preload',
						as: 'image',
						href: this.preloadLink,
					},
				],
			};
		},
		computed: {
			isCategoryBanner() {
				return this.$store.state.ui.isFilterPage;
			},
			isLink() {
				return this.data.linkUrl !== null;
			},
			hasDescription() {
				return this.data.caption.description !== null;
			},
			hasMedia() {
				return (
					this.data.image.src !== null || this.data.video.src !== null
				);
			},
			eventName() {
				return this.isLink ? 'click' : null;
			},
			hasCustomCaptionCtaStyle() {
				return (
					this.data.caption.cta.textColor !== null ||
					this.data.caption.cta.backgroundColor !== null
				);
			},
			customCaptionCtaStyle() {
				if (!this.hasCustomCaptionCtaStyle) {
					return null;
				}

				return {
					color: this.data.caption.cta.textColor || '#ffffff',
					backgroundColor:
						this.data.caption.cta.backgroundColor || '#000000',
					borderColor:
						this.data.caption.cta.backgroundColor || '#000000',
				};
			},
			isProductList() {
				return (
					this.$store.state.page.content?.currentView ===
					'product_list'
				);
			},
			countTitle() {
				const count = this.$store.state.page.content?.productCount || 0;
				return (
					count +
					' ' +
					(count == 1 ? this.$__('Item') : this.$__('Items'))
				);
			},
			device() {
				return this.$store.state.ui.device;
			},
			isIpad() {
				if (process.browser) {
					return (
						navigator.userAgent.match(/Mac/) &&
						navigator.maxTouchPoints &&
						navigator.maxTouchPoints > 2
					);
				}
				return false;
			},
			preloadLink() {
				if (this.device.isMobile) {
					return this.$dataSrc({
						url: this.data.image.src,
						size: 'mobile',
						isHiRes: true,
						extension: this.device.isWebp ? 'webp' : 'jpg',
						hash: this.data.image.hash,
					});
				}

				if (this.device.isTablet || this.isIpad) {
					return this.$dataSrc({
						url: this.data.image.src,
						size: 'tablet',
						extension: this.device.isWebp ? 'webp' : 'jpg',
						hash: this.data.image.hash,
					});
				}

				return this.$dataSrc({
					url: this.data.image.src,
					size: 'desktop',
					extension: this.device.isWebp ? 'webp' : 'jpg',
					hash: this.data.image.hash,
				});
			},
		},
		mounted() {
			if (this.hasDescription) {
				this.evaluateExpansion();
				this.$bus.$on('resize', this.onResize);
			}
		},
		beforeDestroy() {
			this.$bus.$off('resize', this.onResize);
		},
		methods: {
			toggleDescriptionExpand() {
				if (this.isDescriptionExpandable) {
					this.isDescriptionExpanded = !this.isDescriptionExpanded;
					this.trackMore();
				}
			},
			needsExpansion() {
				if (
					!process.browser ||
					!this.isCategoryBanner ||
					!this.data.caption.description
				) {
					return false;
				}

				const linesAllow = 1;
				const descriptionStyle = getComputedStyle(
					this.$refs.BannerCaptionExpandableDescription,
					null
				);
				const heightNeeded =
					parseFloat(descriptionStyle.getPropertyValue('height')) -
					parseFloat(
						descriptionStyle.getPropertyValue('padding-top')
					);
				const heightAllowed =
					parseFloat(
						descriptionStyle.getPropertyValue('line-height')
					) * linesAllow;

				return heightNeeded > heightAllowed;
			},
			evaluateExpansion() {
				if (this.$mq.is('md-down') && this.needsExpansion()) {
					this.isDescriptionExpanded = false;
					this.isDescriptionExpandable = true;
				} else {
					this.isDescriptionExpanded = true;
					this.isDescriptionExpandable = false;
				}
			},
			trackLink() {
				this.$track('banner-link', this.data);
			},
			trackMore() {
				this.$track('banner-more', {
					isDescriptionExpanded: this.isDescriptionExpanded,
				});
			},
			onResize(params) {
				if (params.oldMq === params.mq) {
					return;
				}

				this.evaluateExpansion();
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/banner.scss';
</style>
