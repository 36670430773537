<template>
	<div
		ref="Search"
		v-click-outside="dismiss"
		:style="style"
		class="livesearch"
	>
		<div class="livesearch-container">
			<form
				class="livesearch-form"
				role="search"
				:method="form.method"
				:action="formQueryPath"
				@submit.prevent="onSubmit($event)"
			>
				<button
					type="button"
					class="livesearch-form-button livesearch-form-button--back"
					:title="$__('Back')"
					:aria-label="$__('Back')"
					@click="dismiss()"
				></button>
				<div class="livesearch-form-wrapper">
					<input
						:id="inputId"
						:value="form.query"
						class="livesearch-form-input"
						autocomplete="off"
						type="search"
						@keydown="onKeydown"
						@input="form.query = $event.target.value"
					/>
					<label
						v-show="isInputLabelVisible"
						:for="inputId"
						class="livesearch-form-label"
						v-text="$__('Search')"
					></label>
				</div>
				<button
					v-if="form.query !== ''"
					type="button"
					class="livesearch-form-button livesearch-form-button--clear"
					@click="clearQuery"
				>
					<span class="btn-inner">
						<i class="icon-cross"></i>
					</span>
				</button>
				<button
					type="submit"
					:class="[
						'livesearch-form-button',
						'livesearch-form-button--start',
						{
							'is-loading':
								isLoading && formQuery.length > minQueryLength,
						},
					]"
					:title="$__('Search')"
					:aria-label="$__('Search')"
				>
					<span class="btn-inner">
						<i
							class="livesearch-button--start-icon icon-search"
						></i>
					</span>
					<Spinner class="btn-spinner" />
				</button>
			</form>
			<div id="listWrapper" class="livesearch-list-wrapper">
				<div ref="SearchList" class="livesearch-list-container">
					<template v-if="hasSuggestions">
						<div class="livesearch-list-container--left">
							<div
								v-if="suggestions.brands.length > 0"
								class="livesearch-list-category livesearch-list-category--brands"
							>
								<div class="livesearch-list-category-header">
									<h3>
										{{ $__('Designers') }}
									</h3>

									<span
										v-if="
											device.isMobile &&
											!isDesignersExpanded
										"
										@click="isDesignersExpanded = true"
									>
										View More
									</span>
								</div>
								<div
									v-for="(brand, index) in suggestions.brands"
									:key="`search-list-item-brand--${index}${brand.title}`"
									ref="SearchListItem"
									:data-index="index"
									:class="[
										'livesearch-list-item',
										{
											'is-selected':
												selectedListItemIndex === index,
										},
									]"
									@mouseenter="onMouseEnterListItem($event)"
									@mouseleave="onMouseLeaveListItem($event)"
								>
									<NuxtLink
										:to="brand.url"
										event=""
										class="livesearch-list-item-link"
										@click.native="navigate(brand)"
										v-text="brand.title"
									>
									</NuxtLink>
								</div>
								<div
									v-show="
										!device.isMobile ||
										(device.isMobile && isDesignersExpanded)
									"
									class="livesearch-view-all livesearch-view-all--designers"
								>
									<nuxt-link
										class="livesearch-view-all-btn"
										to="/designers"
									>
										<span class="btn-inner">
											{{ $__('View All Designers') }}
										</span>
									</nuxt-link>
								</div>
							</div>
							<div
								v-if="suggestions.categories.length > 0"
								class="livesearch-list-category livesearch-list-category--categories"
							>
								<div class="livesearch-list-category-header">
									<h3>
										{{ $__('Categories') }}
									</h3>

									<span
										v-if="
											device.isMobile &&
											!isCategoriesExpanded
										"
										@click="isCategoriesExpanded = true"
									>
										View More
									</span>
								</div>

								<div
									v-for="(
										category, index
									) in suggestions.categories"
									:key="`search-list-item-category--${index}${category.title}`"
									ref="SearchListItem"
									:data-index="
										suggestions.brands.length + index
									"
									:class="[
										'livesearch-list-item',
										{
											'is-selected':
												selectedListItemIndex ===
												suggestions.brands.length +
													index,
										},
									]"
									@mouseenter="onMouseEnterListItem($event)"
									@mouseleave="onMouseLeaveListItem($event)"
								>
									<NuxtLink
										:to="category.url"
										event=""
										class="livesearch-list-item-link"
										@click.native="navigate(category)"
										v-text="category.title"
									>
									</NuxtLink>
								</div>
							</div>
						</div>
						<div class="livesearch-list-container--right">
							<div
								v-if="suggestions.products.length > 0"
								class="livesearch-list-category livesearch-list-category--products"
							>
								<div class="livesearch-list-category-header">
									<h3>
										{{ $__('Products') }}
									</h3>
								</div>
								<div
									class="livesearch-list-category--products-container"
								>
									<NuxtLink
										v-for="product in suggestions.products"
										:key="`search-list-item-product--${product.imageUrls[0]}`"
										:class="[
											'livesearch-list-item',
											'livesearch-list-item--product',
										]"
										:to="`${product.url}?from-search=true`"
										event=""
										@click.native="navigate(product)"
									>
										<div style="position: relative">
											<LazyImg
												width="100"
												height="auto"
												:alt="product.title"
												:data-srcset="
													$dataSrc({
														url: product
															.imageUrls[0],
														size: 'product-card',
													})
												"
												:data-src="
													$dataSrc({
														url: product
															.imageUrls[0],
														size: 'product-card',
													})
												"
												data-object-position="center center"
											/>
										</div>
										<div
											class="livesearch-list-item--product-basic"
										>
											<div
												class="livesearch-list-item--product-brand"
											>
												{{ product.brand.title }}
											</div>
											<div
												class="livesearch-list-item--product-title"
											>
												{{ product.title }}
											</div>
											<div
												class="product-price livesearch-list-item--product-price"
											>
												<span
													dir="auto"
													:class="[
														'livesearch-list-item--product-price-regular',
														{
															'is-reduced':
																product.priceReduced &&
																product.priceReduced !==
																	product.priceRegular,
														},
													]"
													v-text="
														product.priceRegular
													"
												/>
												<span
													v-if="
														product.priceReduced &&
														product.priceReduced !==
															product.priceRegular
													"
													dir="auto"
													class="livesearch-list-item--product-price-reduced"
													v-text="
														product.priceReduced
													"
												/>
											</div>
										</div>
									</NuxtLink>

									<div class="livesearch-view-all">
										<button
											type="button"
											class="livesearch-view-all-btn"
											@click="onSubmit($event)"
										>
											<span class="btn-inner">
												{{ $__('View All Products') }}
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</template>
					<div
						v-if="hasRecent && formQuery.length < 1"
						class="livesearch-list-category livesearch-list-category--recent"
					>
						<div class="livesearch-list-category-header">
							<h3>
								{{ $__('Recent search') }}
							</h3>
						</div>
						<h3></h3>
						<div
							v-for="(item, index) in recentItems"
							:key="`search-list-item-recent--${index}`"
							ref="SearchListItem"
							:data-index="index"
							:class="[
								'livesearch-list-item',
								{
									'is-selected':
										selectedListItemIndex ===
										suggestions.brands.length + index,
								},
							]"
							@mouseenter="onMouseEnterListItem($event)"
							@mouseleave="onMouseLeaveListItem($event)"
						>
							<span
								class="livesearch-list-item-link"
								@click="navigate(item)"
								v-text="item.title"
							>
							</span>
							<button
								type="button"
								class="livesearch-list-item-remove"
								@click="removeFromRecentItems(item)"
							>
								<i class="icon-cross"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import animatedScrollTo from 'animated-scroll-to';
	import _debounce from 'lodash.debounce';

	import dismissByEsc from '@/mixins/dismiss-by-esc.js';
	import useDropdown from '@/use/dropdown.js';

	export default {
		mixins: [dismissByEsc],
		data() {
			return {
				isLoading: false,
				canClickOutside: false,
				form: {
					method: 'get',
					action: '/search',
					query: '',
				},
				feed: null,
				feedRequestUrl: `common:search`,
				recentItems: [],
				minQueryLength: 1,
				isAnimateScrollingToListItem: false,
				selectedListItemIndex: null,
				isDesignersExpanded: false,
				isCategoriesExpanded: false,
				debounce: 1000,
				isEmpty: false,
				limits: {
					brands: 6,
					categories: 6,
					products: 8,
				},
			};
		},
		computed: {
			isActive() {
				return this.$store.state.ui.isSearchOn;
			},
			style() {
				// overcoming iOS input.focus() bug
				return {
					zIndex: this.isActive ? null : -9999,
					transform: this.isActive ? null : 'translateX(-9999px)',
					height: this.$platform?.is?.iphone
						? 'calc(100vh - 170px)'
						: '',
				};
			},
			userLanguage() {
				return this.$store.state.user.localization.language;
			},
			inputId() {
				return this.$store.state.ui.searchInputId;
			},
			selectedItem() {
				if (this.selectedListItemIndex !== null) {
					return this.listItems[this.selectedListItemIndex];
				} else {
					return null;
				}
			},
			formQuery() {
				return this.form.query.split('/').join('');
			},
			formQueryPath() {
				return `${this.form.action}/${encodeURIComponent(
					this.formQuery.toLowerCase()
				)}`;
			},
			isTabletOrDesktop() {
				return (
					this.device.isDesktop || this.device.isTablet || this.isIpad
				);
			},
			suggestions() {
				let output = {
					brands:
						this.isDesignersExpanded || this.isTabletOrDesktop
							? this.feed?.brands ?? []
							: this.feed?.brands.slice(0, 1) ?? [],
					categories:
						this.isCategoriesExpanded || this.isTabletOrDesktop
							? this.feed?.categories ?? []
							: this.feed?.categories.slice(0, 1) ?? [],
					products: this.feed?.products ?? [],
				};

				return output;
			},
			hasSuggestions() {
				return (
					this.suggestions.brands.length > 0 ||
					this.suggestions.categories.length > 0 ||
					this.suggestions.products.length > 0
				);
			},
			hasRecent() {
				return this.recentItems.length > 0;
			},
			listItems() {
				if (this.hasSuggestions) {
					return [
						...this.suggestions.brands,
						...this.suggestions.categories,
						...this.suggestions.products,
					];
				} else if (this.hasRecent && this.formQuery.length < 1) {
					return this.recentItems;
				} else {
					return [];
				}
			},
			isInputLabelVisible() {
				return this.formQuery.length < 1;
			},
			isBodyfixOn() {
				return this.$store.state.ui.isBodyfixOn;
			},
			feedLocalStorageName() {
				return this.$store.state.user.localStorage.names.searchFeed;
			},
			recentLocalStorageName() {
				return this.$store.state.user.localStorage.names.recentSearch;
			},
			device() {
				return this.$store.state.ui.device;
			},
			queryId() {
				return this.feed?.queryID ?? null;
			},
			isIpad() {
				if (process.browser) {
					return (
						navigator.userAgent.match(/Mac/) &&
						navigator.maxTouchPoints &&
						navigator.maxTouchPoints > 2
					);
				}
				return false;
			},
		},
		watch: {
			isActive(val) {
				this.$mq.setResizeHandle(val);
				this.setEscDismission(val);
				this.setCanClickOutside(val);

				const wrapper = document.getElementById('listWrapper');
				if (val) {
					this.getRecentItems();
					if (this.$mq.is('md-down')) {
						this.$store.dispatch('ui/toggleBodyfix', true);
					}

					wrapper.addEventListener('touchstart', () => {
						document.getElementById(this.inputId).blur();
					});
				} else {
					this.$store.dispatch('ui/toggleBodyfix', false);
					this.blurInput(this.inputId);
					this.selectedListItemIndex = null;

					wrapper.removeEventListener('touchstart', () => {
						document.getElementById(this.inputId).blur();
					});
				}
			},
			'form.query'() {
				if (
					this.form.query &&
					this.form.query.length > this.minQueryLength
				) {
					this.fetchLiveFeed();
				} else {
					this.feed = null;
					this.isCategoriesExpanded = false;
					this.isDesignersExpanded = false;
				}
			},

			$route() {
				this.form.query = '';
				this.isDesignersExpanded = false;
				this.isCategoriesExpanded = false;
			},
		},
		mounted() {
			this.$bus.$on('resize', this.onResize);
		},
		beforeDestroy() {
			this.$bus.$off('resize', this.onResize);
		},
		methods: {
			close() {
				this.$store.dispatch('ui/toggleSearch', false);
			},
			dismiss() {
				if (this.canClickOutside) this.close();
			},
			onSubmit(event) {
				if (!this.selectedItem && this.formQuery.length < 1) return;

				const item = this.selectedItem || {
					title: this.formQuery,
					url: this.formQueryPath,
				};
				this.navigate(item);
				this.$router.push(item.url);
			},
			onMouseEnterListItem(event) {
				if (!this.isAnimateScrollingToListItem) {
					this.selectListItem(event.target);
				}
			},
			onMouseLeaveListItem(event) {
				if (!this.isAnimateScrollingToListItem) {
					this.unselectListItem();
				}
			},
			clearQuery() {
				this.form.query = '';
				this.feed = null;
			},
			fetchLiveFeed: _debounce(async function () {
				if (this.form.query.length < 1) {
					return;
				}
				this.isLoading = true;

				try {
					const response = await this.$axios.get(
						`search:feed?searchQuery=${this.form.query}&products=${this.limits.products}&categories=${this.limits.categories}&brands=${this.limits.brands}`
					);
					this.isLoading = false;
					this.feed = response.data.search.feed;
					this.$lazyload.generic.update();
				} catch (e) {
					console.error(e);
					this.isLoading = false;
				}
			}, 1000),
			onKeydown(event) {
				if (
					['ArrowUp', 'ArrowDown'].includes(event.key) &&
					this.listItems.length > 0
				) {
					const index = useDropdown.getIndexByKey(
						event.key,
						this.listItems.length,
						this.selectedListItemIndex
					);

					const item = this.$refs.SearchListItem[index];

					const elementToScroll = this.$mq.is('md-down')
						? this.$refs.Search
						: this.$refs.SearchList;
					const verticalOffset = Math.round(
						Math.min(
							window.innerHeight,
							elementToScroll.offsetHeight
						) / -2
					);

					this.selectListItem(item);
					this.isAnimateScrollingToListItem = true;

					setTimeout(() => {
						animatedScrollTo(item, {
							elementToScroll,
							verticalOffset,
						}).then((hasScrolledToPosition) => {
							if (hasScrolledToPosition)
								this.isAnimateScrollingToListItem = false;
						});
					}, 0);

					event.preventDefault();
				} else if (event.key !== 'Enter') {
					this.selectedListItemIndex = null;
				}
			},
			setCanClickOutside(val) {
				setTimeout(() => {
					this.canClickOutside = val;
				}, 0);
			},
			selectListItem(element) {
				this.selectedListItemIndex = parseInt(element.dataset.index);
			},
			unselectListItem() {
				this.selectedListItemIndex = null;
			},
			getRecentItems() {
				let recentItemsInLocalStorage = this.$store.getters[
					'user/GET_LOCAL_STORAGE'
				]({
					name: this.recentLocalStorageName,
				});
				let recentItemsJson =
					typeof recentItemsInLocalStorage !== 'undefined'
						? decodeURIComponent(recentItemsInLocalStorage)
						: [];
				let recentItems = [];

				try {
					recentItems = JSON.parse(recentItemsJson);
				} catch (error) {
					recentItems = [];
				}

				this.recentItems = recentItems;
			},
			getRecentItemsBy(item) {
				return this.recentItems.filter((i) => {
					return i.title !== item.title || i.url !== item.url;
				});
			},
			addToRecentItems(item) {
				let recentItemsModified = this.getRecentItemsBy(item);

				recentItemsModified.unshift(item);

				this.recentItems = recentItemsModified;
				this.saveRecentItems(recentItemsModified);
			},
			removeFromRecentItems(item) {
				let recentItemsModified = this.getRecentItemsBy(item);

				this.canClickOutside = false;
				this.$nextTick(() => {
					this.recentItems = recentItemsModified;
					this.saveRecentItems(recentItemsModified);
					this.setCanClickOutside(true);
				});
			},
			clearRecentItems() {
				this.saveRecentItems([]);
			},
			saveRecentItems(items) {
				this.$store.commit('user/SET_LOCAL_STORAGE', {
					name: this.recentLocalStorageName,
					data: encodeURIComponent(JSON.stringify(items)),
				});
			},
			navigate(item) {
				if (this.queryId) {
					localStorage.setItem('queryId', this.queryId);
				}

				if (this.$route.path !== item.url) {
					this.$track('search', { item });
					this.$router.push(`${item.url}?from-search=true`);
				} else {
					if (this.isBodyfixOn) {
						this.$bodyfix.setAnimatedScrollToTop();
					} else {
						animatedScrollTo(0);
					}

					this.close();
				}
				this.addToRecentItems(item);
			},
			blurInput(inputId) {
				let input = document.getElementById(inputId);

				setTimeout(() => {
					input.focus();
					input.blur();
				}, 20);
			},
			onResize(data) {
				if (!this.isActive) return;

				let mq = data.mq;
				let oldMq = data.oldMq;
				let sizes = {
					mobile: Object.keys(this.$mq.data).slice(0, 4),
					desktop: Object.keys(this.$mq.data).slice(
						4,
						Object.keys(this.$mq.data).length
					),
				};

				if (
					(sizes.mobile.includes(oldMq) &&
						sizes.desktop.includes(mq)) ||
					(sizes.mobile.includes(mq) && sizes.desktop.includes(oldMq))
				) {
					this.close();
				}
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/livesearch.scss';
</style>
