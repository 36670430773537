<template>
	<transition name="top-button-icon-counter-transition" mode="out-in">
		<span
			v-if="count > 0"
			:key="count"
			class="top-button-icon-counter"
			v-text="count"
		/>
	</transition>
</template>

<script>
	export default {
		props: { count: { type: Number, default: 0 } },
	};
</script>
