<template>
	<div class="nav--mobile-menu-top">
		<nav class="nav--mobile-menu-top-wrapper">
			<ul class="nav--mobile-menu-top-list">
				<li class="nav--mobile-menu-top-item">
					<button
						:class="[
							'nav--mobile-menu-top--back',
							{
								active:
									isMenuOn ||
									isNavMobileOn ||
									previousRouteExists,
							},
						]"
						type="button"
						:title="$__('Back to main menu')"
						:aria-label="$__('Back to main menu')"
						@click="goBack()"
					/>
				</li>
				<li class="nav--mobile-menu-top-item">
					<NuxtLink
						to="/"
						title="BAMBINIFASHION.COM"
						aria-label="BAMBINIFASHION.COM"
						class="nav--mobile-menu-top--logo"
						@click.native="scrollTopIfSameRouteName('index')"
					>
						<i class="icon-logo"></i>
					</NuxtLink>
				</li>
				<li class="nav--mobile-menu-top-item">
					<NuxtLink
						:title="$__('Wishlist')"
						:aria-label="$__('Wishlist')"
						to="/wishlist"
						class="nav--mobile-menu-top--wishlist"
						@click.native="scrollTopIfSameRouteName('wishlist')"
					>
						<i class="icon-heart--empty">
							<TopCounter :count="counters.wishlist"></TopCounter>
						</i>
					</NuxtLink>
				</li>
			</ul>
		</nav>
	</div>
</template>
<script>
	import animatedScrollTo from 'animated-scroll-to';
	import TopCounter from '@/components/TopCounter.vue';
	import { mapper } from '@/store/helpers/create-mapper';
	export default {
		components: {
			TopCounter,
		},
		computed: {
			...mapper('mobileNavigation', ['isMenuOn', 'level']),
			...mapper('user', ['counters']),
			...mapper('ui', ['isNavMobileOn', 'isNavDropmenuOn']),

			previousRouteExists() {
				if (!process.browser) {
					return false;
				}

				return this.$accessor.ui.previousRoute !== null;
			},
		},
		methods: {
			scrollTopIfSameRouteName(routeName) {
				if (this.$route.name === routeName) animatedScrollTo(0);
			},

			goBack() {
				if (this.isMenuOn) {
					if (this.level > 0) {
						this.$accessor.mobileNavigation.POP_MENU_FILTER_HISTORY();
					} else {
						this.$accessor.mobileNavigation.TOGGLE_NAV_MOBILE();
						this.$accessor.mobileNavigation.RESET_MENU_HISTORY();
					}
					return;
				}
				if (this.previousRouteExists) {
					this.$router.back();
				}
			},
		},
	};
</script>
<style lang="scss">
	.nav--mobile-menu-top {
		& {
			@include media-breakpoint-up(md) {
				display: none;
			}
			top: 0;
			margin-bottom: auto;
			z-index: 200;
			position: sticky;
		}
		&-wrapper {
			-webkit-tap-highlight-color: rgba(15, 119, 176, 0.1);
			background-color: $body-color;
			height: 50px;
		}
		&-list {
			// align-items: center;
			box-sizing: border-box;
			// display: flex;
			// justify-content: space-between;
			display: grid;
			grid-template-columns: 0.5fr 2.5fr 0.5fr;
			align-items: center;
			max-width: 100vw;
			overflow: hidden;
			padding: 10px;
			color: white;
			height: 100%;
		}
		&-item {
			height: 100%;
			margin: 0 auto;
		}
		&--logo {
			color: $white;
			font-size: 0.9rem;
			height: 30px;
		}
		&--back {
			@extend %is-hidden-lg-up;
			font-family: $icomoon-font-family;
			width: 100%;
			color: $white;
			opacity: 0;
			height: 0;
			overflow: hidden;
			transition: opacity $nav--mobile-transition-duration
				$overlay-backdrop-transition-duration $transition-style;
			&.active {
				opacity: 1;
				height: 30px;
			}
			&:before {
				content: $icon-arrow--left;
			}
		}
		&--wishlist {
			color: $white;
			width: 100%;
			height: 30px;
			position: relative;
			span {
				background-color: #555555;
				top: -0.2rem;
			}
		}
	}
</style>
