import { init } from '@sentry/vue';
import { BrowserTracing } from '@sentry/browser';
import { Dedupe as DedupeIntegration } from '@sentry/integrations';
import Vue from 'vue';

init({
	Vue,
	dsn: 'https://4e7dd3f61899430aa9ce54a62dca3317@o518493.ingest.sentry.io/5690750',
	environment: process.env.BF_ENV,
	ignoreErrors: [
		// 'Network request failed',
		// 'Failed to fetch',
		// 'NetworkError',
		// 'Network Error',
		'Request failed with status code 401',
	],

	// Alternatively, use `process.env.npm_package_version` for a dynamic release version
	// if your build tool supports it.
	release: process.env.npm_package_version,
	integrations: [new BrowserTracing(), new DedupeIntegration()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.25,
});
