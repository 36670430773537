<template>
	<div ref="Search" v-click-outside="dismiss" :style="style" class="search">
		<div class="search-container">
			<form
				class="search-form"
				role="search"
				:method="form.method"
				:action="formQueryPath"
				@submit.prevent="onSubmit($event)"
			>
				<button
					type="button"
					class="search-form-button search-form-button--back"
					:title="$__('Back')"
					:aria-label="$__('Back')"
					@click="dismiss()"
				/>
				<div class="search-form-wrapper">
					<input
						:id="inputId"
						v-model="form.query"
						class="search-form-input"
						autocomplete="off"
						type="search"
						@keydown="onKeydown"
						@input="form.query = $event.target.value"
					/>
					<label
						v-show="isInputLabelVisible"
						:for="inputId"
						class="search-form-label"
						v-text="$__('Search')"
					/>
				</div>
				<button
					type="submit"
					:class="[
						'search-form-button',
						'search-form-button--start',
						{
							'is-loading':
								isLoading && formQuery.length > minQueryLength,
						},
					]"
					:title="$__('Search')"
					:aria-label="$__('Search')"
				>
					<span class="btn-inner">
						<i class="search-button--start-icon icon-search" />
					</span>
					<Spinner class="btn-spinner" />
				</button>
			</form>
			<div ref="SearchList" class="search-list">
				<template v-if="hasSuggestions">
					<div
						v-if="suggestions.brands.length > 0"
						class="search-list-category search-list-category--brands"
					>
						<h3 class="search-list-category-heading">
							{{ $__('Brands') }}
						</h3>
						<div
							v-for="(brand, index) in suggestions.brands"
							:key="`search-list-item-brand--${index}`"
							ref="SearchListItem"
							:data-index="index"
							:class="[
								'search-list-item',
								{
									'is-selected':
										selectedListItemIndex === index,
								},
							]"
							@mouseenter="onMouseEnterListItem($event)"
							@mouseleave="onMouseLeaveListItem($event)"
						>
							<NuxtLink
								:to="brand.url"
								class="search-list-item-link"
								@click.native="navigate(brand)"
								v-text="brand.title"
							/>
						</div>
					</div>
					<div
						v-if="suggestions.categories.length > 0"
						class="search-list-category search-list-category--categories"
					>
						<h3 class="search-list-category-heading">
							{{ $__('Categories') }}
						</h3>
						<div
							v-for="(category, index) in suggestions.categories"
							:key="`search-list-item-category--${index}`"
							ref="SearchListItem"
							:data-index="suggestions.brands.length + index"
							:class="[
								'search-list-item',
								{
									'is-selected':
										selectedListItemIndex ===
										suggestions.brands.length + index,
								},
							]"
							@mouseenter="onMouseEnterListItem($event)"
							@mouseleave="onMouseLeaveListItem($event)"
						>
							<NuxtLink
								:to="category.url"
								class="search-list-item-link"
								@click.native="navigate(category)"
								v-text="category.title"
							/>
						</div>
					</div>
				</template>
				<div
					v-if="hasRecent && formQuery.length < 1"
					class="search-list-category search-list-category--recent"
				>
					<h3 class="search-list-category-heading">
						{{ $__('Recent search') }}
					</h3>
					<div
						v-for="(item, index) in recentItems"
						:key="`search-list-item-recent--${index}`"
						ref="SearchListItem"
						:data-index="index"
						:class="[
							'search-list-item',
							{
								'is-selected':
									selectedListItemIndex ===
									suggestions.brands.length + index,
							},
						]"
						@mouseenter="onMouseEnterListItem($event)"
						@mouseleave="onMouseLeaveListItem($event)"
					>
						<NuxtLink
							:to="item.url"
							class="search-list-item-link"
							@click.native="navigate(item)"
							v-text="item.title"
						/>
						<button
							type="button"
							class="search-list-item-remove"
							@click="removeFromRecentItems(item)"
						>
							<i class="icon-cross" />
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import animatedScrollTo from 'animated-scroll-to';
	import dismissByEsc from '@/mixins/dismiss-by-esc.js';
	import useDropdown from '@/use/dropdown.js';

	export default {
		mixins: [dismissByEsc],
		data() {
			return {
				isLoading: false,
				canClickOutside: false,
				form: {
					method: 'get',
					action: '/search',
					query: '',
				},
				feed: null,
				feedRequestUrl: 'common:search',
				recentItems: [],
				minQueryLength: 1,
				isAnimateScrollingToListItem: false,
				selectedListItemIndex: null,
			};
		},
		computed: {
			isActive() {
				return this.$store.state.ui.isSearchOn;
			},
			style() {
				// overcoming iOS input.focus() bug
				return {
					zIndex: this.isActive ? null : -9999,
					transform: this.isActive ? null : 'translateX(-9999px)',
				};
			},
			userLanguage() {
				return this.$store.state.user.localization.language;
			},
			inputId() {
				return this.$store.state.ui.searchInputId;
			},
			selectedItem() {
				if (this.selectedListItemIndex !== null) {
					return this.listItems[this.selectedListItemIndex];
				} else {
					return null;
				}
			},
			formQuery() {
				return this.form.query.split('/').join('');
			},
			formQueryPath() {
				return `${this.form.action}/${encodeURIComponent(
					this.formQuery.toLowerCase()
				)}`;
			},
			suggestions() {
				const output = {
					brands: [],
					categories: [],
				};

				if (
					this.feed !== null &&
					this.formQuery.length > this.minQueryLength
				) {
					output.brands = this.feed.brands.filter((brand) => {
						return brand.title
							.toLowerCase()
							.includes(this.formQuery.toLowerCase());
					});
					output.categories = this.feed.categories.filter(
						(category) => {
							return category.title
								.toLowerCase()
								.includes(this.formQuery.toLowerCase());
						}
					);
				}

				return output;
			},
			hasSuggestions() {
				return (
					this.suggestions.brands.length > 0 ||
					this.suggestions.categories.length > 0
				);
			},
			hasRecent() {
				return this.recentItems.length > 0;
			},
			listItems() {
				if (this.hasSuggestions) {
					return [
						...this.suggestions.brands,
						...this.suggestions.categories,
					];
				} else if (this.hasRecent && this.formQuery.length < 1) {
					return this.recentItems;
				} else {
					return [];
				}
			},
			isInputLabelVisible() {
				return this.formQuery.length < 1;
			},
			isBodyfixOn() {
				return this.$store.state.ui.isBodyfixOn;
			},
			feedLocalStorageName() {
				return this.$store.state.user.localStorage.names.searchFeed;
			},
			recentLocalStorageName() {
				return this.$store.state.user.localStorage.names.recentSearch;
			},
		},
		watch: {
			isActive(val) {
				this.$mq.setResizeHandle(val);
				this.setEscDismission(val);
				this.setCanClickOutside(val);
				if (val) {
					this.getFeed();
					this.getRecentItems();
					if (this.$mq.is('md-down')) {
						this.$store.dispatch('ui/toggleBodyfix', true);
					}
				} else {
					this.$store.dispatch('ui/toggleBodyfix', false);
					this.blurInput(this.inputId);
					this.selectedListItemIndex = null;
				}
			},
			$route() {
				this.form.query = '';
			},
		},
		mounted() {
			this.$bus.$on('resize', this.onResize);
		},
		beforeDestroy() {
			this.$bus.$off('resize', this.onResize);
		},
		methods: {
			close() {
				this.$store.dispatch('ui/toggleSearch', false);
			},
			dismiss() {
				if (this.canClickOutside) {
					this.close();
				}
			},
			onSubmit(event) {
				if (!this.selectedItem && this.formQuery.length < 1) {
					return;
				}

				const item = this.selectedItem || {
					title: this.formQuery,
					url: this.formQueryPath,
				};

				this.navigate(item);
				this.$router.push(item.url);
			},
			onMouseEnterListItem(event) {
				if (!this.isAnimateScrollingToListItem) {
					this.selectListItem(event.target);
				}
			},
			onMouseLeaveListItem(event) {
				if (!this.isAnimateScrollingToListItem) {
					this.unselectListItem();
				}
			},
			onKeydown(event) {
				if (
					['ArrowUp', 'ArrowDown'].includes(event.key) &&
					this.listItems.length > 0
				) {
					const index = useDropdown.getIndexByKey(
						event.key,
						this.listItems.length,
						this.selectedListItemIndex
					);

					const item = this.$refs.SearchListItem[index];

					const elementToScroll = this.$mq.is('md-down')
						? this.$refs.Search
						: this.$refs.SearchList;
					const verticalOffset = Math.round(
						Math.min(
							window.innerHeight,
							elementToScroll.offsetHeight
						) / -2
					);

					this.selectListItem(item);
					this.isAnimateScrollingToListItem = true;

					setTimeout(() => {
						animatedScrollTo(item, {
							elementToScroll,
							verticalOffset,
						}).then((hasScrolledToPosition) => {
							if (hasScrolledToPosition) {
								this.isAnimateScrollingToListItem = false;
							}
						});
					}, 0);

					event.preventDefault();
				} else if (event.key !== 'Enter') {
					this.selectedListItemIndex = null;
				}
			},
			async getFeed() {
				if (this.feed !== null) {
					return;
				}

				const feedInLocalStorage = this.$store.getters[
					'user/GET_LOCAL_STORAGE'
				]({
					name: this.feedLocalStorageName,
				});

				if (typeof feedInLocalStorage !== 'undefined') {
					let feed;
					const feedJson = decodeURIComponent(feedInLocalStorage);
					const now = Date.now();

					try {
						feed = JSON.parse(feedJson);
					} catch (error) {
						feed = false;
					}

					if (
						!feed ||
						feed.expires <= now ||
						feed.language !== this.userLanguage
					) {
						this.feed = await this.fetchFeed();

						if (feed.language !== this.userLanguage) {
							this.clearRecentItems();
						}
					} else {
						this.feed = feed;
					}
				} else {
					this.feed = await this.fetchFeed();
				}
			},
			async fetchFeed() {
				let feed = null;

				this.isLoading = true;

				try {
					const response = await this.$axios[`$${this.form.method}`](
						this.feedRequestUrl
					);

					feed = response.common.search.feed;

					this.saveFeedToLocal(feed);
					this.isLoading = false;
				} catch (error) {
					console.log(error);
				}

				return feed;
			},
			setCanClickOutside(val) {
				setTimeout(() => {
					this.canClickOutside = val;
				}, 0);
			},
			getFeedToSaveToLocal(feed) {
				const feedWithExpiry = feed;

				feedWithExpiry.expires = Date.now() + 24 * 60 * 60 * 1000;
				feedWithExpiry.language = this.userLanguage;

				return encodeURIComponent(JSON.stringify(feedWithExpiry));
			},
			saveFeedToLocal(feed) {
				this.$store.commit('user/SET_LOCAL_STORAGE', {
					name: this.feedLocalStorageName,
					data: this.getFeedToSaveToLocal(feed),
				});
			},
			selectListItem(element) {
				this.selectedListItemIndex = parseInt(element.dataset.index);
			},
			unselectListItem() {
				this.selectedListItemIndex = null;
			},
			getRecentItems() {
				const recentItemsInLocalStorage = this.$store.getters[
					'user/GET_LOCAL_STORAGE'
				]({
					name: this.recentLocalStorageName,
				});
				const recentItemsJson =
					typeof recentItemsInLocalStorage !== 'undefined'
						? decodeURIComponent(recentItemsInLocalStorage)
						: [];
				let recentItems = [];

				try {
					recentItems = JSON.parse(recentItemsJson);
				} catch (error) {
					recentItems = [];
				}

				this.recentItems = recentItems;
			},
			getRecentItemsBy(item) {
				return this.recentItems.filter((i) => {
					return i.title !== item.title || i.url !== item.url;
				});
			},
			addToRecentItems(item) {
				const recentItemsModified = this.getRecentItemsBy(item);

				recentItemsModified.unshift(item);

				this.recentItems = recentItemsModified;
				this.saveRecentItems(recentItemsModified);
			},
			removeFromRecentItems(item) {
				const recentItemsModified = this.getRecentItemsBy(item);

				this.canClickOutside = false;
				this.$nextTick(() => {
					this.recentItems = recentItemsModified;
					this.saveRecentItems(recentItemsModified);
					this.setCanClickOutside(true);
				});
			},
			clearRecentItems() {
				this.saveRecentItems([]);
			},
			saveRecentItems(items) {
				this.$store.commit('user/SET_LOCAL_STORAGE', {
					name: this.recentLocalStorageName,
					data: encodeURIComponent(JSON.stringify(items)),
				});
			},
			navigate(item) {
				if (this.$route.path !== item.url) {
					this.$track('search', { item });
				} else {
					if (this.isBodyfixOn) {
						this.$bodyfix.setAnimatedScrollToTop();
					} else {
						animatedScrollTo(0);
					}

					this.close();
				}

				this.addToRecentItems(item);
			},
			blurInput(inputId) {
				const input = document.getElementById(inputId);

				setTimeout(() => {
					input.focus();
					input.blur();
				}, 20);
			},
			onResize(data) {
				if (!this.isActive) {
					return;
				}

				const mq = data.mq;
				const oldMq = data.oldMq;
				const sizes = {
					mobile: Object.keys(this.$mq.data).slice(0, 4),
					desktop: Object.keys(this.$mq.data).slice(
						4,
						Object.keys(this.$mq.data).length
					),
				};

				if (
					(sizes.mobile.includes(oldMq) &&
						sizes.desktop.includes(mq)) ||
					(sizes.mobile.includes(mq) && sizes.desktop.includes(oldMq))
				) {
					this.close();
				}
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/search.scss';
</style>
