export default (string = '2020-03-30', language = 'en') => {
	let locale;

	switch (language) {
		case 'de':
			locale = 'de-DE';
			break;
		case 'ru':
			locale = 'ru-RU';
			break;
		case 'ar':
			locale = 'ar-SA';
			break;
		case 'zh':
			locale = 'zh-CN';
			break;
		default:
			locale = 'en-US';
	}

	const date = new Date(string.replace(/-/g, '/'));
	const options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	};

	return date.toLocaleString(locale, options);
};
