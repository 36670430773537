/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-csspositionsticky-objectfit ! */
!(function (e, n, t) {
	function r(e, n) {
		return typeof e === n;
	}
	function o() {
		let e, n, t, o, i, s, l;
		for (const a in h) {
			if (h.hasOwnProperty(a)) {
				if (
					((e = []),
					(n = h[a]),
					n.name &&
						(e.push(n.name.toLowerCase()),
						n.options &&
							n.options.aliases &&
							n.options.aliases.length))
				) {
					for (t = 0; t < n.options.aliases.length; t++) {
						e.push(n.options.aliases[t].toLowerCase());
					}
				}
				for (
					o = r(n.fn, 'function') ? n.fn() : n.fn, i = 0;
					i < e.length;
					i++
				) {
					(s = e[i]),
						(l = s.split('.')),
						l.length === 1
							? (Modernizr[l[0]] = o)
							: (!Modernizr[l[0]] ||
									Modernizr[l[0]] instanceof Boolean ||
									(Modernizr[l[0]] = new Boolean(
										Modernizr[l[0]]
									)),
							  (Modernizr[l[0]][l[1]] = o)),
						C.push((o ? '' : 'no-') + l.join('-'));
				}
			}
		}
	}
	function i(e) {
		return e
			.replace(/([a-z])-([a-z])/g, function (e, n, t) {
				return n + t.toUpperCase();
			})
			.replace(/^-/, '');
	}
	function s() {
		return typeof n.createElement !== 'function'
			? n.createElement(arguments[0])
			: x
			? n.createElementNS.call(
					n,
					'http://www.w3.org/2000/svg',
					arguments[0]
			  )
			: n.createElement.apply(n, arguments);
	}
	function l(e, n) {
		return function () {
			return e.apply(n, arguments);
		};
	}
	function a(e, n, t) {
		let o;
		for (const i in e) {
			if (e[i] in n) {
				return t === !1
					? e[i]
					: ((o = n[e[i]]), r(o, 'function') ? l(o, t || n) : o);
			}
		}
		return !1;
	}
	function f(e, n) {
		return !!~('' + e).indexOf(n);
	}
	function u(n, t, r) {
		let o;
		if ('getComputedStyle' in e) {
			o = getComputedStyle.call(e, n, t);
			const i = e.console;
			if (o !== null) {
				r && (o = o.getPropertyValue(r));
			} else if (i) {
				const s = i.error ? 'error' : 'log';
				i[s].call(
					i,
					'getComputedStyle returning null, its possible modernizr test results are inaccurate'
				);
			}
		} else {
			o = !t && n.currentStyle && n.currentStyle[r];
		}
		return o;
	}
	function p(e) {
		return e
			.replace(/([A-Z])/g, function (e, n) {
				return '-' + n.toLowerCase();
			})
			.replace(/^ms-/, '-ms-');
	}
	function d() {
		let e = n.body;
		return e || ((e = s(x ? 'svg' : 'body')), (e.fake = !0)), e;
	}
	function c(e, t, r, o) {
		let i;
		let l;
		let a;
		let f;
		const u = 'modernizr';
		const p = s('div');
		const c = d();
		if (parseInt(r, 10)) {
			for (; r--; ) {
				(a = s('div')),
					(a.id = o ? o[r] : u + (r + 1)),
					p.appendChild(a);
			}
		}
		return (
			(i = s('style')),
			(i.type = 'text/css'),
			(i.id = 's' + u),
			(c.fake ? c : p).appendChild(i),
			c.appendChild(p),
			i.styleSheet
				? (i.styleSheet.cssText = e)
				: i.appendChild(n.createTextNode(e)),
			(p.id = u),
			c.fake &&
				((c.style.background = ''),
				(c.style.overflow = 'hidden'),
				(f = w.style.overflow),
				(w.style.overflow = 'hidden'),
				w.appendChild(c)),
			(l = t(p, e)),
			c.fake
				? (c.parentNode.removeChild(c),
				  (w.style.overflow = f),
				  w.offsetHeight)
				: p.parentNode.removeChild(p),
			!!l
		);
	}
	function m(n, r) {
		let o = n.length;
		if ('CSS' in e && 'supports' in e.CSS) {
			for (; o--; ) {
				if (e.CSS.supports(p(n[o]), r)) {
					return !0;
				}
			}
			return !1;
		}
		if ('CSSSupportsRule' in e) {
			for (var i = []; o--; ) {
				i.push('(' + p(n[o]) + ':' + r + ')');
			}
			return (
				(i = i.join(' or ')),
				c(
					'@supports (' +
						i +
						') { #modernizr { position: absolute; } }',
					function (e) {
						return u(e, null, 'position') == 'absolute';
					}
				)
			);
		}
		return t;
	}
	function v(e, n, o, l) {
		function a() {
			p && (delete T.style, delete T.modElem);
		}
		if (((l = r(l, 'undefined') ? !1 : l), !r(o, 'undefined'))) {
			const u = m(e, o);
			if (!r(u, 'undefined')) {
				return u;
			}
		}
		for (
			var p, d, c, v, y, h = ['modernizr', 'tspan', 'samp'];
			!T.style && h.length;

		) {
			(p = !0), (T.modElem = s(h.shift())), (T.style = T.modElem.style);
		}
		for (c = e.length, d = 0; c > d; d++) {
			if (
				((v = e[d]),
				(y = T.style[v]),
				f(v, '-') && (v = i(v)),
				T.style[v] !== t)
			) {
				if (l || r(o, 'undefined')) {
					return a(), n == 'pfx' ? v : !0;
				}
				try {
					T.style[v] = o;
				} catch (g) {}
				if (T.style[v] != y) {
					return a(), n == 'pfx' ? v : !0;
				}
			}
		}
		return a(), !1;
	}
	function y(e, n, t, o, i) {
		const s = e.charAt(0).toUpperCase() + e.slice(1);
		let l = (e + ' ' + z.join(s + ' ') + s).split(' ');
		return r(n, 'string') || r(n, 'undefined')
			? v(l, n, o, i)
			: ((l = (e + ' ' + b.join(s + ' ') + s).split(' ')), a(l, n, t));
	}
	var h = [];
	const g = {
		_version: '3.6.0',
		_config: {
			classPrefix: '',
			enableClasses: !0,
			enableJSClass: !0,
			usePrefixes: !0,
		},
		_q: [],
		on(e, n) {
			const t = this;
			setTimeout(function () {
				n(t[e]);
			}, 0);
		},
		addTest(e, n, t) {
			h.push({ name: e, fn: n, options: t });
		},
		addAsyncTest(e) {
			h.push({ name: null, fn: e });
		},
	};
	var Modernizr = function () {};
	(Modernizr.prototype = g), (Modernizr = new Modernizr());
	var C = [];
	const S = g._config.usePrefixes
		? ' -webkit- -moz- -o- -ms- '.split(' ')
		: ['', ''];
	g._prefixes = S;
	var w = n.documentElement;
	var x = w.nodeName.toLowerCase() === 'svg';
	Modernizr.addTest('csspositionsticky', function () {
		const e = 'position:';
		const n = 'sticky';
		const t = s('a');
		const r = t.style;
		return (
			(r.cssText = e + S.join(n + ';' + e).slice(0, -e.length)),
			r.position.includes(n)
		);
	});
	const _ = 'Moz O ms Webkit';
	var b = g._config.usePrefixes ? _.toLowerCase().split(' ') : [];
	g._domPrefixes = b;
	var z = g._config.usePrefixes ? _.split(' ') : [];
	g._cssomPrefixes = z;
	const E = function (n) {
		let r;
		const o = S.length;
		const i = e.CSSRule;
		if (typeof i === 'undefined') {
			return t;
		}
		if (!n) {
			return !1;
		}
		if (
			((n = n.replace(/^@/, '')),
			(r = n.replace(/-/g, '_').toUpperCase() + '_RULE'),
			r in i)
		) {
			return '@' + n;
		}
		for (let s = 0; o > s; s++) {
			const l = S[s];
			const a = l.toUpperCase() + '_' + r;
			if (a in i) {
				return '@-' + l.toLowerCase() + '-' + n;
			}
		}
		return !1;
	};
	g.atRule = E;
	const P = { elem: s('modernizr') };
	Modernizr._q.push(function () {
		delete P.elem;
	});
	var T = { style: P.elem.style };
	Modernizr._q.unshift(function () {
		delete T.style;
	}),
		(g.testAllProps = y);
	const k = (g.prefixed = function (e, n, t) {
		return e.indexOf('@') === 0
			? E(e)
			: (e.includes('-') && (e = i(e)), n ? y(e, n, t) : y(e, 'pfx'));
	});
	Modernizr.addTest('objectfit', !!k('objectFit'), {
		aliases: ['object-fit'],
	}),
		o(),
		delete g.addTest,
		delete g.addAsyncTest;
	for (let j = 0; j < Modernizr._q.length; j++) {
		Modernizr._q[j]();
	}
	e.Modernizr = Modernizr;
})(window, document);

export default Modernizr;
