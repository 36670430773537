<template>
	<li :class="{ 'is-active': isActive }">
		<slot />
	</li>
</template>

<script>
	export default {
		props: {
			item: {
				type: Object,
			},
			isActive: {
				type: Boolean,
			},
		},
		data() {
			return {
				highlightPeriod: 0,
				isHighlighted: false,
			};
		},
		watch: {
			isActive: {
				immediate: true,
				handler(val) {
					this.onIsActiveChange(val);
				},
			},
		},
		methods: {
			onIsActiveChange(isActive) {
				if (isActive) {
					this.emitHighlight();
					this.adjustHighlightPeriod();
				}
			},
			adjustHighlightPeriod() {
				if (
					this.item.highlight &&
					this.highlightPeriod < this.item.highlight.periodicity - 1
				) {
					this.highlightPeriod++;
				} else {
					this.highlightPeriod = 0;
				}
			},
			emitHighlight() {
				this.isHighlighted = !!(
					!this.highlightPeriod && this.item.highlight
				);

				this.$parent.$emit('highlight-parent', {
					isHighlight: this.isHighlighted,
					backgroundColor: this.isHighlighted
						? this.item.highlight.backgroundColor
						: null,
					textColor: this.isHighlighted
						? this.item.highlight.textColor
						: null,
				});
			},
		},
	};
</script>
