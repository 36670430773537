var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav--mobile-menu-bottom"},[_c('nav',{staticClass:"nav--mobile-menu-bottom-wrapper"},[_c('div',{staticClass:"nav--mobile-menu-bottom-container"},[_c('ul',{staticClass:"nav--mobile-menu-bottom-list"},[_c('li',[_c('button',{class:[
							'nav--mobile-menu-bottom-item',
							'nav--mobile-menu-bottom-item--menu',
							{
								'nav--mobile-menu-bottom-item--active':
									_vm.isMenuOn,
							},
						],attrs:{"type":"button","title":_vm.isMenuOn
								? _vm.$__('Close main menu')
								: _vm.$__('Open main menu'),"aria-label":_vm.isMenuOn
								? _vm.$__('Close main menu')
								: _vm.$__('Open main menu')},on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"nav--mobile-menu-bottom-icon--menu icon-bambini_b"}),_vm._v(" "),_c('span',[_vm._v("Menu")])])]),_vm._v(" "),_c('li',[_c('NuxtLink',{class:[
							'nav--mobile-menu-bottom-item',
							{
								'nav--mobile-menu-bottom-item--active':
									_vm.activeRoute === 'index' &&
									!_vm.isMenuOn &&
									!_vm.isSearchOn,
							},
						],attrs:{"title":_vm.$__('Home'),"aria-label":_vm.$__('Home'),"to":"/"},nativeOn:{"click":function($event){return _vm.setRouteActiveOrScroll('index')}}},[_c('i',{staticClass:"nav--mobile-menu-bottom-icon icon-home"}),_vm._v(" "),_c('span',[_vm._v("Home")])])],1),_vm._v(" "),_c('li',[_c('NuxtLink',{class:[
							'nav--mobile-menu-bottom-item',
							{
								'nav--mobile-menu-bottom-item--active':
									_vm.activeRoute === 'shopping-bag' &&
									!_vm.isMenuOn &&
									!_vm.isSearchOn,
							},
						],attrs:{"title":_vm.$__('Shopping bag'),"aria-label":_vm.$__('Shopping bag'),"to":"/shopping-bag"},nativeOn:{"click":function($event){return _vm.setRouteActiveOrScroll('shopping-bag')}}},[_c('i',{staticClass:"nav--mobile-menu-bottom-icon icon-bag"},[_c('TopCounter',{attrs:{"count":_vm.counters.cart}})],1),_vm._v(" "),_c('span',[_vm._v("Bag")])])],1),_vm._v(" "),_c('li',[_c('button',{class:[
							'nav--mobile-menu-bottom-item',
							{
								'nav--mobile-menu-bottom-item--active':
									_vm.isSearchOn && !_vm.isMenuOn,
							},
						],attrs:{"type":"button","title":_vm.$__('Search'),"aria-label":_vm.$__('Search')},on:{"click":_vm.toggleSearch}},[_c('i',{staticClass:"nav--mobile-menu-bottom-icon icon-search"}),_vm._v(" "),_c('span',[_vm._v("Search")])])]),_vm._v(" "),_c('li',[_c('NuxtLink',{class:[
							'nav--mobile-menu-bottom-item',
							{
								'nav--mobile-menu-bottom-item--active':
									_vm.activeRoute === 'designers' &&
									!_vm.isMenuOn &&
									!_vm.isSearchOn,
							},
						],attrs:{"title":_vm.$__('Designers'),"aria-label":_vm.$__('Designers'),"to":"/designers"},nativeOn:{"click":function($event){return _vm.setRouteActiveOrScroll('designers')}}},[_c('i',{staticClass:"nav--mobile-menu-bottom-icon icon-star"}),_vm._v(" "),_c('span',[_vm._v("Designers")])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }