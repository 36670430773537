import { apiClient } from '@/plugins/axios';
import { UserDelivery, UserTax } from 'types/user';

export async function getUserDeliveryTax(isoCode: string | undefined) {
	const isoString = isoCode ? `&countryOverride=${isoCode}` : '';
	return await apiClient.$get<{
		user: {
			delivery: UserDelivery;
			tax: UserTax;
		};
	}>(`user:delivery&tax${isoString}`);
}
