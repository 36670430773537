import localeDefaults from '@/assets/json/localization/defaults.json';
import localeValidate from '@/assets/json/localization/validate.json';

import useDetach from '@/use/detach.js';
import useGetApiPath from '@/use/get-api-path.js';

export default async function ({ app, redirect, req, res, route, store }) {
	store.commit('ui/SET_DATA', {
		utsStartLoading: Date.now(),
	});

	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/* Sanitize path from non ASCII chars
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/

	if (process.server) {
		route = sanitizeRoute({ route });
	}

	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/* Possible redirects
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/

	if (removePathTrailingSlash({ route, redirect })) {
		return;
	}
	if (convertPathToLowerCase({ route, redirect })) {
		return;
	}
	if (redirectLocalePrefix({ route, redirect })) {
		return;
	}

	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/* Store token from cookie
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/

	commitToken({ store, req });

	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/* Store correct localization variables and localize all future requests
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/

	commitLocalizationMutations({ store, route, req, res });

	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/* Store referer variables for all future requests
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	if (process.server) {
		commitReferer({ store, req });
	}

	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/* Bail earlier if it is a healthcheck
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/

	if (isHealthCheck({ route })) {
		return;
	}

	if (route.name === 'getapp') {
		return getApp({ app, route, redirect, store });
	}

	if (route.query.magic) {
		let magicLink;
		const { magic, ...rest } = route.query;
		try {
			const response = await app.$axios.get(
				`user:magicLink?t=${route.query.magic}`
			);
			magicLink = response.data.user.magicLink;
			app.$cookies.set('bf_token', magicLink.token, {
				domain: process.env.COOKIE_DOMAIN,
				sameSite: 'lax',
				secure: 1,
				http: true,
				path: '/',
				expires: new Date('31 Dec 2099 23:59:59 UTC'),
			});
		} catch (error) {
			magicLink = error.response.data.user.magicLink;
		} finally {
			route.query = rest;
		}
		console.log(magicLink);
		return redirect({
			path: route.path,
			query: {
				...rest,
			},
		});
	}

	if (process.server) {
		const botUserAgents = [
			'googlebot',
			'bingbot',
			'linkedinbot',
			'mediapartners-google',
			'lighthouse',
			'insights',
			'YandexBot',
			'YandexMedia',
			'YandexMetrika',
			'YandexMobileBot',
			'YandexAccessibilityBot',
			'YandexImages',
			'YandexWebmaster',
		];
		const userAgent = req.headers['user-agent'];
		const isSearchBot = botUserAgents.some((item) =>
			userAgent.toLowerCase().includes(item.toLowerCase())
		);

		if (isSearchBot) {
			store.commit('user/setIsSearchBot', isSearchBot);
		}
	}

	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/* Execution
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/

	try {
		const apiPath = useGetApiPath({ route, store });
		const response = await app.$axios.get(apiPath);

		commitCategoryMutations({ response, route, store });

		// Do we need this???
		commitNextApiMutations({ response, store });

		handleFrontendRedirects({ route, response, redirect });
	} catch (error) {
		handleError({ error, route, redirect });
	}
}

/******************************************************************************************/
/******************************************************************************************/
/******************************************************************************************/
/******************************************************************************************/
/******************************************************************************************/
/* Functions
/******************************************************************************************/
/******************************************************************************************/
/******************************************************************************************/
/******************************************************************************************/
/******************************************************************************************/

export const isValidLocaleParam = (key, value) => {
	const isValidLocaleIndex = localeValidate[key].includes(value);

	return typeof value !== 'undefined' && isValidLocaleIndex;
};

export const isValidJsonString = (string) => {
	if (!string) {
		return false;
	}

	try {
		JSON.parse(string);
	} catch (error) {
		return false;
	}

	return true;
};

export const getLocaleNeeded = ({ store, route, req, res }) => {
	// Getting available locale params
	const availableLocales = {
		bf: getCookieLocale({ store, req }),
		cf: getCfLocale({ res }),
		query: route.query,
	};

	// Setting complete locale to fallback, detached to prevent mutation, zeroing country:
	let locale = useDetach(localeDefaults.us);

	locale = evaluateLocaleCountry({ availableLocales, locale });
	locale = overrideByCookieLocale({ availableLocales, locale });
	locale = overrideByQueryLocale({ availableLocales, locale });

	locale.language = 'en';

	return locale;
};

export const commitToken = ({ store, req }) => {
	const token = store.getters['user/GET_COOKIE']({
		name: store.state.user.cookie.names.token,
		req,
	});

	if (token) {
		store.commit('user/SET_DATA', {
			token,
		});
	}
};

export const commitLocalizationMutations = ({ store, route, req, res }) => {
	const locale = getLocaleNeeded({ store, route, req, res });

	store.commit('user/SET_LOCALIZATION', locale);
};

export const commitReferer = ({ store, req }) => {
	const { referer } = req.headers;

	if (
		referer &&
		!referer?.includes('bambinifashion') &&
		!referer.includes('paypal')
	) {
		store.commit('user/SET_REFERER', referer);
	}
};

export const commitCategoryMutations = ({ response, route, store }) => {
	if (!store.state.ui.isFilterUpdating) {
		store.commit('ui/RESET_FILTER_HISTORY');
	}

	if (typeof response.data.ui === 'undefined') {
		// UI storage mutations for category page
		response.data.ui = {};
	}

	if (route.name.startsWith('all') && !route.path.endsWith('.html')) {
		response.data.ui.isFilterPage = true;

		if (typeof response.data.page !== 'undefined') {
			// Modify category structure to allow inifinite scrolling
			const index = response.data.page.content.pagination.pageCurrent;
			const products = Object.freeze(response.data.page.content.products);

			response.data.page.content.infinite = [
				{
					index,
					products,
				},
			];

			store.dispatch('user/updateRecentCategory', {
				uts: store.state.ui.utsNow,
			});
		}

		if (typeof response.data.page !== 'undefined') {
			// Adjust filter sections
			const sections = { ...store.state.ui.filterExpandedSections };

			const expandedSections = Object.entries(sections).reduce(
				(acc, [item, value]) => {
					return value ? [item, ...acc] : acc;
				},
				[]
			);
			if (expandedSections.length > 0) {
				response.data.page.content.filter.sections.map((section) => {
					expandedSections.includes(section.name)
						? (section.isExpanded = true)
						: (section.isExpanded = false);
					return section;
				});
			} else {
				response.data.page.content.filter.sections.forEach(
					(section) => {
						if (typeof sections[section.name] === 'undefined') {
							sections[section.name] = section.isExpanded;
						}
					}
				);
			}

			response.data.ui.filterExpandedSections = sections;
		}
	} else {
		response.data.ui.isFilterPage = false;
		response.data.ui.isFilterMobileOn = false;
		response.data.ui.isFilterUpdating = false;
	}
};

export const commitNextApiMutations = ({ response, store }) => {
	// Commit response to the temporary store until route.afterEach()
	store.commit('SET_NEXT_API_DATA', response.data);

	// Update store modules with fresh response data
	if (process.server) {
		store.dispatch('syncWithNext');
	}
};

export const handleFrontendRedirects = ({ response, route, redirect }) => {
	if (route.name === 'wishlist') {
		if (!response?.data?.user?.hasEmail) {
			redirect('/wishlist/sign-in');
		}
	}
};

export const removePathTrailingSlash = ({ route, redirect }) => {
	const hasTrailingSlash = route.path.endsWith('/');
	const hasPath = route.path.length > 1;

	if (!hasTrailingSlash || !hasPath) {
		return false;
	}

	redirect({
		path: route.path.slice(0, -1),
		params: route.params,
		query: route.query,
	});

	return true;
};

export const convertPathToLowerCase = ({ route, redirect }) => {
	const pathToLowerCase = route.path.toLowerCase();

	if (route.path === pathToLowerCase) {
		return false;
	}

	redirect({
		path: pathToLowerCase,
		params: route.params,
		query: route.query,
	});

	return true;
};

export const redirectLocalePrefix = ({ route, redirect }) => {
	const prefix = /^((\/[a-z]{2}\/)|(\/[a-z]{2}$))/gm;

	if (!route.path.match(prefix)) {
		return false;
	}

	const redirectPath = route.path.replace(prefix, '/');

	redirect({
		path: redirectPath,
		params: route.params,
		query: route.query,
	});

	return true;
};

export const isHealthCheck = ({ route }) => {
	return route.name === 'health';
};

export const getApp = ({ app, route, redirect, store }) => {
	const source = route.query.utm_source;
	const appStoreUrl = `https://apps.apple.com/app/apple-store/id1597308965?pt=120458505&ct=${source}&mt=8`;
	const googlePlayUrl = `https://play.google.com/store/apps/details?id=com.bambinifashion.production&utm_source=${source}`;
	const device = store.state.ui.device;

	if (device.isIOS) {
		redirect(appStoreUrl);
	} else if (device.isAndroidOS) {
		redirect(googlePlayUrl);
	}

	redirect('/');

	return true;
};

export const handleProductNotFound = ({ route, redirect, error }) => {
	if (route.name !== 'all' || !route.path.endsWith('.html')) {
		return false;
	}
	if (!error.response) {
		return false;
	}
	if (error.response.status === 404) {
		redirect('/');
		return true;
	}
	return false;
};

export const handleError = ({ error, route, redirect }) => {
	const redirectHandled = handleProductNotFound({
		error,
		route,
		redirect,
	});
	if (redirectHandled) {
		return;
	}
	const errorPath = '/not-found';
	const fatalPath = '/not-working.html';

	const isFatal = route.path.endsWith(errorPath);
	const redirectPath = isFatal ? fatalPath : errorPath;

	serverLogError({ error, isFatal, route });
	redirect({
		path: redirectPath,
		params: route.params,
		query: route.query,
	});
};

export const serverLogError = ({ error, isFatal, route }) => {
	if (!process.server) {
		return;
	}

	if (typeof error.response === 'undefined') {
		console.error(error);
	}

	console.error({
		fatal: isFatal,
		status: `${error.response.statusText}`,
		host: `${process.env.HOST_URL}${route.path}`,
		query: route.query,
		api: `${process.env.API_URL}${error.response.request.path}`,
		timestamp: new Date(),
	});
};

export const getCookieLocale = ({ store, req }) => {
	const cookieString = store.getters['user/GET_COOKIE']({
		name: store.state.user.cookie.names.localization,
		req,
	});

	if (!isValidJsonString(cookieString)) {
		return {};
	}

	const cookieObject = JSON.parse(cookieString);

	return {
		country: cookieObject.country,
		currency: cookieObject.currency,
		language: cookieObject.language,
	};
};

export const getCfLocale = ({ res }) => {
	if (!process.server) {
		return {};
	}

	return {
		country: res.getHeaders()['cf-ipcountry'],
	};
};

export const evaluateLocaleCountry = ({ availableLocales, locale }) => {
	let availableCountry = null;

	if (isValidLocaleParam('country', availableLocales.bf.country)) {
		availableCountry = availableLocales.bf.country;
	} else if (
		typeof availableLocales.cf.country !== 'undefined' &&
		isValidLocaleParam('country', availableLocales.cf.country)
	) {
		availableCountry = availableLocales.cf.country;
	}

	if (availableCountry == null) {
		return locale;
	}

	const availableLocaleDefault = Object.keys(localeDefaults).find(
		(localeDefault) => {
			return localeDefaults[localeDefault].country === availableCountry; // whether a locale exists for this country
		}
	);

	if (availableLocaleDefault) {
		locale = useDetach(localeDefaults[availableLocaleDefault]); // resets whole locale according to available country, detached to prevent mutation
	} else {
		locale.country = availableCountry; // only alters the country
	}

	return locale;
};

export const overrideByCookieLocale = ({ availableLocales, locale }) => {
	Object.keys(locale).forEach((param) => {
		if (
			param !== 'country' &&
			isValidLocaleParam(param, availableLocales.bf[param])
		) {
			locale[param] = availableLocales.bf[param];
		} // override each by corresponding cookie value if available
	});

	return locale;
};

export const overrideByQueryLocale = ({ availableLocales, locale }) => {
	Object.keys(locale).forEach((param) => {
		if (isValidLocaleParam(param, availableLocales.query[param])) {
			locale[param] = availableLocales.query[param];
		} // override each by corresponding query value if available
	});

	return locale;
};

export const sanitizeRoute = ({ route }) => {
	// This whole thing is a disgrace.
	route.path = route.path
		.split('/')
		.map((i) =>
			i
				.split('=')
				.map((j) => encodeURIComponent(j).split('%2C').join(','))
				.join('=')
		)
		.join('/');
	return route;
};
