var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.dismiss),expression:"dismiss"}],ref:"Search",staticClass:"livesearch",style:(_vm.style)},[_c('div',{staticClass:"livesearch-container"},[_c('form',{staticClass:"livesearch-form",attrs:{"role":"search","method":_vm.form.method,"action":_vm.formQueryPath},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('button',{staticClass:"livesearch-form-button livesearch-form-button--back",attrs:{"type":"button","title":_vm.$__('Back'),"aria-label":_vm.$__('Back')},on:{"click":function($event){return _vm.dismiss()}}}),_vm._v(" "),_c('div',{staticClass:"livesearch-form-wrapper"},[_c('input',{staticClass:"livesearch-form-input",attrs:{"id":_vm.inputId,"autocomplete":"off","type":"search"},domProps:{"value":_vm.form.query},on:{"keydown":_vm.onKeydown,"input":function($event){_vm.form.query = $event.target.value}}}),_vm._v(" "),_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.isInputLabelVisible),expression:"isInputLabelVisible"}],staticClass:"livesearch-form-label",attrs:{"for":_vm.inputId},domProps:{"textContent":_vm._s(_vm.$__('Search'))}})]),_vm._v(" "),(_vm.form.query !== '')?_c('button',{staticClass:"livesearch-form-button livesearch-form-button--clear",attrs:{"type":"button"},on:{"click":_vm.clearQuery}},[_vm._m(0)]):_vm._e(),_vm._v(" "),_c('button',{class:[
					'livesearch-form-button',
					'livesearch-form-button--start',
					{
						'is-loading':
							_vm.isLoading && _vm.formQuery.length > _vm.minQueryLength,
					},
				],attrs:{"type":"submit","title":_vm.$__('Search'),"aria-label":_vm.$__('Search')}},[_vm._m(1),_vm._v(" "),_c('Spinner',{staticClass:"btn-spinner"})],1)]),_vm._v(" "),_c('div',{staticClass:"livesearch-list-wrapper",attrs:{"id":"listWrapper"}},[_c('div',{ref:"SearchList",staticClass:"livesearch-list-container"},[(_vm.hasSuggestions)?[_c('div',{staticClass:"livesearch-list-container--left"},[(_vm.suggestions.brands.length > 0)?_c('div',{staticClass:"livesearch-list-category livesearch-list-category--brands"},[_c('div',{staticClass:"livesearch-list-category-header"},[_c('h3',[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$__('Designers'))+"\n\t\t\t\t\t\t\t\t")]),_vm._v(" "),(
										_vm.device.isMobile &&
										!_vm.isDesignersExpanded
									)?_c('span',{on:{"click":function($event){_vm.isDesignersExpanded = true}}},[_vm._v("\n\t\t\t\t\t\t\t\t\tView More\n\t\t\t\t\t\t\t\t")]):_vm._e()]),_vm._v(" "),_vm._l((_vm.suggestions.brands),function(brand,index){return _c('div',{key:`search-list-item-brand--${index}${brand.title}`,ref:"SearchListItem",refInFor:true,class:[
									'livesearch-list-item',
									{
										'is-selected':
											_vm.selectedListItemIndex === index,
									},
								],attrs:{"data-index":index},on:{"mouseenter":function($event){return _vm.onMouseEnterListItem($event)},"mouseleave":function($event){return _vm.onMouseLeaveListItem($event)}}},[_c('NuxtLink',{staticClass:"livesearch-list-item-link",attrs:{"to":brand.url,"event":""},domProps:{"textContent":_vm._s(brand.title)},nativeOn:{"click":function($event){return _vm.navigate(brand)}}})],1)}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
									!_vm.device.isMobile ||
									(_vm.device.isMobile && _vm.isDesignersExpanded)
								),expression:"\n\t\t\t\t\t\t\t\t\t!device.isMobile ||\n\t\t\t\t\t\t\t\t\t(device.isMobile && isDesignersExpanded)\n\t\t\t\t\t\t\t\t"}],staticClass:"livesearch-view-all livesearch-view-all--designers"},[_c('nuxt-link',{staticClass:"livesearch-view-all-btn",attrs:{"to":"/designers"}},[_c('span',{staticClass:"btn-inner"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$__('View All Designers'))+"\n\t\t\t\t\t\t\t\t\t")])])],1)],2):_vm._e(),_vm._v(" "),(_vm.suggestions.categories.length > 0)?_c('div',{staticClass:"livesearch-list-category livesearch-list-category--categories"},[_c('div',{staticClass:"livesearch-list-category-header"},[_c('h3',[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$__('Categories'))+"\n\t\t\t\t\t\t\t\t")]),_vm._v(" "),(
										_vm.device.isMobile &&
										!_vm.isCategoriesExpanded
									)?_c('span',{on:{"click":function($event){_vm.isCategoriesExpanded = true}}},[_vm._v("\n\t\t\t\t\t\t\t\t\tView More\n\t\t\t\t\t\t\t\t")]):_vm._e()]),_vm._v(" "),_vm._l((_vm.suggestions.categories),function(category,index){return _c('div',{key:`search-list-item-category--${index}${category.title}`,ref:"SearchListItem",refInFor:true,class:[
									'livesearch-list-item',
									{
										'is-selected':
											_vm.selectedListItemIndex ===
											_vm.suggestions.brands.length +
												index,
									},
								],attrs:{"data-index":_vm.suggestions.brands.length + index},on:{"mouseenter":function($event){return _vm.onMouseEnterListItem($event)},"mouseleave":function($event){return _vm.onMouseLeaveListItem($event)}}},[_c('NuxtLink',{staticClass:"livesearch-list-item-link",attrs:{"to":category.url,"event":""},domProps:{"textContent":_vm._s(category.title)},nativeOn:{"click":function($event){return _vm.navigate(category)}}})],1)})],2):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"livesearch-list-container--right"},[(_vm.suggestions.products.length > 0)?_c('div',{staticClass:"livesearch-list-category livesearch-list-category--products"},[_c('div',{staticClass:"livesearch-list-category-header"},[_c('h3',[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$__('Products'))+"\n\t\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"livesearch-list-category--products-container"},[_vm._l((_vm.suggestions.products),function(product){return _c('NuxtLink',{key:`search-list-item-product--${product.imageUrls[0]}`,class:[
										'livesearch-list-item',
										'livesearch-list-item--product',
									],attrs:{"to":`${product.url}?from-search=true`,"event":""},nativeOn:{"click":function($event){return _vm.navigate(product)}}},[_c('div',{staticStyle:{"position":"relative"}},[_c('LazyImg',{attrs:{"width":"100","height":"auto","alt":product.title,"data-srcset":_vm.$dataSrc({
													url: product
														.imageUrls[0],
													size: 'product-card',
												}),"data-src":_vm.$dataSrc({
													url: product
														.imageUrls[0],
													size: 'product-card',
												}),"data-object-position":"center center"}})],1),_vm._v(" "),_c('div',{staticClass:"livesearch-list-item--product-basic"},[_c('div',{staticClass:"livesearch-list-item--product-brand"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(product.brand.title)+"\n\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"livesearch-list-item--product-title"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(product.title)+"\n\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"product-price livesearch-list-item--product-price"},[_c('span',{class:[
													'livesearch-list-item--product-price-regular',
													{
														'is-reduced':
															product.priceReduced &&
															product.priceReduced !==
																product.priceRegular,
													},
												],attrs:{"dir":"auto"},domProps:{"textContent":_vm._s(
													product.priceRegular
												)}}),_vm._v(" "),(
													product.priceReduced &&
													product.priceReduced !==
														product.priceRegular
												)?_c('span',{staticClass:"livesearch-list-item--product-price-reduced",attrs:{"dir":"auto"},domProps:{"textContent":_vm._s(
													product.priceReduced
												)}}):_vm._e()])])])}),_vm._v(" "),_c('div',{staticClass:"livesearch-view-all"},[_c('button',{staticClass:"livesearch-view-all-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.onSubmit($event)}}},[_c('span',{staticClass:"btn-inner"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$__('View All Products'))+"\n\t\t\t\t\t\t\t\t\t\t")])])])],2)]):_vm._e()])]:_vm._e(),_vm._v(" "),(_vm.hasRecent && _vm.formQuery.length < 1)?_c('div',{staticClass:"livesearch-list-category livesearch-list-category--recent"},[_c('div',{staticClass:"livesearch-list-category-header"},[_c('h3',[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.$__('Recent search'))+"\n\t\t\t\t\t\t")])]),_vm._v(" "),_c('h3'),_vm._v(" "),_vm._l((_vm.recentItems),function(item,index){return _c('div',{key:`search-list-item-recent--${index}`,ref:"SearchListItem",refInFor:true,class:[
							'livesearch-list-item',
							{
								'is-selected':
									_vm.selectedListItemIndex ===
									_vm.suggestions.brands.length + index,
							},
						],attrs:{"data-index":index},on:{"mouseenter":function($event){return _vm.onMouseEnterListItem($event)},"mouseleave":function($event){return _vm.onMouseLeaveListItem($event)}}},[_c('span',{staticClass:"livesearch-list-item-link",domProps:{"textContent":_vm._s(item.title)},on:{"click":function($event){return _vm.navigate(item)}}}),_vm._v(" "),_c('button',{staticClass:"livesearch-list-item-remove",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeFromRecentItems(item)}}},[_c('i',{staticClass:"icon-cross"})])])})],2):_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"btn-inner"},[_c('i',{staticClass:"icon-cross"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"btn-inner"},[_c('i',{staticClass:"livesearch-button--start-icon icon-search"})])
}]

export { render, staticRenderFns }