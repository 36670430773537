<template>
	<transition name="top-user-cart-tooltip-transition" mode="out-in">
		<div v-if="isOn" :key="addedProductKey" class="top-user-cart-tooltip">
			<strong v-text="addedProductSummary" />
			{{ $__('has been added to your shopping bag') }}
		</div>
	</transition>
</template>

<script>
	export default {
		data() {
			return {
				isOn: false,
				addedProduct: null,
				hideTimeout: null,
			};
		},
		computed: {
			hideTimeoutDuration() {
				return this.$store.state.ui.pinnedTimeoutLong;
			},
			addedProductKey() {
				return this.addedProduct != null
					? this.addedProduct.variation.id
					: 'new';
			},
			addedProductSummary() {
				if (this.addedProduct != null) {
					return `${this.addedProduct.brand.title.toUpperCase()} ${
						this.addedProduct.title
					} (${this.addedProduct.variation.title})`;
				} else {
					return this.$__('A new product');
				}
			},
		},
		mounted() {
			this.$bus.$on(
				'show-top-user-cart-tooltip',
				this.onProductAddedToCart
			);
		},
		beforeDestroy() {
			this.$bus.$off(
				'show-top-user-cart-tooltip',
				this.onProductAddedToCart
			);
		},
		methods: {
			onProductAddedToCart(product) {
				if (product != null) {
					this.addedProduct = product;

					if (this.isOn) {
						this.clearHideTimeout();
					}

					this.isOn = true;
					this.setHideTimeout();
				}
			},
			setHideTimeout() {
				this.hideTimeout = window.setTimeout(() => {
					this.isOn = false;
				}, this.hideTimeoutDuration);
			},
			clearHideTimeout() {
				window.clearTimeout(this.hideTimeout);
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/top-user-cart-tooltip.scss';
</style>
