// eslint-disable-next-line import/no-mutable-exports
export let blogClient;

export function setClient(newClient) {
	blogClient = newClient;
}

export default function ({ $axios }, inject) {
	const api = $axios.create({
		headers: {
			Authorization: `bearer ${process.env.BLOG_API_KEY}`,
		},
	});

	api.setBaseURL(process.env.BLOG_API_URL);

	setClient(api);

	inject('blogClient', api);
}
