import dismissByEsc from './dismiss-by-esc';

export default {
	data() {
		return {
			isOpen: false,
			canClickOutside: false,
			hoverDropdownItemClass: 'is-hover',
		};
	},
	methods: {
		toggle() {
			!this.isOpen ? this.open() : this.close();
		},
		open() {
			this.isOpen = true;
		},
		close() {
			this.isOpen = false;
		},
		dismiss() {
			if (this.canClickOutside) {
				this.close();
			}
		},
		setClickOutside(val) {
			setTimeout(() => {
				this.canClickOutside = val;
			}, 0);
		},
		hoverDropdownItem(event) {
			event.target.classList.add(this.hoverDropdownItemClass);
		},
		leaveDropdownItem(event) {
			event.target.classList.remove(this.hoverDropdownItemClass);
		},
	},
	watch: {
		isOpen(val) {
			this.setEscDismission(val);
			this.setClickOutside(val);
		},
	},
	mixins: [dismissByEsc],
};
