import Vue from 'vue';

// Global directives
import observeVisibility from 'vue-observe-visibility';
import clickOutside from '../use/vue-click-outside.js';

// Client includes
import useBodyfix from '../use/bodyfix.js';
import useBus from '../use/bus.js';
import useLazyload from '../use/lazyload.js';
import useTabbing from '../use/tabbing.js';
import useMq from '../use/mq.js';
import Modernizr from '../use/modernizr.js';

export default ({ store }, inject) => {
	inject('bus', useBus);
	inject('bodyfix', useBodyfix);
	inject('mq', useMq);
	inject('lazyload', useLazyload);

	useBodyfix.init(store);
	useMq.init();
	useLazyload.init();
	useTabbing.init(store);
};

Vue.use(observeVisibility);
Vue.directive('click-outside', clickOutside);
