<template>
	<ul class="rotaterator">
		<RotateratorItem
			v-for="(item, index) in items"
			:key="`rotaterator-item--${index}`"
			:is-active="index === activeIndex"
			:item="item"
		>
			<Countdown v-if="item.countdown" :countdown="item.countdown" />
			<div v-else v-html="item.content" />
		</RotateratorItem>
	</ul>
</template>

<script>
	import RotateratorItem from './RotateratorItem.vue';
	import Countdown from './Countdown.vue';

	export default {
		components: {
			RotateratorItem,
			Countdown,
		},
		props: {
			items: {
				type: Array,
				default() {
					return [];
				},
			},
		},
		data() {
			return {
				activeIndex: 0,
				duration: 2750,
			};
		},
		watch: {
			activeIndex(newIndex) {
				this.rotateIndex();
			},
		},
		mounted() {
			this.rotateIndex();
		},
		methods: {
			rotateIndex() {
				if (this.items.length < 2) {
					return;
				}

				const duration =
					this.items[this.activeIndex].duration || this.duration;

				setTimeout(() => {
					if (this.activeIndex + 1 === this.items.length) {
						this.activeIndex = 0;
					} else {
						this.activeIndex++;
					}
				}, duration);
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/rotaterator.scss';
</style>
