<template>
	<ClientOnly>
		<span class="spinner" role="alert" aria-busy="true">
			<svg class="spinner-svg" viewBox="25 25 50 50" :style="svgStyle">
				<circle
					:style="pathStyle"
					:stroke-width="strokeWidth"
					fill="none"
					class="spinner-circle"
					cx="50"
					cy="50"
					r="20"
					stroke-miterlimit="10"
				/>
			</svg>
		</span>
	</ClientOnly>
</template>

<script>
	export default {
		name: 'Spinner',
		props: {
			updateKey: {
				default: '',
			},
		},
		data() {
			return {
				size: '',
				color: '',
			};
		},
		computed: {
			strokeWidth() {
				return (
					Math.ceil(Math.sqrt(50 / parseInt(this.size)) * 2.5) || 5
				);
			},
			svgStyle() {
				return {
					width: this.size,
					height: this.size,
				};
			},
			pathStyle() {
				return {
					stroke: this.color,
				};
			},
		},
		watch: {
			updateKey() {
				this.getParentStyles();
			},
		},
		beforeMount() {
			this.getParentStyles();
		},
		methods: {
			getParentStyles() {
				if (!process.browser) {
					return;
				}
				this.$nextTick().then(() => {
					if (this.$el.children) {
						const style = window.getComputedStyle(this.$el);

						this.size =
							parseInt(style.getPropertyValue('font-size')) +
							'px';
						this.color = style.getPropertyValue('color');
					}
				});
			},
		},
	};
</script>

<style lang="scss">
	.spinner {
		display: flex;
		align-items: center;
		&-svg {
			display: flex;
			animation: spinner-rotate 2s linear infinite;
			transform-origin: center center;
		}

		&-circle {
			stroke-dasharray: 89, 200;
			stroke-dashoffset: 0;
			transform-origin: center center;
			animation: spinner-dash 1.5s ease-in-out infinite;
		}
	}

	@keyframes spinner-rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes spinner-dash {
		0% {
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 89, 200;
			stroke-dashoffset: -35px;
		}
		100% {
			stroke-dasharray: 89, 200;
			stroke-dashoffset: -124px;
		}
	}
</style>
