<template>
	<LazyHydrate when-idle>
		<div>
			<dl
				v-for="day in workingHours"
				:key="`working-day--${day.dayOfWeek}`"
				:class="[
					{ 'is-open': isOpen(day) },
					{ 'is-today': isToday(day) },
				]"
			>
				<dt v-text="day.title" />
				<dd dir="auto" v-text="getFormattedWorkingHours(day)" />
			</dl>
		</div>
	</LazyHydrate>
</template>

<script>
	import { isToday, isOpen } from '@/use/working-hours';

	export default {
		computed: {
			workingHours() {
				return this.$store.state.common.workingHours;
			},
			utsNow() {
				return this.$store.state.ui.utsNow;
			},
			dateNow() {
				return new Date(this.utsNow);
			},
		},
		methods: {
			isToday(day) {
				return isToday(day, this.dateNow);
			},
			isOpen(day) {
				return isOpen(day, this.dateNow);
			},
			getFormattedWorkingHours(day) {
				return `${day.start.hh}:${day.start.mm} — ${day.end.hh}:${day.end.mm} CET`;
			},
		},
	};
</script>
