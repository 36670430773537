import { getCurrentSource } from '@/use/utm-source';

export default ({ route, store }) => {
	let params = getCurrentSource(route.query);

	if (!params) {
		params = store.getters.getParams;
	}

	store.dispatch('ad-params/saveQueryParams', params);
};
