var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutsideDropmenu),expression:"onClickOutsideDropmenu"}],class:[
		'nav--desktop-list-item',
		'nav--desktop-list-item--' + _vm.item.name,
		{ 'has-hover': _vm.hasHover },
	],on:{"mouseenter":function($event){return _vm.onHover(true)},"mouseleave":function($event){return _vm.onHover(false)},"touchstart":_vm.onTouchStart}},[_c('div',{staticClass:"nav--desktop-list-item-mask"},[_c('NuxtLink',{class:[
				'nav--desktop-list-item-link',
				'nav--desktop-list-item-link--' + _vm.item.name,
			],style:({ ...(_vm.item.color && { color: _vm.item.color }) }),attrs:{"id":'nav--desktop-list-item-link--' + _vm.item.name,"to":_vm.item.url,"aria-haspopup":_vm.item.dropmenu ? 'true' : 'false',"aria-expanded":_vm.isNavDropmenuOn ? 'true' : 'false',"event":""},domProps:{"textContent":_vm._s(_vm.item.title)},nativeOn:{"click":function($event){return _vm.onClickNavLink($event, true)}}})],1),_vm._v(" "),(_vm.item.sections)?[_c('transition',{attrs:{"name":"nav--desktop-dropmenu-transition"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNavDropmenuOn),expression:"isNavDropmenuOn"}],staticClass:"nav--desktop-dropmenu",attrs:{"aria-labelledby":'nav--desktop-list-v2-item-link--' + _vm.item.name}},[_c('div',{staticClass:"nav--desktop-dropmenu-sections"},_vm._l((_vm.item.sections),function(section){return _c('div',{key:`nav--desktop-dropmenu-section--${section.name}`,class:[
							'nav--desktop-dropmenu-section',
							'nav--desktop-dropmenu-section--' +
								section.name,
						]},[_c('div',{staticClass:"nav--desktop-dropmenu-title",domProps:{"textContent":_vm._s(section.title)}}),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":"","when-idle":""}},[_c('ul',{class:[
									'nav--desktop-dropmenu-list-v2',
									'nav--desktop-dropmenu-list-v2--' +
										section.name,
								]},[_vm._l((section.children),function(sectionItem,index){return _c('li',{key:`nav--desktop-dropmenu-list-v2-item--${index}`,class:'nav--desktop-dropmenu-list-v2-item--' +
										section.name},[_c('NuxtLink',{class:_vm.getSectionItemClass(sectionItem),attrs:{"to":sectionItem.url,"data-label":_vm.getSectionItemLabel(
												sectionItem
											) || false},nativeOn:{"click":function($event){return _vm.onClickNavLink($event, false)}}},[_c('span',{class:[
												'nav--desktop-dropmenu-list-v2-item-title',
												'nav--desktop-dropmenu-list-v2-item-title--' +
													section.name,
											]},[_vm._v(_vm._s(sectionItem.title
													? sectionItem.title
													: sectionItem.name))])]),_vm._v(" "),(sectionItem.children)?_c('ul',{staticClass:"nav--desktop-dropmenu-list-v2-children"},[_vm._l((sectionItem.children),function(subSectionItem,index){return [(
													!subSectionItem.isBold
												)?_c('li',{key:`nav--desktop-dropmenu-list-v2-children--${index}`,staticClass:"nav--desktop-dropmenu-list-v2-children-item"},[_c('NuxtLink',{class:_vm.getSectionItemClass(
															subSectionItem
														),attrs:{"to":subSectionItem.url,"data-label":_vm.getSectionItemLabel(
															subSectionItem
														) || false},nativeOn:{"click":function($event){return _vm.onClickNavLink(
															$event,
															false
														)}}},[_c('span',{staticClass:"nav--desktop-dropmenu-list-v2-children-name"},[_vm._v(_vm._s(subSectionItem.title))])])],1):_vm._e()]})],2):_vm._e()],1)}),_vm._v(" "),_c('li',{staticClass:"nav--desktop-dropmenu-list-v2-item--all"},[_c('NuxtLink',{staticClass:"nav--desktop-dropmenu-list-v2-all",attrs:{"to":_vm.item.url},nativeOn:{"click":function($event){return _vm.onClickNavLink($event, false)}}},[_c('strong',[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(section.name === 'brand'
													? section.viewAllButton
															.title
															.desktopLong
													: section.name !==
													  'size'
													? section.viewAllButton
															.title
															.desktopLong
													: section.viewAllButton
															.title
															.desktopShort)+"\n\t\t\t\t\t\t\t\t\t\t")])])],1)],2)])],1)}),0),_vm._v(" "),(_vm.item.banner)?_c('NuxtLink',{staticClass:"nav--desktop-dropmenu-section nav--desktop-dropmenu--banner has-objectfit",attrs:{"to":_vm.item.banner.url},nativeOn:{"click":function($event){return _vm.onClickNavLink($event, false)}}},[_c('figure',[_c('picture',[_c('source',{attrs:{"data-srcset":_vm.$dataSrc({
										url: _vm.item.banner.image.src,
										isHiRes: true,
										extension: 'webp',
									}),"type":"image/webp"}}),_vm._v(" "),_c('source',{attrs:{"data-srcset":_vm.$dataSrc({
										url: _vm.item.banner.image.src,
										isHiRes: true,
									}),"type":"image/jpeg"}}),_vm._v(" "),_c('LazyImg',{staticClass:"nav--desktop-dropmenu--banner-image is-objectfit",attrs:{"alt":_vm.item.title,"data-src":_vm.$dataSrc({
										url: _vm.item.banner.image.src,
									})}})],1),_vm._v(" "),_c('figcaption',{staticClass:"nav--desktop-dropmenu--banner-caption",domProps:{"innerHTML":_vm._s(_vm.item.banner.caption)}})])]):_vm._e()],1)])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }