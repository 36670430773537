import { actionTree, getterTree, mutationTree } from 'typed-vuex';
import {
	CommonLocalizations,
	LocalizationCountry,
	LocalizationState,
	NavigationItem,
} from '@/types/common';

export const state = () => ({
	localizations: {} as CommonLocalizations,
	methods: {},
	states: [] as LocalizationState[],
	navigation: [] as NavigationItem[],
	infoNavigation: [],
	checkoutNavigation: [],
	infoPage: {},
	sizeGuide: {},
});

export const getters = getterTree(state, {
	GET_COUNTRY_BY_ID:
		(state) =>
		(countryID: number): LocalizationCountry | undefined =>
			state.localizations.country.find((e) => e.id === countryID),
	GET_COUNTRY_BY_ISO_CODE:
		(state) =>
		(isoCode: string): LocalizationCountry | undefined =>
			state.localizations.country.find((e) => e.isoCode === isoCode),
});

export const mutations = mutationTree(state, {
	SET_DATA(state, data) {
		// Collect infoPages instead of overwriting them
		if (Object.prototype.hasOwnProperty.call(data, 'infoPage')) {
			data.infoPage = Object.assign(data.infoPage, state.infoPage);
		}

		state = Object.assign(state, data);
	},
});

export const actions = actionTree(
	{ state, mutations },
	{
		async loadNavigation({ commit }) {
			try {
				const response = await this.$axios.$get('common:navigation');

				commit('SET_DATA', {
					navigation: response.common.navigation,
				});
			} catch (error) {}
		},
		async showInfoPageOverlay(
			{ state, commit, dispatch },
			payload: {
				infoPages: string[];
				overlayType: string | boolean;
				getOverlayData: () => {
					title: string;
					content: string;
				};
			}
		) {
			const infoPagesToFetch: string[] = [];

			payload.infoPages.forEach((infoPageSlug) => {
				if (
					!Object.prototype.hasOwnProperty.call(
						state.infoPage,
						infoPageSlug
					)
				) {
					infoPagesToFetch.push(infoPageSlug);
				}
			});

			if (infoPagesToFetch.length) {
				dispatch(
					'ui/toggleOverlay',
					{
						overlayType: payload.overlayType,
					},
					{ root: true }
				);

				try {
					const response = await this.$axios.$get(
						`common:infoPage=${infoPagesToFetch.join('|')}`
					);

					commit('SET_DATA', {
						infoPage: response.common.infoPage,
					});
				} catch (error) {}
			}

			let overlayType = payload.overlayType;
			let overlayData;

			try {
				overlayData = payload.getOverlayData();
			} catch {
				overlayType = false;
			}

			if (infoPagesToFetch.length) {
				// this.app.$accessor.ui.SET_DATA(null, {
				// 	overlayType,
				// 	overlayData,
				// });
				commit(
					// @ts-ignore
					'ui/SET_DATA',
					{
						overlayType,
						overlayData,
					},
					{ root: true }
				);
			} else {
				// this.app.$accessor.ui.toggleOverlay(null, {
				// 	overlayType,
				// 	overlayData,
				// });
				dispatch(
					'ui/toggleOverlay',
					{
						overlayType,
						overlayData,
					},
					{ root: true }
				);
			}
		},
	}
);
