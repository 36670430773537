
	import Vue from 'vue';
	import NavMobileItem from '@/components/Navigation/Mobile/NavMobileItem.vue';

	import { mapper } from '@/store/helpers/create-mapper';

	export default Vue.extend({
		components: {
			NavMobileItem,
			indexMenu: async () =>
				await import(
					'@/components/Navigation/Mobile/pages/indexMenu.vue'
				),
			categoryMenu: async () =>
				await import(
					'@/components/Navigation/Mobile/pages/categoryMenu.vue'
				),
			helpMenu: async () =>
				await import(
					'@/components/Navigation/Mobile/pages/helpMenu.vue'
				),
			countryMenu: async () =>
				await import(
					'@/components/Navigation/Mobile/pages/countryMenu.vue'
				),
			currencyMenu: async () =>
				await import(
					'@/components/Navigation/Mobile/pages/currencyMenu.vue'
				),
		},
		props: {
			isLight: {
				type: Boolean,
				default: true,
			},
		},
		computed: {
			...mapper('mobileNavigation', ['getLatestHistoryEntry', 'level']),
			component() {
				if (!this.getLatestHistoryEntry.type) {
					return 'indexMenu';
				}

				return `${this.getLatestHistoryEntry.type}Menu`;
			},
		},
		methods: {
			onLeaveTransitionEnds() {
				this.$refs.navRef.scrollIntoView();
			},
			closeNav() {
				if (this.$mq.is('md-up')) {
					this.$accessor.mobileNavigation.TOGGLE_NAV_MOBILE();
					this.$accessor.mobileNavigation.RESET_MENU_HISTORY();
				}
			},
		},
	});
