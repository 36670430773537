function isCategory(route) {
	return route.name === 'all' && !route.path.endsWith('.html');
}

function isProduct(route) {
	return route.path.endsWith('.html');
}

if (process.client) {
	if ('scrollRestoration' in window.history) {
		window.history.scrollRestoration = 'manual';

		// Reset scrollRestoration to auto when leaving page, allowing page reload
		// and back-navigation from other pages to use the browser to restore the
		// scrolling position.
		window.addEventListener('beforeunload', () => {
			window.history.scrollRestoration = 'auto';
		});

		// Set scrollRestoration to manual again when returning to this page.
		window.addEventListener('load', () => {
			window.history.scrollRestoration = 'manual';
		});
	}
}

export default (to, from, savedPosition) => {
	const nuxt = window['$bf'];

	let position = {
		x: 0,
		y: 0,
	};

	if (isCategory(from) && isCategory(to)) {
		position = null;
	} else if (savedPosition) {
		position = savedPosition;
	} else if (isProduct(to) && isProduct(from)) {
		return new Promise((resolve) => {
			nuxt.$root.$once('page-transition-leave', () => {
				resolve(
					setTimeout(() => {
						window.scrollTo({ top: 0, behavior: 'smooth' });
					}, 300)
				);
			});
		});
	}

	return new Promise((resolve) => {
		nuxt.$root.$once('page-transition-leave', () => {
			nuxt.$nextTick(() => {
				resolve(position);
			});
		});
	});
};
