export default {
	computed: {
		methods() {
			return this.$store.state.common.methods;
		},
		localizationProps() {
			return this.$store.getters['user/GET_LOCALIZATION_PROPS'].filter(
				(p) => p !== 'language'
			);
		},
		language() {
			return this.$store.state.user.localization.language;
		},
		infoNavigation() {
			return this.$store.state.common.infoNavigation;
		},
		infoContact() {
			return this.$store.state.common.infoContact;
		},
		infoPageUrlPrefix() {
			return '/info';
		},
		contactPhoneLink() {
			return this.infoContact.phone
				? `tel:${this.infoContact.phone.replace(/\s/g, '')}`
				: '';
		},
		contactPhoneUsLink() {
			return this.infoContact.phone_us
				? `tel:${this.infoContact.phone_us.replace(/\s/g, '')}`
				: '';
		},
		contactNetworks() {
			const networks = this.infoContact.networks
				? [...this.infoContact.networks]
				: [];

			return networks;
		},
	},
};
