export default ({ app, store }, inject) => {
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/* Set reusable constants
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	const getUtsNow = function () {
		return store.state.ui.utsNow;
	};
	const getUserEmail = function () {
		return store.state.user.personalInformation.email;
	};
	const getUserCountry = function () {
		return store.state.user.localization.country;
	};
	const getProductSumQuantity = function (products) {
		return products.length
			? products
					.map((product) => product.variation.quantity)
					.reduce((accumulator, quantity) => accumulator + quantity)
			: 0;
	};
	const getFbProductIds = function (products) {
		return products.length
			? products.map((product) => {
					return `BFUS${product.id}`;
			  })
			: [];
	};
	const getFbProducts = function (products) {
		return products.length
			? products.map((product) => {
					return {
						id: `BFUS${product.id}`,
						quantity: product.variation.quantity,
						item_price: product.variation.unitPriceEuroReducedRaw,
					};
			  })
			: [];
	};
	const getKlaviyoProducts = function (products) {
		return products.length
			? products.map((product) => {
					return {
						ProductID: `P${product.id}`,
						SKU: `V${product.variation.id}`,
						ProductName: product.title,
						Quantity: product.variation.quantity,
						ItemPrice: product.variation.unitPriceEuroReducedRaw,
						RowTotal: product.variation.priceEuroReducedRaw,
						ProductURL: process.env.HOST_URL + product.url,
						ImageURL:
							product.imageUrls[0] + '--product-card@2x.jpg',
						ProductCategories: product.categories,
						discount: product.priceDiscount,
					};
			  })
			: [];
	};
	const getKlaviyoProductNames = function (products) {
		return products.length ? products.map((product) => product.title) : [];
	};
	const getKlaviyoProductCategories = function (products) {
		let categories = [];

		products.forEach((product) => {
			if (!product.categories) {
				return;
			}

			categories = categories.concat(
				product.categories.filter((category) => {
					return !categories.includes(category);
				})
			);
		});

		return categories;
	};
	const setGtagEnchancedConversionData = function ({
		order,
		personalInformation,
	}) {
		const address = order.address.shipping.fields;

		window.enhanced_conversion_data = {
			email: personalInformation.email,
			phone_number: address.phone,
			first_name: address.fname,
			last_name: address.lname,
			home_address: {
				street: `${address.address1}${
					address.address2 ? ', ' + address.address2 : ''
				}`,
				city: address.city,
				region: address.state,
				postal_code: address.zip,
				country: address.country,
			},
		};
	};

	const feedCountries = [
		'DE',
		'US',
		'CH',
		'AR',
		'SK',
		'AU',
		'GB',
		'AT',
		'DE',
		'HK',
		'IL',
		'CA',
		'NL',
		'NZ',
		'NO',
		'PH',
		'PR',
		'BR',
		'ZA',
		'TW',
		'BE',
		'CZ',
		'ID',
		'IE',
		'SA',
		'SE',
		'KR',
		'AE',
		'MY',
		'SG',
		'KH',
		'FR',
		'JP',
		'ES',
		'IT',
		'PL',
		'PT',
		'TH',
		'MX',
		'UA',
		'RO',
		'GR',
		'KW',
		'TR',
		'HU',
		'FI',
		'DK',
		'OM',
		'BH',
	];

	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/* Execute once
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/

	const track = async (key, params = {}) => {
		if (!process.browser) {
			return;
		}

		switch (key) {
			case 'layout':
				// app.$bing.load();
				app.$rakuten.load();
				app.$ym.load();
				app.$rakuten.load();

				app.$smartlook.load();
				app.$smartlook.identify();

				app.$klaviyo.load();
				app.$klaviyo.identify();
				app.$gtag.identify();
				break;

			case 'navigation':
				app.$gtag.trackPageView({ to: params.to });

				// app.$bing.event('page_view', {
				// 	event_category: 'New page view',
				// 	event_label: params.to.path,
				// });

				app.$ym.hit({ from: params.from, to: params.to });
				break;

			case 'banner-link':
				app.$gtag.eventGA('banner_click', {
					event_category: 'Performance Trap',
					event_label: `${params.caption.heading.text} — ${params.linkUrl}`,
				});

				app.$ym.reachGoal({ goalId: 'banner-link' });
				break;

			case 'banner-more':
				app.$gtag.eventGA('banner_click', {
					event_category: 'Performance Trap',
					event_label: `${
						params.isDescriptionExpanded ? 'Expand' : 'Collapse'
					} description`,
				});

				app.$ym.reachGoal({ goalId: 'banner-more' });
				break;

			case 'featured-category':
				app.$gtag.eventGA('featured_category_click', {
					event_category: 'Performance Trap',
					event_label: `${params.category.title} — ${params.category.linkUrl}`,
				});

				app.$ym.reachGoal({ goalId: 'featured-category' });
				break;

			case 'quadro':
				app.$gtag.eventGA('quadro_click', {
					event_category: 'Performance Trap',
					event_label: `${params.category.title} — ${params.category.linkUrl}`,
				});

				app.$ym.reachGoal({ goalId: 'quadro-click' });
				break;

			case 'nav-desktop':
				app.$gtag.eventGA('desktop_navigation_click', {
					event_category: 'Performance Trap',
					event_label: `${params.element.innerText} — ${params.element.pathname}`,
				});

				break;

			case 'nav-mobile':
				app.$gtag.eventGA('mobile_navigation_click', {
					event_category: 'Performance Trap',
					event_label: `${params.element.innerText} — ${params.element.pathname}`,
				});

				break;

			case 'filter':
				app.$gtag.eventGA('filter_click', {
					event_category: 'Performance Trap',
					event_label: `${params.element.innerText} — ${params.element.pathname}`,
				});

				app.$ym.reachGoal({ goalId: 'filter-click' });
				break;

			case 'category':
				await app.$trace('trap', {
					action: 'category-view',
					label: params.path,
				});

				if (params.hasQuickfilter) {
					app.$gtag.eventGA('quickfilter', {
						event_category: 'Performance Trap',
						event_label: 'Impression',
					});
				}

				app.$gtag.viewItemList({ category: params.category });

				break;

			case 'category-position-top-click':
				app.$gtag.eventGA('click_position_top', {
					event_category: 'Performance Trap',
					event_label: `${params.positionTop}:${params.product.id}`,
				});

				break;

			case 'category-position-top-view':
				app.$fb.track('ViewContent', {
					content_type: 'product',
					content_ids: [`BFUS${params.product.id}`],
					content_name: params.product.title,
					content_category: params.product.brand.title,
					value: params.product.priceEuroReducedRaw,
					currency: 'EUR',
				});
				break;

			case 'category-infinite-scroll':
				app.$gtag.eventGA('infinite_scroll', {
					event_category: 'Performance Trap',
					event_label: `${params.page}`,
				});

				break;

			case 'magic-link-suggested':
				app.$gtag.eventGA('suggest_magic_link', {
					event_category: 'Performance Trap',
					event_label: params.email,
				});

				app.$smartlook.identify(params.email);
				app.$klaviyo.identify(params.email);
				app.$gtag.identify(params.email);

				app.$ym.reachGoal({ goalId: 'magic-link-suggested' });
				break;

			case 'magic-link-sent':
				app.$gtag.eventGA('send_magic_link', {
					event_category: 'Performance Trap',
					event_label: params.email,
				});

				app.$ym.reachGoal({ goalId: 'magic-link-sent' });
				break;

			case 'magic-link-sign-in':
				app.$klaviyo.identify(params.email);
				app.$gtag.identify(params.email);

				app.$gtag.eventGA('login', {
					method: `Magic Link for ${params.email}`,
				});
				break;

			case 'landing':
				await app.$trace('trap', {
					action: 'landing-view',
				});

				// app.$gtag.eventAds('page_view', {
				// 	ecomm_pagetype: 'home',
				// });
				break;

			case 'sign-in':
				app.$gtag.eventGA('login', {
					method: 'Sign in form',
				});

				app.$smartlook.identify(params.email);
				app.$klaviyo.identify(params.email);
				app.$gtag.identify(params.email);
				break;

			case 'sign-out':
				app.$smartlook.anonymize();
				break;

			case 'register':
				app.$gtag.eventGA('sign_up', {
					method: params.method,
				});
				app.$ym.reachGoal({ goalId: 'register' });
				break;

			case 'search':
				const { url, title } = params.item;

				await app.$trace('trap', {
					action: 'search',
					label: `${url}, ${title}`,
				});
				app.$gtag.eventGA('search', {
					search_term: params.item.title,
				});

				// app.$bing.event('search', {
				// 	event_category: 'Funnel',
				// 	event_label: `Searched for ${params.item.title} — ${params.item.url}`,
				// });

				app.$ym.reachGoal({ goalId: 'search' });
				break;

			case 'newsletter-sign-up':
				await app.$trace('funnel', {
					action: `newsletter-subscribed-${params.context}`,
					label: params.path,
					value: params.email,
				});

				app.$gtag.eventGA('subscribe_newsletter', {
					event_category: 'Funnel',
				});

				app.$smartlook.identify(params.email);
				app.$klaviyo.identify(params.email);
				app.$gtag.identify(params.email);

				app.$ym.reachGoal({ goalId: 'newsletter-sign-up' });
				break;

			case 'voucher-added':
				await app.$trace('trap', {
					action: 'voucher-added',
					label: params.code,
				});

				app.$gtag.eventGA('add_voucher', {
					event_category: 'Performance Trap',
					event_label: `Add Voucher '${params.code}' on ${app.router.history.current.fullPath} — ${params.message}`,
				});

				app.$ym.reachGoal({ goalId: 'voucher-added' });
				break;

			case 'voucher-removed':
				await app.$trace('trap', {
					action: 'voucher-removed',
					label: params.code,
				});

				app.$gtag.eventGA('remove_voucher', {
					event_category: 'Performance Trap',
					event_label: `Remove Voucher '${params.code}' on ${app.router.history.current.fullPath} — ${params.message}`,
				});

				app.$ym.reachGoal({ goalId: 'voucher-removed' });
				break;

			case 'information-popup':
				await app.$trace('trap', {
					action: 'information-popup',
					label: params.infoPageSlug,
				});

				app.$gtag.eventGA('information_popup', {
					event_category: 'Performance Trap',
					event_label: `Show "${params.infoPageSlug}" on ${app.router.history.current.fullPath}`,
				});

				app.$ym.reachGoal({ goalId: 'information-popup' });
				break;

			case 'account':
				await app.$trace('trap', {
					action: 'account-view',
					label: app.router.history.current.fullPath,
				});
				break;

			case 'product-imp-click':
				app.$gtag.eventGA('select_content', {
					content_type: 'product',
					items: [{ id: params.product.id }],
				});

				break;

			case 'product-imp-view':
				break;

			case 'product-imp-addet-to-cart':
				break;

			case 'product':
				await app.$trace('funnel', {
					action: 'product-view',
					label: `${params.product.id}`,
					value: params.product.priceEuroReducedRaw,
				});

				app.$gtag.viewItem({ product: params.product });

				app.$gtag.eventAds('page_view', {
					send_to: 'AW-1014284038',
					value: params.product.priceEuroReducedRaw,
					items: [
						{
							id: params.product.id,
							google_business_vertical: 'retail',
						},
					],
				});

				app.$gtag.eventAds('page_view', {
					send_to: 'AW-16518871757',
					value: params.product.priceEuroReducedRaw,
					items: [
						{
							id: params.product.id,
							google_business_vertical: 'retail',
						},
					],
				});

				if (params.email) {
					app.$klaviyo.identify(params.email);
					app.$gtag.identify(params.email);
				}

				app.$klaviyo.track('Viewed Product', {
					ProductName: params.product.title,
					ProductID: `P${params.product.id}`,
					Categories: params.product.categories,
					ImageURL:
						params.product.imageUrls[0] + '--product-card@2x.jpg',
					URL:
						process.env.HOST_URL +
						app.router.history.current.fullPath,
					Brand: params.product.brand.title,
					Price: params.product.priceEuroReducedRaw,
					CompareAtPrice: params.product.priceEuroRegularRaw,
				});

				app.$klaviyo.track('trackViewedItem', {
					Title: params.product.title,
					ItemId: `P${params.product.id}`,
					// Categories: params.product.categories,
					ImageURL:
						params.product.imageUrls[0] + '--product-card@2x.jpg',
					Url:
						process.env.HOST_URL +
						app.router.history.current.fullPath,
					Metadata: {
						Brand: params.product.brand.title,
						Price: params.product.priceEuroReducedRaw,
						CompareAtPrice: params.product.priceEuroRegularRaw,
					},
				});

				// app.$bing.event('view_item', {
				// 	event_category: `Single Product #${params.product.id} Page`,
				// 	event_label: params.product.id,
				// 	event_value: `${params.product.priceEuroReducedRaw}`,
				// });

				app.$fb.track('ViewContent', {
					content_type: 'product',
					content_ids: [`BFUS${params.product.id}`],
					content_name: params.product.title,
					content_category: params.product.brand.title,
					value: params.product.priceEuroReducedRaw,
					currency: 'EUR',
				});

				app.$ym.reachGoal({ goalId: 'product-view' });
				app.$ym.ecommerce({
					action: 'detail',
					products: [params.product],
				});
				break;

			case 'product-size-select-opened':
				await app.$trace('trap', {
					action: 'product-size-select-opened',
					label: `${params.product.id}`,
					value: params.product.priceEuroReducedRaw,
				});
				break;

			case 'product-size-selected':
				app.$gtag.selectItem({
					product: params.product,
					variation: params.variation,
				});
				break;

			case 'product-size-requested':
				await app.$trace('funnel', {
					action: 'product-size-requested',
					label: `${params.variationId}`,
				});

				app.$gtag.eventGA('product_size_request', {
					event_category: 'Funnel',
					event_label: `${params.variationId}`,
				});

				app.$smartlook.identify(params.email);
				app.$klaviyo.identify(params.email);
				app.$gtag.identify(params.email);

				break;

			case 'wishlist':
				await app.$trace('trap', {
					action: 'wishlist-view',
				});
				break;

			case 'added-to-wishlist':
				await app.$trace('funnel', {
					action: 'wishlist-added',
					label: `${params.product.id}`,
					value: params.product.priceEuroReducedRaw,
				});

				app.$gtag.addToWishlist({ product: params.product });

				app.$gtag.eventAds('conversion', {
					send_to: 'AW-1014284038/1hnTCP2XnvUYEIb-0uMD',
					id: params.product.id,
					value: params.product.priceEuroReducedRaw,
					currency: 'EUR',
				});

				app.$gtag.eventAds('conversion', {
					send_to: 'AW-16518871757/CqJeCM_bjasZEM3158Q9',
					id: params.product.id,
					value: params.product.priceEuroReducedRaw,
					currency: 'EUR',
				});

				break;

			case 'removed-from-wishlist':
				await app.$trace('funnel', {
					action: 'wishlist-removed',
					label: `${params.product.id}`,
					value: params.product.priceEuroReducedRaw,
				});
				break;

			case 'adding-to-cart':
				app.$smartlook.trigger();

				break;

			case 'added-to-cart':
				await app.$trace('funnel', {
					action: 'cart-added',
					label: `${params.product.id}`,
					value: params.product.variation.priceEuroReducedRaw,
				});

				app.$gtag.addToCart({
					product: params.product,
				});

				app.$gtag.eventAds('add_to_cart', {
					send_to: 'AW-1014284038',
					value: params.product.priceEuroReducedRaw,
					items: [
						{
							id: params.product.id,
							google_business_vertical: 'retail',
						},
					],
				});

				app.$gtag.eventAds('conversion', {
					id: params.product.id,
					value: params.product.variation.priceEuroReducedRaw,
					currency: 'EUR',
					send_to: 'AW-11354243323/H81hCJac0OkYEPuBkKYq',
				});

				app.$gtag.eventAds('conversion', {
					id: params.product.id,
					value: params.product.variation.priceEuroReducedRaw,
					currency: 'EUR',
					send_to: 'AW-1014284038/TWfvCNjY9vQYEIb-0uMD',
				});

				app.$gtag.eventAds('conversion', {
					id: params.product.id,
					value: params.product.variation.priceEuroReducedRaw,
					currency: 'EUR',
					send_to: 'AW-16518871757/D8JHCMbbjasZEM3158Q9',
				});

				app.$klaviyo.track('Added to Cart', {
					$value: params.product.variation.priceEuroReducedRaw,
					AddedItemProductName: params.product.title,
					AddedItemProductID: `P${params.product.id}`,
					AddedItemSKU: `V${params.product.variation.id}`,
					AddedItemCategories: params.product.categories,
					AddedItemImageURL: params.product.imageUrls[0],
					AddedItemURL:
						process.env.HOST_URL +
						app.router.history.current.fullPath,
					AddedItemPrice:
						params.product.variation.priceEuroReducedRaw,
					AddedItemQuantity: 1,
					ItemNames: getKlaviyoProductNames(params.cart.products),
					Items: getKlaviyoProducts(params.cart.products),
					CheckoutURL: '/shopping-bag',
					discount: params.product.variation.priceDiscount,
				});

				// app.$bing.event('add_to_cart', {
				// 	event_category: 'Product Added to Cart',
				// 	event_label: params.product.id,
				// 	event_value: `${params.product.variation.priceEuroReducedRaw}`,
				// });

				app.$fb.track('AddToCart', {
					content_type: 'product',
					content_ids: [`BFUS${params.product.id}`],
					content_name: `Product ${params.product.id} Added to Cart`,
					content_category: params.product.brand,
					value: params.product.variation.priceEuroReducedRaw,
					currency: 'EUR',
				});

				app.$ym.reachGoal({
					goalId: 'added-to-cart',
					price: params.product.variation.priceEuroReducedRaw,
				});

				app.$ym.addToCart(params);

				app.$ym.ecommerce({
					action: 'add',
					products: [params.product],
				});
				break;

			case 'adjusting-cart-quantity':
				await app.$trace('funnel', {
					action: 'cart-quantity-adjusting',
					label: params.product.id,
					value: params.quantity,
				});
				break;

			case 'adjusted-cart-quantity':
				await app.$trace('funnel', {
					action: 'cart-quantity-adjusted',
					label: params.product.id,
					value: params.quantity,
				});
				break;

			case 'removed-from-cart':
				app.$gtag.removeFromCart({
					product: params.product,
				});

				break;

			case 'cart':
				await app.$trace('funnel', {
					action: 'cart-view',
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.eventGA('set_checkout_option', {
					checkout_step: 0,
					checkout_option: 'Shopping Bag',
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.viewCart({ cart: params.cart });

				app.$klaviyo.track('Started Checkout', {
					$event_id: `${params.cart.id}_${getUtsNow()}`,
					$value: params.cart.total.amounts.totalEuroRaw,
					Categories: getKlaviyoProductCategories(
						params.cart.products
					),
					Items: getKlaviyoProducts(params.cart.products),
					ItemNames: getKlaviyoProductNames(params.cart.products),
					CheckoutURL: '/shopping-bag',
				});

				app.$fb.track('InitiateCheckout', {
					contents: getFbProducts(params.cart.products),
					content_ids: getFbProductIds(params.cart.products),
					num_items: getProductSumQuantity(params.cart.products),
					content_type: 'product_group',
					value: params.cart.total.amounts.totalEuroRaw,
					currency: 'EUR',
				});

				app.$ym.viewCart(params);

				app.$gtag.eventAds('conversion', {
					value: params.cart.total.amounts.totalEuroRaw,
					currency: 'EUR',
					send_to: 'AW-1014284038/kWkqCO_C9fQYEIb-0uMD',
				});

				app.$gtag.eventAds('conversion', {
					value: params.cart.total.amounts.totalEuroRaw,
					currency: 'EUR',
					send_to: 'AW-16518871757/XQySCMzbjasZEM3158Q9',
				});
				break;

			case 'apple-pay-view':
				await app.$trace('funnel', {
					action: 'apple-pay-view',
					label: params.path,
					value: params.cart.total.amounts.totalEuroRaw,
				});
				break;

			case 'apple-pay-click':
				await app.$trace('funnel', {
					action: 'apple-pay-click',
					label: params.path,
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.eventGA('set_checkout_option', {
					checkout_step: 1,
					checkout_option: 'Login with Apple Pay',
					value: params.cart.total.amounts.totalEuroRaw,
				});

				break;

			case 'apple-pay-cancel':
				await app.$trace('funnel', {
					action: 'apple-pay-cancel',
					label: params.path,
					value: params.cart.total.amounts.totalEuroRaw,
				});
				break;

			case 'apple-pay-change-shipping-option':
				await app.$trace('funnel', {
					action: 'apple-pay-change-shipping',
					label: `${
						params.path
					} — ${params.shippingOption.label.toLowerCase()}`,
					value: params.cart.total.amounts.totalEuroRaw,
				});
				break;

			case 'apple-pay-payment-error':
				await app.$trace('funnel', {
					action: 'apple-pay-payment-error',
					label: `${params.path} — ${params.message}`,
					value: params.cart.total.amounts.totalEuroRaw,
				});
				break;

			case 'apple-pay-payment-attempt':
				await app.$trace('funnel', {
					action: 'apple-pay-payment-attempt',
					label: app.$case(params.selectedMethodName, 'pascal'),
					value: params.cart.total.amounts.totalEuroRaw,
				});

				break;

			case 'apple-pay-payment-success':
				await app.$trace('funnel', {
					action: 'apple-pay-payment-success',
					label: params.path,
					value: params.cart.total.amounts.totalEuroRaw,
				});
				break;

			case 'checkout-start':
				await app.$trace('funnel', {
					action: 'checkout-start',
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.beginCheckout({
					cart: params.cart,
				});

				app.$gtag.eventAds('conversion', {
					value: params.cart.total.amounts.totalEuroRaw,
					currency: 'EUR',
					send_to: 'AW-11354243323/E1RGCKyd0OkYEPuBkKYq',
				});

				app.$gtag.eventAds('conversion', {
					value: params.cart.total.amounts.totalEuroRaw,
					currency: 'EUR',
					send_to: 'AW-16518871757/8u4lCMnbjasZEM3158Q9',
				});

				break;

			case 'checkout-lead':
				await app.$trace('funnel', {
					action: 'checkout-lead',
					label: getUserEmail(),
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.eventGA('set_checkout_option', {
					checkout_step: 1,
					checkout_option: 'Lead',
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.eventGA('generate_lead', {
					currency: 'EUR',
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$smartlook.identify(params.email);
				app.$klaviyo.identify(params.email);
				app.$gtag.identify(params.email);

				app.$fb.track('Lead', {
					value: params.cart.total.amounts.totalEuroRaw,
					currency: 'EUR',
				});

				break;

			case 'checkout-address':
				await app.$trace('funnel', {
					action: `checkout-address-${app.$case(params.action)}`,
					label: app.$case(params.section, 'pascal'),
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.eventGA('set_checkout_option', {
					checkout_step: 2,
					checkout_option: `${app.$case(params.action, 'pascal')} ${
						params.section
					} address`,
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.eventGA(`${params.action}_address`, {
					event_category: 'Funnel',
					event_label: app.$case(params.section, 'pascal'),
				});

				// app.$bing.event('checkout_progress', {
				// 	event_category: `${app.$case(params.action, 'pascal')} ${
				// 		params.section
				// 	} address`,
				// 	event_label: getUserEmail(),
				// 	event_value: params.cart.total.amounts.totalEuroRaw,
				// });

				app.$fb.track('CompleteRegistration', {
					content_name: `Address — ${app.$case(
						params.action,
						'pascal'
					)} ${app.$case(params.section, 'pascal')}`,
					value: params.cart.total.amounts.totalEuroRaw,
					currency: 'EUR',
				});

				break;

			case 'checkout-shipping-view':
				await app.$trace('funnel', {
					action: 'checkout-shipping-view',
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.eventGA('checkout_shipping_view', {
					label: params.label,
					value: params.cart.total.amounts.totalEuroRaw,
				});

				break;

			case 'checkout-shipping-change':
				await app.$trace('funnel', {
					action: 'checkout-shipping-change',
					label: app.$case(params.selectedMethodName, 'pascal'),
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.eventGA('change_shipping_method', {
					event_category: 'Funnel',
					event_action: app.$case(
						params.selectedMethodName,
						'pascal'
					),
				});

				// app.$bing.event('set_checkout_option', {
				// 	event_category: `Shipping Method Changed to ${app.$case(
				// 		params.selectedMethodName,
				// 		'pascal'
				// 	)}`,
				// 	event_label: getUserEmail(),
				// 	event_value: params.cart.total.amounts.totalEuroRaw,
				// });

				break;

			case 'checkout-shipping-confirm':
				app.$gtag.eventGA('set_checkout_option', {
					checkout_step: 3,
					checkout_option: `Set shipping method to ${app.$case(
						params.selectedMethodName,
						'pascal'
					)}`,
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.addMethodInfo({
					cart: params.cart,
					method: app.$case(params.selectedMethodName, 'pascal'),
					type: 'shipping',
				});

				app.$klaviyo.track('Started Checkout', {
					$event_id: `${params.cart.id}_${getUtsNow()}`,
					$value: params.cart.total.amounts.totalEuroRaw,
					Categories: getKlaviyoProductCategories(
						params.cart.products
					),
					Items: getKlaviyoProducts(params.cart.products),
					ItemNames: getKlaviyoProductNames(params.cart.products),
					CheckoutURL: '/checkout/shipping',
				});
				break;

			case 'checkout-payment-page':
				await app.$trace('funnel', {
					action: 'checkout-payment-view',
					label: getUserEmail(),
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.eventGA('checkout_payment_view', {
					value: params.cart.total.amounts.totalEuroRaw,
				});

				// app.$bing.event('', {
				// 	event_category: 'Payment Page',
				// 	event_label: getUserEmail(),
				// 	event_value: params.cart.total.amounts.totalEuroRaw,
				// });
				break;

			case 'checkout-payment-attempt':
				await app.$trace('funnel', {
					action: 'checkout-payment-attempt',
					label: app.$case(params.selectedMethodName, 'pascal'),
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.eventGA('checkout_payment_attempt', {
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.eventGA('set_checkout_option', {
					checkout_step: 4,
					checkout_option: `Attempt payment with ${app.$case(
						params.selectedMethodName,
						'pascal'
					)}`,
					value: params.cart.total.amounts.totalEuroRaw,
				});

				app.$gtag.addMethodInfo({
					cart: params.cart,
					method: app.$case(params.selectedMethodName, 'pascal'),
					type: 'payment',
				});

				// app.$bing.event('add_payment_info', {
				// 	event_category: `Payment Attempted with ${app.$case(
				// 		params.selectedMethodName,
				// 		'pascal'
				// 	)}`,
				// 	event_label: getUserEmail(),
				// 	event_value: params.cart.total.amounts.totalEuroRaw,
				// });

				app.$fb.track('AddPaymentInfo', {
					content_name: `Payment Attempted with ${app.$case(
						params.selectedMethodName,
						'pascal'
					)}`,
					value: params.cart.total.amounts.totalEuroRaw,
					currency: 'EUR',
				});

				break;

			case 'checkout-payment-canceled':
				await app.$trace('funnel', {
					action: 'checkout-payment-canceled',
					label: app.$case(params.selectedMethodName, 'pascal'),
					value: params.cart.total.amounts.totalEuroRaw,
				});

				break;

			case 'checkout-payment-error':
				await app.$trace('funnel', {
					action: 'checkout-payment-attempt-error',
					label: params.message,
					value: params.cart.total.amounts.totalEuroRaw,
				});

				// app.$bing.event('add_payment_info', {
				// 	event_category: `Payment Failed — ${params.message}`,
				// 	event_label: getUserEmail(),
				// 	event_value: params.cart.total.amounts.totalEuroRaw,
				// });

				app.$fb.track('AddPaymentInfo', {
					content_name: `Payment Failed — ${params.message}`,
					value: params.cart.total.amounts.totalEuroRaw,
					currency: 'EUR',
				});

				break;

			case 'saved-card-add':
				await app.$trace('trap', {
					action: 'saved-card-add',
				});

				app.$gtag.eventGA('add_saved_card', {
					event_category: 'Performance Trap',
				});

				break;

			case 'saved-card-view':
				await app.$trace('trap', {
					action: 'saved-card-view',
				});

				app.$gtag.eventGA('view_saved_card', {
					event_category: 'Performance Trap',
				});

				break;

			case 'saved-card-use':
				await app.$trace('trap', {
					action: 'saved-card-use',
				});

				app.$gtag.eventGA('use_saved_card', {
					event_category: 'Performance Trap',
				});

				break;

			case 'saved-card-remove':
				await app.$trace('trap', {
					action: 'saved-card-remove',
				});

				app.$gtag.eventGA('remove_saved_card', {
					event_category: 'Performance Trap',
				});

				break;

			case 'checkout-success':
				await app.$trace('funnel', {
					action: 'purchase',
					label: params.order.summary.reference,
					value: params.order.summary.total.amounts.totalEuroRaw,
				});

				setGtagEnchancedConversionData(params);
				app.$gtag.purchase({
					order: params.order,
					personalInformation: params.personalInformation,
				});

				app.$gtag.eventAds('purchase', {
					send_to: 'AW-1014284038',
					value: params.order.summary.total.amounts.totalEuroRaw,
					items: params.order.products.map((product) => ({
						...product,
						google_business_vertical: 'retail',
					})),
				});

				app.$gtag.eventAds('conversion', {
					transaction_id: params.order.summary.reference,
					value: params.order.summary.total.amounts.totalEuroRaw,
					currency: 'EUR',
					send_to: 'AW-11354243323/e6HbCJCc0OkYEPuBkKYq',
				});

				app.$gtag.eventAds('conversion', {
					transaction_id: params.order.summary.reference,
					value: params.order.summary.total.amounts.totalEuroRaw,
					currency: 'EUR',
					send_to: 'AW-1014284038/8HJOCMGvv4kYEIb-0uMD',
				});

				app.$gtag.eventAds('conversion', {
					transaction_id: params.order.summary.reference,
					value: params.order.summary.total.amounts.totalEuroRaw,
					currency: 'EUR',
					send_to: 'AW-16518871757/wHHKCJrepqoZEM3158Q9',
				});

				app.$rakuten.conversion(params.order);

				app.$fb.track('Purchase', {
					content_name: `Purchase — Order #${params.order.summary.reference}`,
					contents: getFbProducts(params.order.products),
					content_ids: getFbProductIds(params.order.products),
					num_items: getProductSumQuantity(params.order.products),
					value: params.order.summary.total.amounts.totalEuroRaw,
					currency: 'EUR',
				});

				app.$ym.reachGoal({
					goalId: 'purchase',
					price: params.order.summary.total.amounts.totalEuroRaw,
				});
				app.$ym.ecommerce({
					action: 'purchase',
					products: params.order.products,
					order: params.order,
				});
				break;

			case 'ddp-shipping-change':
				await app.$trace('funnel', {
					action: 'ddp-shipping-change',
					label: params,
				});
				break;
			case 'popup-newsletter-shown':
				await app.$trace('popup-newsletter-shown', {
					action: 'popup-newsletter-shown',
					label: params.id,
				});

				break;

			case 'popup-picture-shown':
				await app.$trace('popup-picture-shown', {
					action: 'popup-picture-shown',
					label: params.id,
				});

				break;

			case 'country-select':
				await app.$trace('trap', {
					action: 'country-select',
					label: `${params.old} - ${params.new}`,
				});

				break;
			case 'currency-select':
				await app.$trace('trap', {
					action: 'currency-select',
					label: `${params.old} - ${params.new}`,
				});

				break;
			case 'app-popup-view': {
				await app.$trace('trap', {
					action: 'app-popup-view',
				});
				break;
			}
			case 'app-popup-click': {
				await app.$trace('trap', {
					action: 'app-popup-click',
					label: params.source,
					value: params.device,
				});
				break;
			}
			case 'subscribe-popup': {
				await app.$trace('trap', {
					action: 'subscribe-popup',
					label: params.action,
				});
				break;
			}
			case 'iosBannerBottom':
				await app.$trace('trap', {
					action: 'ios-banner-bottom',
					label: params,
				});

				break;
			case 'iosBannerFooter':
				await app.$trace('trap', {
					action: 'ios-banner-footer',
					label: params,
				});

				break;

			case 'catalog-product-swipe':
				await app.$trace('trap', {
					action: params.action,
					label: params.label,
				});
				break;

			case 'continue-shopping':
				await app.$trace('trap', {
					action: 'continue-shopping-click',
					label: params,
				});
				break;

			case 'filter-open':
				await app.$trace('trap', {
					action: 'filter-open',
					label: params,
				});
				break;

			case 'quickfilter-filter-open':
				await app.$trace('trap', {
					action: 'quickfilter-filter-open',
					label: params,
				});
				break;

			case 'filter-close':
				await app.$trace('trap', {
					action: 'filter-close',
					label: params,
				});
				break;

			case 'filter-section-open':
				await app.$trace('trap', {
					action: 'filter-section-open',
					label: params,
				});
				break;
			case 'filter-section-close':
				await app.$trace('trap', {
					action: 'filter-section-close',
					label: params,
				});
				break;

			case 'filter-navigate-down':
				await app.$trace('trap', {
					action: 'filter-navigate-down',
					label: params,
				});
				break;

			case 'filter-navigate-up':
				await app.$trace('trap', {
					action: 'filter-navigate-up',
					label: params,
				});
				break;

			case 'filter-selected':
				await app.$trace('trap', {
					action: 'filter-selected',
					label: params,
				});
				break;

			case 'filter-unselected':
				await app.$trace('trap', {
					action: 'filter-unselected',
					label: params,
				});
				break;

			case 'social-login-google-attempt':
				await app.$trace('trap', {
					action: 'social-login-google-attempt',
					label: params,
				});
				break;

			case 'social-login-google-failure':
				await app.$trace('trap', {
					action: 'social-login-google-failure',
					label: params,
				});
				break;

			case 'social-login-google-success':
				await app.$trace('trap', {
					action: 'social-login-google-success',
					label: params,
				});
				break;

			case 'social-login-apple-attempt':
				await app.$trace('trap', {
					action: 'social-login-apple-attempt',
					label: params,
				});
				break;

			case 'social-login-apple-failure':
				await app.$trace('trap', {
					action: 'social-login-apple-failure',
					label: params,
				});
				break;

			case 'social-login-apple-success':
				await app.$trace('trap', {
					action: 'social-login-apple-success',
					label: params,
				});
				break;

			case 'social-login-facebook-attempt':
				await app.$trace('trap', {
					action: 'social-login-facebook-attempt',
					label: params,
				});
				break;

			case 'social-login-facebook-failure':
				await app.$trace('trap', {
					action: 'social-login-facebook-failure',
					label: params,
				});
				break;

			case 'social-login-facebook-success':
				await app.$trace('trap', {
					action: 'social-login-facebook-success',
					label: params,
				});
				break;

			case 'quickfilter-selected':
				await app.$trace('trap', {
					action: 'quickfilter-selected',
					label: params,
				});
				break;

			case 'scroll-to-top':
				await app.$trace('trap', {
					action: 'scroll-to-top',
					label: params,
				});
				break;

			case 'get-app-button':
				await app.$trace('trap', {
					action: `get-app-${params.utm}`,
					label: params.device,
				});
				break;

			case 'voucher-suggestion-view':
				await app.$trace('funnel', {
					action: 'voucher-suggestion-view',
					label: params.path,
					value: params.cart.total.amounts.totalEuroRaw,
				});
				break;

			case 'voucher-suggestion-apply':
				await app.$trace('funnel', {
					action: 'voucher-suggestion-apply',
					label: params.path,
					value: params.cart.total.amounts.totalEuroRaw,
				});
				break;
			default:
				console.warn('$track unimplemented action:', key, params);
		}
	};

	inject('track', track);

	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/* Execute upon confirmed navigation
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/
	/******************************************************************************************/

	app.router.afterEach(async (to, from) => {
		if (from.name === null && process.browser) {
			await store.dispatch('user/getPersonalInfo');

			app.$track('layout');
		}

		app.$track('navigation', {
			from,
			to,
		});
	});
};
