import cookie from 'cookie';
import { getRandom, getSalted } from '@/plugins/hash.js';
import { parseForCookie } from '@/plugins/delta-imp.ts';
import { getClientInfo } from '@/modules/rm-trace/get-client-info';

export default async function ({ app, store, req }, inject) {
	const trace = async function (category, params) {
		const hash = store.state.user.hash;
		const cookies = JSON.stringify({
			deltaImp: parseForCookie(store) || '',
		});

		const conversion = getConversions();

		const client = getClientInfo();

		if ('false' === 'true') {
			console.log(
				'[trace-module]',
				`(${
					'false' === 'true'
						? 'disabled'
						: 'enabled'
				})`,
				{
					category,
					hash,
					cookies,
					conversion,
					client,
					...params,
				}
			);
		}

		if ('false' === 'true') {
			return;
		}

		try {
			const payload = getSalted({
				category,
				hash,
				cookies,
				conversion,
				client,
				...params,
			});

			await app.$axios.$post('user:trace', payload, {
				headers: {
					'bf-action': params.action,
				},
			});
		} catch (error) {
			if ('false' === 'true') {
				console.warn('[trace-module]', error.response);
			}
		}
	};

	inject('trace', trace);
}

export const getConversions = () => {
	const eventId = getRandom(24);
	const fbp = cookie.parse(document.cookie)._fbp;

	const conversions = [];

	if (fbp != null) {
		conversions.push({
			platform: 'facebook',
			params: {
				fbp,
				unqueKey: eventId,
			},
		});
	}

	return conversions.length ? JSON.stringify(conversions) : null;
};
