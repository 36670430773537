import {
	get as getStoredConsent,
	isGranted as isConsentGranted,
} from '@/use/gdpr-consent.js';

const TIMEOUT = 3 * 60 * 60 * 1000;

export default ({ store, route }, inject) => {
	const ga4Id = 'G-17VMHK20H6';
	const adsIds = 'AW-1014284038,AW-11354243323,AW-16518871757'.split(',');
	const adsLabels = '8HJOCMGvv4kYEIb-0uMD,4gSGCJOc0OkYEPuBkKYq'.split(',');
	const adsArr = adsIds.map((item, index) => {
		return {
			id: item,
			label: adsLabels[index],
		};
	});
	const gtag = {
		init() {
			const hash = store.state.user.hash;

			window.dataLayer = window.dataLayer || [];

			if ('false' === 'true') {
				console.log(
					'[gtag-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					"GTAG has been initiated. Automatically collected and Enhanced measurement events won't be visible in the Console. Use Network tab to snitch."
				);
			}

			if ('false' === 'true') return;

			const script = document.createElement('script');

			script.async = true;
			script.src = `https://www.googletagmanager.com/gtag/js?id=${ga4Id}`;

			const head = document.getElementsByTagName('head')[0];

			head.appendChild(script);

			this.push('js', new Date());

			adsArr.forEach((item) =>
				this.push('config', item.id, {
					allow_enhanced_conversions: true,
					send_page_view: true,
				})
			);

			this.consent(true);
		},
		load() {
			this.init();
		},
		identify(email = store.state.user.personalInformation.email) {
			if (!email) {
				return;
			}
			this.push('set', 'user_data', {
				email,
			});
		},
		push() {
			dataLayer.push(arguments);

			if ('false' === 'true') {
				console.log(
					'[gtag-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					[...arguments]
				);
			}
		},

		evaluateConsent() {
			const storedConsent = getStoredConsent(store);

			return isConsentGranted(storedConsent);
		},
		consent(isGranted) {
			const type = 'default';
			const consent = {
				ad_storage: isGranted ? 'granted' : 'denied',
				analytics_storage: isGranted ? 'granted' : 'denied',
			};

			if ('false' === 'true') {
				console.log(
					'[google-adwords-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					{
						event: type,
						consent,
					}
				);
			}

			if ('false' === 'true') return;

			this.push('consent', type, consent);
		},
		eventGA(type, params) {
			this.push('event', type, { ...params, send_to: ga4Id });
		},
		eventAds(type, params) {
			this.push('event', type, {
				...params,
			});
		},
		setGa(type, params) {
			this.push('set', type, params);
		},
		purchase({ order, personalInformation }) {
			const coupon = getCoupon({ voucher: order.summary.voucher });
			const currency = 'EUR';
			const items = order.products.map((product) =>
				getCartItem({ product, store })
			);
			const shipping = order.summary.total.amounts.deliveryEuroRaw;
			const transaction_id = order.summary.reference;
			const value = order.summary.total.amounts.totalEuroRaw;

			this.eventGA('purchase', {
				coupon,
				currency,
				items,
				shipping,
				transaction_id,
				value,
				email: personalInformation.email,
				address: {
					first_name: personalInformation.fname,
					last_name: personalInformation.lname,
				},
			});

			// this.eventAds('conversion', {
			// 	value,
			// 	currency: 'EUR',
			// 	transaction_id: order.summary.id,
			// 	email: personalInformation.email,
			// 	address: {
			// 		first_name: personalInformation.fname,
			// 		last_name: personalInformation.lname,
			// 	},
			// });
		},
		trackPageView({ from, to }) {
			this.setGa('page_path', to.fullPath);
			this.setGa('page_location', to.fullPath);

			// this.eventGA('page_view');
		},
		viewItemList({ category }) {
			const item_list_name = category.banner.caption.heading.text;
			const items = category.products.map((product, index) =>
				getListItem({ product, index: index + 1, store })
			);

			this.eventGA('view_item_list', {
				items,
				item_list_name,
			});
		},
		viewItem({ product }) {
			const currency = 'EUR';
			const value = product.priceEuroReducedRaw;
			const items = [getSingleItem({ product, store })];

			this.eventGA('view_item', {
				currency,
				items,
				value,
			});
		},
		selectItem({ product, variation }) {
			const index = product.variations.findIndex(
				(v) => v.id === variation.id
			);
			const item_list_id = formatProductId({
				id: product.id,
				store,
			});
			const item_list_name = product.title;
			const items = [getSelectedItem({ product, variation, index })];

			this.eventGA('select_item', {
				item_list_id,
				item_list_name,
				items,
			});
		},
		addToCart({ product }) {
			const currency = 'EUR';
			const value = product.variation.priceEuroReducedRaw;
			const items = [getCartActionItem({ product, store })];

			this.eventGA('add_to_cart', {
				currency,
				value,
				items,
			});

			const eventSessionName = 'add_to_cart_session';
			const lastShownDate = getLocalStorageValueExpire(eventSessionName);
			const hasBeenShown = Date.now() - lastShownDate < TIMEOUT;
			if (!lastShownDate || !hasBeenShown) {
				this.eventGA(eventSessionName, {
					currency,
					value,
					items,
				});
				setLocalStorageValueExpire(eventSessionName, Date.now());
			}
		},
		removeFromCart({ product }) {
			const currency = 'EUR';
			const value = product.variation.priceEuroReducedRaw;
			const items = [getCartActionItem({ product, store })];

			this.eventGA('remove_from_cart', {
				currency,
				value,
				items,
			});
		},
		viewCart({ cart }) {
			const currency = 'EUR';
			const value = cart.total.amounts.totalEuroRaw;
			const items = cart.products.map((product) =>
				getCartItem({ product, store })
			);

			this.eventGA('view_cart', {
				currency,
				value,
				items,
			});

			const eventSessionName = 'view_cart_session';
			const lastShownDate = getLocalStorageValueExpire(eventSessionName);
			const hasBeenShown = Date.now() - lastShownDate < TIMEOUT;
			if (!lastShownDate || !hasBeenShown) {
				this.eventGA(eventSessionName, {
					currency,
					value,
					items,
				});
				setLocalStorageValueExpire(eventSessionName, Date.now());
			}
		},
		beginCheckout({ cart }) {
			const coupon = getCoupon({ voucher: cart.voucher });
			const currency = 'EUR';
			const value = cart.total.amounts.totalEuroRaw;
			const items = cart.products.map((product) =>
				getCartItem({ product, store })
			);

			this.eventGA('begin_checkout', {
				coupon,
				currency,
				value,
				items,
			});
		},
		addMethodInfo({ cart, method, type }) {
			const coupon = getCoupon({ voucher: cart.voucher });
			const currency = 'EUR';
			const value = cart.total.amounts.totalEuroRaw;
			const items = cart.products.map((product) =>
				getCartItem({ product, store })
			);

			const info = {
				coupon,
				currency,
				value,
				items,
			};

			switch (type) {
				case 'shipping':
					info.shipping_tier = method;
					break;

				case 'payment':
					info.payment_type = method;
					break;

				default:
			}

			this.eventGA(`add_${type}_info`, info);
		},
		addToWishlist({ product }) {
			const currency = 'EUR';
			const value = product.priceEuroReducedRaw;
			const items = [getSingleItem({ product, store })];

			this.eventGA('add_to_wishlist', {
				currency,
				value,
				items,
			});
		},
		setCustomDimensions() {
			this.setGa('user_properties', {
				...getCustomDimensions({ store, route }),
			});
		},
	};
	gtag.init();
	inject('gtag', gtag);
};

export const formatProductId = ({ id, store }) => {
	const countryIso = store.state.user.localization.country;

	return `BF${countryIso}${id}`;
};

export const getCustomDimensions = ({ store, route }) => {
	const tests = store.state.user.deltaImp;
	const dimensions = {};

	tests.forEach((test) => {
		dimensions[`delta_imp${test.id}`] = test.value;
	});

	const utmSource = route.query.utm_source ?? null;
	const variation = route.query?.variation ?? null;

	if (variation && utmSource && utmSource.toLowerCase() === 'klaviyo') {
		dimensions.variation = variation;
	}
	return dimensions;
};

export const getListItem = ({ product, index, store }) => {
	return {
		currency: 'EUR',
		discount: product.priceEuroRegularRaw - product.priceEuroReducedRaw,
		index,
		item_brand: product.brand.title,
		item_id: formatProductId({ id: product.id, store }),
		item_name: product.title,
		price: product.priceEuroRegularRaw,
		quantity: 1,
	};
};

export const getSingleItem = ({ product, store }) => {
	return {
		discount: product.priceEuroRegularRaw - product.priceEuroReducedRaw,
		item_brand: product.brand.title,
		item_id: formatProductId({ id: product.id, store }),
		item_name: product.title,
		price: product.priceEuroRegularRaw,
		quantity: 1,
	};
};

export const getSelectedItem = ({ product, variation, index }) => {
	return {
		currency: 'EUR',
		discount: variation.priceEuroRegularRaw - variation.priceEuroReducedRaw, // SCR-2872
		index,
		item_brand: product.brand.title,
		item_id: variation.id,
		item_name: product.title,
		item_variant: variation.title,
		price: variation.priceEuroRegularRaw, // SCR-2872
		quantity: 1,
	};
};

export const getCartActionItem = ({ product, store }) => {
	return {
		discount:
			product.variation.priceEuroRegularRaw -
			product.variation.priceEuroReducedRaw,
		item_brand: product.brand.title,
		...getCartItemCategories({ product }),
		item_id: formatProductId({ id: product.id, store }),
		item_name: product.title,
		item_variant: product.variation.title,
		price: product.variation.priceEuroRegularRaw,
		quantity: 1,
	};
};

export const getCartItem = ({ product, store }) => {
	return {
		discount:
			product.variation.unitPriceEuroRegularRaw -
			product.variation.unitPriceEuroReducedRaw,
		item_brand: product.brand.title,
		...getCartItemCategories({ product }),
		item_id: formatProductId({ id: product.id, store }),
		item_name: product.title,
		item_variant: product.variation.title,
		price: product.variation.unitPriceEuroRegularRaw,
		quantity: product.variation.quantity,
	};
};

export const getCartItemCategories = ({ product }) => {
	const categories = {};

	product.categories.map((category, index) => {
		const categoryKey =
			index > 0 ? `item_category${index + 1}` : 'item_category';

		categories[categoryKey] = category;
	});

	return categories;
};

export const getCoupon = ({ voucher }) => {
	return voucher.entries.map((v) => v.code).join(' + ');
};

const setLocalStorageValueExpire = (name, value) => {
	localStorage.setItem(name, JSON.stringify(value));
};

const getLocalStorageValueExpire = (name) => {
	return JSON.parse(localStorage.getItem(name));
};
