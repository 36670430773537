import _debounce from 'lodash.debounce';
import $bus from './bus.js';

const mq = {
	selectors: {
		data: '__mq-data',
		current: '__mq-current',
	},
	data: {},
	saved: {
		mq: '',
		width: '',
	},
	init() {
		// Get data
		this.data = this.scssToJs(document.getElementById(this.selectors.data));
		this.updateSaved();
	},
	is(breakpointToCompare) {
		// Get breakpoint names
		const range = breakpointToCompare.split('-'); // whether compare a single breakpoint or a range
		const current = this.getCurrentMq();

		if (range.length > 1) {
			const breakpoint = range[0];
			const isBreakpointUp = range[1] !== 'down';
			let breakpoints = Object.keys(this.data);

			// Whether reverse breakpoint array based on directions
			breakpoints = !isBreakpointUp
				? [...breakpoints].reverse()
				: breakpoints;
			// Harvest through breakpoints
			for (let i = breakpoints.length - 1; i >= 0; i--) {
				if (current === breakpoints[i]) {
					return true;
				}
				if (breakpoints[i] === breakpoint) {
					return false;
				}
			}
		} else {
			return range[0] === current;
		}
	},
	getCurrentMq() {
		return window.getComputedStyle(
			document.getElementById(this.selectors.current)
		).fontFamily;
	},
	getCurrentWidth() {
		return window.innerWidth;
	},
	updateSaved() {
		this.saved.mq = this.getCurrentMq();
		this.saved.width = this.getCurrentWidth();
	},
	setResizeHandle(val) {
		if (val) {
			this.updateSaved();
			window.addEventListener('resize', this.onResize, true);
		} else {
			window.removeEventListener('resize', this.onResize, true);
		}
	},
	onResize: _debounce(
		function (event) {
			const newWidth = mq.getCurrentWidth();

			if (newWidth != mq.saved.width) {
				const oldMq = mq.saved.mq;

				mq.saved.mq = mq.getCurrentMq();

				$bus.$emit('resize', {
					mq: mq.saved.mq,
					oldMq,
				});

				mq.saved.width = newWidth;
			}
		},
		200,
		{
			leading: true,
		}
	),
	scssToJs(element, params) {
		params = params || {};
		if (!element) {
			if (params.debug) {
				logError([element, ' is not an HTML element']);
			}
			return {};
		}
		params.cssProperty = params.cssProperty || 'font-family';

		return this.scssToJsGetJson(element, params);
	},
	scssToJsNormalizeCssValue(string) {
		string = string.replace(/^['"]+|\s+|\\|(;\s?})+|['"]$/g, '');

		return string;
	},
	scssToJsGetJson(element, params) {
		let cssValue = '';
		let json = {};

		// Get CSS value
		if (params.pseudoEl) {
			cssValue = window
				.getComputedStyle(element, params.pseudoEl)
				.getPropertyValue(params.cssProperty);
		} else {
			cssValue = window
				.getComputedStyle(element)
				.getPropertyValue(params.cssProperty);
		}

		if (cssValue === null) {
			if (params.debug) {
				logError([
					'CSS value for ',
					element,
					' with params ',
					params,
					' is empty',
				]);
			}
			return json;
		}

		// Normalize
		cssValue = this.scssToJsNormalizeCssValue(cssValue);

		try {
			json = JSON.parse(cssValue);
		} catch (err) {
			if (params.debug) {
				logError([
					'Cannot parse JSON from ',
					element,
					' with params ',
					params,
				]);
			}
		}
		// Return
		return json;
	},
};

export default mq;
