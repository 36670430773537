import { setUser, setTag } from '@sentry/vue';

export default ({ store }) => {
	const localizationProps = store.getters['user/GET_LOCALIZATION_PROPS'];

	setUser({
		id: store.state.user.hash,
		ip_address: '{{auto}}',
	});

	localizationProps.forEach((prop) => {
		setTag(prop, store.state.user.localization[prop]);
	});

	store.subscribe((mutation) => {
		if (
			mutation.type === 'user/SET_DATA' &&
			mutation.payload.hasOwnProperty('localization')
		) {
			localizationProps.forEach((prop) => {
				setTag(prop, mutation.payload.localization[prop]);
			});
		}
	});
};
