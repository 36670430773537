<template>
	<Error :custom-banner-text="customBannerText" />
</template>

<script>
	import Error from '@/components/Error.vue';
	export default {
		components: {
			Error,
		},
		props: ['error'],
		computed: {
			customBannerText() {
				return {
					description:
						this.error.message +
						(this.error.message.substr(-1) !== '.' ? '.' : ''),
				};
			},
		},
	};
</script>
