<template>
	<div id="__frontend">
		<div id="__strip" class="strip" :aria-hidden="isOverlayOn">
			<a class="skip" href="#__main">{{ $__('Skip to main content') }}</a>

			<header class="header">
				<Top :is-checkout-page="isCheckoutPage" />
				<NavMobileMenuTop v-if="device.isMobile || device.isTablet" />
				<LiveSearch v-if="isNewSearch" />
				<Search v-else />
				<template v-if="!isCheckoutPage">
					<template v-if="device.isDesktop || device.isTablet">
						<NavV2 />
					</template>
					<Proline />
				</template>
			</header>

			<main
				id="__main"
				:class="{
					'has-proline': prolineState,
					'is-shopping-bag': isShoppingBag,
				}"
			>
				<transition
					name="nuxt-transition"
					mode="out-in"
					@after-leave="emitNuxtTransitionLeave"
				>
					<div v-if="isCategoryPage" class="category-container">
						<CategoryFilterToggle
							:is-in-filter-mobile="false"
							class="category-container-filter"
						/>

						<header class="category-header">
							<Nuxt nuxt-child-key="banner" name="banner" />
						</header>
						<Breadcrumbs />
						<section
							id="aside-affix-relative"
							class="category-wrapper"
						>
							<Nuxt nuxt-child-key="filter" name="filter" />
							<Nuxt nuxt-child-key="products" name="products" />
							<Nuxt />
						</section>

						<ScrollToTopButton
							@toggle-visibility="checkVisibility"
						/>
					</div>
					<Nuxt v-else :key="nuxtKey" />
				</transition>
				<Overlay id="__overlay" />
			</main>
			<NavMobileSlideMenu />
			<GdprSheet v-if="!isCheckoutPage" />
			<Footer v-if="!isCheckoutPage" />
			<NavMobileMenuBottom
				v-if="
					isBottomMenuMobileOn && (device.isMobile || device.isTablet)
				"
			/>
			<Backdrop />
		</div>
	</div>
</template>

<script>
	import NavMobileSlideMenu from '../components/Navigation/NavMobileSlideMenu.vue';
	import localeValidate from '@/assets/json/localization/validate.json';
	import Top from '@/components/Top.vue';
	import CategoryFilterToggle from '@/components/CategoryFilterToggle.vue';
	import Search from '@/components/Search.vue';
	import LiveSearch from '@/components/LiveSearch.vue';
	import NavV2 from '@/components/Navigation/NavV2.vue';
	import Proline from '@/components/Proline.vue';
	import GdprSheet from '@/components/GdprSheet.vue';
	import Footer from '@/components/Footer.vue';
	import Backdrop from '@/components/Backdrop.vue';
	import NavMobileMenuTop from '@/components/Navigation/NavMobileMenuTop.vue';
	import NavMobileMenuBottom from '@/components/Navigation/NavMobileMenuBottom.vue';
	import { addClientMetaHeader } from '@/plugins/add-client-meta-header';
	import ScrollToTopButton from '@/components/ScrollToTopButton';
	import Breadcrumbs from '@/components/Breadcrumbs.vue';

	export default {
		components: {
			ScrollToTopButton,
			Top,
			CategoryFilterToggle,
			Search,
			LiveSearch,
			NavV2,
			Proline,
			GdprSheet,
			Footer,
			Backdrop,
			NavMobileSlideMenu,
			NavMobileMenuTop,
			NavMobileMenuBottom,
			Breadcrumbs,
			Overlay: () =>
				import(
					'@/components/Overlay/Overlay.vue' /* webpackChunkName: "overlay" */
				),
		},
		data() {
			return {
				isScrollTopVisible: false,
			};
		},
		head() {
			return {
				htmlAttrs: {
					lang: this.htmlLang,
					class: this.htmlClasses,
					dir: this.htmlDir,
				},
				bodyAttrs: {
					class: this.bodyClasses,
				},
				title: this.meta.title,
				meta: this.meta.entries,
				script: this.scripts,
				link: [
					{
						rel: 'canonical',
						href:
							this.meta.canonicalUrl ||
							`${process.env.HOST_URL}${this.$route.path}`,
					},
				],
			};
		},
		computed: {
			prolineState() {
				return this.$store.state.user.proline;
			},
			layoutVariant() {
				return this.$route.name.startsWith('checkout') &&
					!this.$route.name.startsWith('checkout-thank-you')
					? 'checkout'
					: 'catalogue';
			},
			htmlLang() {
				return (
					this.$store.state.user.localization.language ||
					localeValidate.language[0]
				);
			},
			htmlDir() {
				return localeValidate.isRtl.includes(this.htmlLang)
					? 'rtl'
					: 'ltr';
			},
			htmlClasses() {
				const classes = [];

				classes.push(`is-${this.layoutVariant}`);
				classes.push(`is-${this.htmlDir}`);

				return classes.join(' ');
			},
			bodyClasses() {
				const ui = this.$store.state.ui;
				const classes = [];

				if (ui.isTabbing) {
					classes.push('has-tabbing--on');
				}
				if (ui.isBodyfixOn) {
					classes.push('has-bodyfix--on');
				}
				if (ui.isBackdropOn) {
					classes.push('has-backdrop--on');
				}
				if (ui.isSearchOn) {
					classes.push('has-search--on');
				}
				if (ui.isFilterPage) {
					classes.push('has-filter');
				}
				if (ui.isFilterMobileOn) {
					classes.push('has-filter-mobile--on');
				}
				if (ui.isOverlayOn) {
					classes.push('has-overlay--on');
				}
				if (ui.isNavTabletOn) {
					classes.push('has-nav-mobile--on');
				}
				if (ui.isNavDropmenuOn) {
					classes.push('has-nav-dropmenu--on');
				}
				if (ui.isNavMobileBackdropOn) {
					classes.push('has-nav-mobile-backdrop--on');
				}

				return classes.join(' ');
			},
			meta() {
				return this.$store.getters['page/GET_META'](this.$route);
			},
			scripts() {
				return [
					{
						type: 'text/javascript',
						src: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
						async: true,
					},
				];
			},
			isShoppingBag() {
				return this.$route.name === 'shopping-bag';
			},
			isCategoryPage() {
				return this.$store.state.ui.isFilterPage;
			},
			isEmptyCategoryPage() {
				return (
					this.isCategoryPage &&
					this.$store.state.page.content.products.length < 1
				);
			},
			isCheckoutPage() {
				return this.layoutVariant === 'checkout';
			},
			isOverlayOn() {
				return this.$store.state.ui.isOverlayOn;
			},
			nuxtKey() {
				return this.$route.fullPath;
			},
			isMobile() {
				if (!process.browser) {
					return;
				}
				return this.$platform?.is?.mobile;
			},
			isBottomMenuMobileOn() {
				return this.$store.state.ui.isBottomMenuMobileOn;
			},
			isSafari() {
				return this.$platform?.is?.safari;
			},
			device() {
				return this.$store.state.ui.device;
			},
			isNewSearch() {
				return this.$deltaImp.is(30, 2);
			},
			isIpad() {
				if (process.browser) {
					return (
						navigator.userAgent.match(/Mac/) &&
						navigator.maxTouchPoints &&
						navigator.maxTouchPoints > 2
					);
				}
				return false;
			},
		},
		created() {
			this.$translate.setLang('en');
			this.$store.dispatch('user/setMetricUnit');
		},
		mounted() {
			this.$store.dispatch('ui/startUtsNowTimer');
			this.$store.dispatch('user/setUtmSourceCoookie', this.$route);
			const path = this.$router.currentRoute.path;
			const token = this.$store.state.user.token;

			const params = this.$store.state['ad-params'].params;
			addClientMetaHeader(path, token, params);
			this.$gtag.setCustomDimensions();
		},
		methods: {
			emitNuxtTransitionLeave() {
				this.$root.$emit('page-transition-leave');
			},
			checkVisibility(visible) {
				this.isScrollTopVisible = visible;
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/pages/category.scss';

	.ios-banner--margin {
		margin-bottom: -$btn-scroll-top-size;
	}
</style>
