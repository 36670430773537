<template>
	<transition name="nav-transition">
		<nav
			:class="[
				'nav',
				'nav--desktop',
				{ 'has-dropmenu--open': hasDropmenuOn },
			]"
		>
			<div class="nav--desktop-container">
				<ul class="nav--desktop-list">
					<NavListItemV2
						v-for="item in navListItems"
						:key="item.name"
						:item="item"
					/>
					<NavListItemV2 :item="blogLink" />
					<NavigationSearch />
				</ul>
			</div>
		</nav>
	</transition>
</template>

<script>
	import NavListItemV2 from '@/components/Navigation/NavListItemV2.vue';
	import NavigationSearch from '@/components/Navigation/NavigationSearch';
	export default {
		components: {
			NavigationSearch,
			NavListItemV2,
		},
		data() {
			return {
				hasSearchToggleHover: false,
			};
		},
		computed: {
			navListItems() {
				return this.$store.state.common.navigation;
			},
			hasDropmenuOn() {
				return this.$store.state.ui.isNavDropmenuOn;
			},
			blogLink() {
				return {
					name: 'blog',
					title: 'Blog',
					url: '/blog',
					dropmenu: false,
					sections: null,
				};
			},
		},
		methods: {
			onSearchToggleHover() {
				this.hasSearchToggleHover = true;
			},
			onSearchToggleLeave() {
				this.hasSearchToggleHover = false;
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/nav.scss';
</style>
