import { getterTree, mutationTree } from 'typed-vuex';

export const state = () => ({
	products: [],
});

export const mutations = mutationTree(state, {
	SET_LIST(state, products) {
		state.products = products;
	},
	CLEAR_LIST(state) {
		state.products = [];
	},
});

export const getters = getterTree(state, {
	getList: (state) => state.products,
});
