<template>
	<div class="nav--mobile-menu-bottom">
		<nav class="nav--mobile-menu-bottom-wrapper">
			<div class="nav--mobile-menu-bottom-container">
				<ul class="nav--mobile-menu-bottom-list">
					<li>
						<button
							type="button"
							:title="
								isMenuOn
									? $__('Close main menu')
									: $__('Open main menu')
							"
							:aria-label="
								isMenuOn
									? $__('Close main menu')
									: $__('Open main menu')
							"
							:class="[
								'nav--mobile-menu-bottom-item',
								'nav--mobile-menu-bottom-item--menu',
								{
									'nav--mobile-menu-bottom-item--active':
										isMenuOn,
								},
							]"
							@click="toggleMenu"
						>
							<i
								class="nav--mobile-menu-bottom-icon--menu icon-bambini_b"
							/>
							<span>Menu</span>
						</button>
					</li>
					<li>
						<NuxtLink
							:title="$__('Home')"
							:aria-label="$__('Home')"
							to="/"
							:class="[
								'nav--mobile-menu-bottom-item',
								{
									'nav--mobile-menu-bottom-item--active':
										activeRoute === 'index' &&
										!isMenuOn &&
										!isSearchOn,
								},
							]"
							@click.native="setRouteActiveOrScroll('index')"
						>
							<i class="nav--mobile-menu-bottom-icon icon-home">
							</i>
							<span>Home</span>
						</NuxtLink>
					</li>
					<li>
						<NuxtLink
							:title="$__('Shopping bag')"
							:aria-label="$__('Shopping bag')"
							to="/shopping-bag"
							:class="[
								'nav--mobile-menu-bottom-item',
								{
									'nav--mobile-menu-bottom-item--active':
										activeRoute === 'shopping-bag' &&
										!isMenuOn &&
										!isSearchOn,
								},
							]"
							@click.native="
								setRouteActiveOrScroll('shopping-bag')
							"
						>
							<i class="nav--mobile-menu-bottom-icon icon-bag">
								<TopCounter :count="counters.cart" />
							</i>
							<span>Bag</span>
						</NuxtLink>
					</li>
					<li>
						<button
							type="button"
							:title="$__('Search')"
							:aria-label="$__('Search')"
							:class="[
								'nav--mobile-menu-bottom-item',
								{
									'nav--mobile-menu-bottom-item--active':
										isSearchOn && !isMenuOn,
								},
							]"
							@click="toggleSearch"
						>
							<i
								class="nav--mobile-menu-bottom-icon icon-search"
							/>
							<span>Search</span>
						</button>
					</li>
					<li>
						<NuxtLink
							:title="$__('Designers')"
							:aria-label="$__('Designers')"
							to="/designers"
							:class="[
								'nav--mobile-menu-bottom-item',
								{
									'nav--mobile-menu-bottom-item--active':
										activeRoute === 'designers' &&
										!isMenuOn &&
										!isSearchOn,
								},
							]"
							@click.native="setRouteActiveOrScroll('designers')"
						>
							<i class="nav--mobile-menu-bottom-icon icon-star">
							</i>
							<span>Designers</span>
						</NuxtLink>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</template>
<script>
	import animatedScrollTo from 'animated-scroll-to';
	import TopCounter from '@/components/TopCounter.vue';
	import { mapper } from '@/store/helpers/create-mapper';

	export default {
		components: {
			TopCounter,
		},
		data() {
			return {
				activeRoute: null,
			};
		},
		computed: {
			...mapper('user', ['counters']),
			...mapper('mobileNavigation', ['isMenuOn']),
			...mapper('ui', ['searchInputId', 'isSearchOn']),
		},
		watch: {
			'$route.name': {
				handler(name) {
					this.activeRoute = name;
				},
				immediate: true,
			},
		},
		methods: {
			toggleMenu() {
				if (this.isMenuOn) {
					this.$accessor.mobileNavigation.RESET_MENU_HISTORY();
				} else {
					this.$accessor.mobileNavigation.TOGGLE_NAV_MOBILE();
				}
			},
			toggleSearch() {
				if (this.isMenuOn) {
					this.$accessor.mobileNavigation.RESET_MENU_HISTORY();
				}
				this.$store.dispatch('ui/toggleSearch');
				this.setInputInFocus(this.searchInputId);
			},
			setInputInFocus(inputId) {
				if (!process.browser) return;

				this.$nextTick(() => {
					let input = document.getElementById(inputId);

					this.$el.blur();
					input.focus();
				});
				setTimeout(() => {
					if (this.$mq.is('md-down')) window.scrollTo(0, 0);
					window.onResize = function () {
						document.body.height = window.innerHeight;
					};
					window.onResize();
				}, 75);
			},
			setRouteActiveOrScroll(routeName) {
				this.activeRoute = routeName;
				if (this.$route.name === routeName) animatedScrollTo(0);

				if (this.$store.state.ui.isSearchOn)
					this.$store.dispatch('ui/toggleSearch');

				if (this.$accessor.mobileNavigation.isMenuOn) {
					this.$accessor.mobileNavigation.TOGGLE_NAV_MOBILE();
				}
			},
		},
	};
</script>
<style lang="scss">
	a.nuxt-link-active {
		color: $white;
	}
	.nav--mobile-menu-bottom {
		& {
			@include media-breakpoint-up(md) {
				display: none;
			}
			bottom: 0;
			margin-top: auto;
			z-index: 200;
			position: sticky;
			width: 100vw;
		}
		&-wrapper {
			-webkit-tap-highlight-color: rgba(15, 119, 176, 0.1);
			background-color: $body-color;
		}
		&-container {
			box-sizing: border-box;

			max-width: 100vw;
			overflow: hidden;
			padding: 10px;
		}
		&-list {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			align-items: baseline;
			> li {
				display: flex;
				flex: 0 0 20%;
				justify-content: center;
				align-items: flex-end;
			}
		}
		&-item {
			display: flex;
			flex-direction: column;
			font-size: 0.8rem;
			color: rgba(255, 255, 255, 0.6);
			justify-content: center;
			align-items: center;
			transition: color $nav--mobile-transition-duration
				$overlay-backdrop-transition-duration $transition-style;
			&--active {
				color: $white;
			}
		}
		&-icon {
			position: relative;
			font-size: 1.3rem;
			&--menu {
				font-size: 1.2rem;
			}
			> span {
				background-color: #555555;
				top: -0.2rem;
			}
		}
	}
</style>
