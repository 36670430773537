export default (string = '', to = 'kebab') => {
	if (typeof to === 'undefined') {
		return;
	}

	if (to === 'kebab') {
		return string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
	} else {
		const camelString = string
			.toLowerCase()
			.replace(/(\-\w)/g, (matches) => {
				return matches[1].toUpperCase();
			});

		return to === 'pascal'
			? camelString.charAt(0).toUpperCase() + camelString.slice(1)
			: camelString;
	}
};
