import cookie from 'cookie';
import Vue from 'vue';
import isStorageAvailable from 'storage-available';
import { actionTree, getterTree, mutationTree } from 'typed-vuex';
import { Request } from 'express';
import { AB_TEST } from '@/plugins/delta-imp';
import { getUserDeliveryTax } from '@/api/get-user-delivery-tax';
import {
	addProductToWishlist,
	addProductToWishlistModeId,
	removeProductFromWishlist,
	addVariationToWishlist,
	updateVariationInWishlist,
	removeProductFromWishlistModeId,
} from '@/api/addItemToWishlist';
import { UserAddress } from '@/api/touch-address-for-ddp';
import { CartDdpParams, setCartWithDdp } from '@/api/set-cart-with-ddp';
import { Cart } from '@/types/cart';
import {
	AccountNavigation,
	UserDelivery,
	UserLocalization,
	UserLocalizationCountry,
	UserTax,
} from '@/types/user';
import {
	LocalizationCountry,
	LocalizationCurrency,
	LocalizationLanguage,
} from '@/types/common';
import welcomePopupClient from '@/plugins/welcome-popup.client'; // KAN-2436

export const state = () => ({
	isStipeLoaded: false,
	hash: null,
	cookie: {
		prefix: 'bf_',
		options: {
			set: {
				domain: process.env.COOKIE_DOMAIN,
				sameSite: 'lax',
				secure: 1,
				path: '/',
				expires: new Date('31 Dec 2099 23:59:59 UTC'),
			},
			delete: {
				domain: process.env.COOKIE_DOMAIN,
				sameSite: 'lax',
				secure: 1,
				path: '/',
				expires: new Date('01 Jan 1970 00:00:00 UTC'),
			},
		},
		names: {
			hash: 'hash',
			token: 'token',
			localization: 'localization',
			deltaImp: 'delta_imp',
			utmSource: 'source',
		},
	},
	localStorage: {
		prefix: 'bf_',
		names: {
			gdprConsent: 'gdpr_consent',
			searchFeed: 'search_feed',
			recentSearch: 'recent_search_items',
			recentProducts: 'recent_products',
			recentRmUts: 'recent_rm_uts',
			metricUnit: 'metric_unit',
			cartHold: 'cart_hold',
			utmParams: 'utm_params',
			socialLoginProvider: 'social_login_provider',
			socialLoginRedirect: 'social_login_redirect',
		},
	},
	referer: null,
	localization: {} as UserLocalization,
	deltaImp: [] as AB_TEST[],
	cart: {
		id: null,
		voucher: {},
		errors: [],
		products: [],
		delivery: {},
		total: {},
	} as unknown as Cart,
	wishlist: {
		products: [] as any[],
		ids: [] as any[],
	},
	counters: {},
	cartHoldUts: null,
	proline: {},
	personalInformation: {
		email: '',
	},
	accountNavigation: [] as AccountNavigation[],
	address: {
		entries: [] as UserAddress[],
	},
	recentCategory: null as any, // TODO Add typings
	delivery: {} as UserDelivery,
	payment: {
		methods: [],
		cards: [],
	},
	welcomePopup: {}, // KAN-2436
	returns: {},
	shipping: {},
	tax: {} as UserTax,
	isMetricUnit: undefined,
	isValidMagicLink: undefined,
	isSearchBot: false,
});

export const getters = getterTree(state, {
	GET_NO_CACHE_HEADERS() {
		return {
			'cache-control': 'max-age=0',
		};
	},
	GET_COOKIE:
		(state) =>
		(
			payload: {
				prefix?: string;
				name?: string;
				req?: Request;
			} = {}
		) => {
			const prefix =
				typeof payload.prefix !== 'undefined'
					? payload.prefix
					: state.cookie.prefix;

			if (process.server && payload.req && payload.req.headers.cookie) {
				return cookie.parse(payload.req.headers.cookie)[
					prefix + payload.name
				];
			} else if (process.browser) {
				return cookie.parse(document.cookie)[prefix + payload.name];
			} else {
				return undefined;
			}
		},
	GET_LOCAL_STORAGE:
		(state) =>
		(
			payload: {
				prefix?: string;
				name?: string;
			} = {}
		) => {
			if (!isStorageAvailable('localStorage')) {
				return undefined;
			}

			const prefix =
				typeof payload.prefix !== 'undefined'
					? payload.prefix
					: state.localStorage.prefix;
			const data = localStorage.getItem(prefix + payload.name);

			return data === null
				? undefined
				: localStorage.getItem(prefix + payload.name);
		},
	GET_LOCALIZATION_PROPS() {
		return ['country', 'currency', 'language'];
	},
	GET_THE_COUNTRY_TITLE: (state) => (country: UserLocalizationCountry) => {
		if (country) {
			return (
				(country.hasThe && state.localization.language === 'en'
					? 'The '
					: '') + country.title
			);
		}
	},
	GET_GA_LANGUAGE_DIMENSION: (state) => {
		return {
			dimension20: state.localization.language,
		};
	},
	GET_NAV_ITEM_ACCOUNT_PATH: (state) => {
		const url = state.accountNavigation[0]?.url || '';

		return `/account${url}`;
	},
	GET_E7_COUNTRIES: () => {
		return ['DE', 'DK', 'IT', 'FR', 'GB', 'BE', 'AE'];
	},
	GET_ADDRESS_BY_ID:
		(state) =>
		(addressID: number): UserAddress | undefined =>
			state.address.entries.find((e) => e.id === addressID),
	getSelectedLocalizationByType:
		(state, getters, rootState) => (type: keyof UserLocalization) => {
			return rootState.common.localizations[type].find(
				(
					item:
						| LocalizationCountry
						| LocalizationCurrency
						| LocalizationLanguage
				) => {
					return item.isoCode === state.localization[type];
				}
			);
		},
});

export const mutations = mutationTree(state, {
	RESET_CART_DELIVERY_ZONE_ID(state) {
		state.cart.delivery.zone.id = null;
	},
	SET_CART(state, cart: Cart) {
		state.cart = cart;
	},
	SET_DELIVERY(state, delivery: UserDelivery) {
		state.delivery = delivery;
	},
	SET_TAX(state, tax: UserTax) {
		state.tax = tax;
	},
	LOAD_STRIPE(state) {
		state.isStipeLoaded = true;
	},
	SET_DATA(state, data) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		state = Object.assign(state, data);
	},
	SET_PROLINE(state, proline) {
		state.proline = proline;
	},
	SET_WELCOME_POPUP(state, popup) {
		// KAN-2436
		state.welcomePopup = popup;
	},
	SET_COOKIE(state, payload = {}) {
		if (!process.browser) {
			return;
		}

		const prefix =
			typeof payload.prefix !== 'undefined'
				? payload.prefix
				: state.cookie.prefix;
		const name = prefix + payload.name;
		const value = payload.value || 0;
		const options = value
			? state.cookie.options.set
			: state.cookie.options.delete;
		// @ts-ignore
		const data = cookie.serialize(name, value, options);

		document.cookie = data;
	},
	SET_LOCAL_STORAGE(state, payload = {}) {
		if (!isStorageAvailable('localStorage')) {
			return;
		}

		const prefix = payload.prefix || state.localStorage.prefix;

		localStorage.setItem(prefix + payload.name, payload.data);
	},
	SET_LOCALIZATION(state, payload) {
		state.localization = payload;
	},
	SET_REFERER(state, payload) {
		state.referer = payload;
	},
	SET_COUNTRY(state, payload) {
		state.localization.country = payload;
	},
	SET_ADDRESS(state, payload = []) {
		state.address.entries = payload;
	},
	forceSetDeltaImpValue(state, { id, value }) {
		return (state.deltaImp = state.deltaImp.map((item: any) => {
			if (item.id === id) {
				item.value = value;
				return item;
			}
			return item;
		}));
	},
	setCurrency(state, payload) {
		state.localization.currency = payload;
	},
	setIsSearchBot(state, payload) {
		state.isSearchBot = payload;
	},
});

export const actions = actionTree(
	{ state, getters, mutations },
	{
		async assignUserCart({ commit }, cartDdpParams: CartDdpParams) {
			const { user } = await setCartWithDdp(cartDdpParams);
			commit('SET_CART', user.cart);
		},
		async updateUserDeliveryTax({ commit }, country: string) {
			const { user } = await getUserDeliveryTax(country);
			commit('SET_DELIVERY', user.delivery);
			commit('SET_TAX', user.tax);
		},
		async addProductToWishlist(
			{ dispatch, commit, state },
			{ product, variationId, isPageWishlist }
		) {
			const originalWishlist = state.wishlist;

			// Optimistically adding product to wishlist
			// const newProducts = isPageWishlist
			// 	? [
			// 			...state.wishlist.products,
			// 			{ ...product, variation_id: variationId },
			// 	  ]
			// 	: [];

			// commit('SET_DATA', {
			// 	wishlist: {
			// 		...state.wishlist,
			// 		products: newProducts,
			// 		ids: [
			// 			...state.wishlist.ids,
			// 			{
			// 				product_id: product.id,
			// 				product_variation_id: variationId,
			// 			},
			// 		],
			// 	},
			// });

			const response = isPageWishlist
				? await addProductToWishlist(product.id, variationId)
				: await addProductToWishlistModeId(product.id, variationId);

			const wishlist = response.user.wishlist;

			// If response has failed - remove product from wishlist
			// if (!wishlist.isSuccess) {
			// 	commit('SET_DATA', {
			// 		wishlist: originalWishlist,
			// 	});
			// }
			commit('SET_DATA', {
				wishlist: {
					ids: wishlist.ids,
					products: wishlist.products,
				},
			});

			dispatch('getCounters');
		},
		async removeProductFromWishlist(
			{ dispatch, commit, state },
			{ product, variationId, isPageWishlist }
		) {
			const originalWishlist = state.wishlist.products;
			const originalWishlistIds = state.wishlist.ids;

			const newProducts = isPageWishlist
				? state.wishlist.products.reduce((acc, item) => {
						if (product.id === item.id) {
							if (
								variationId === item.variation_id ||
								variationId === null
							) {
								return acc;
							}
						}
						return (acc = [...acc, item]);
				  }, [])
				: [];

			const newIds = state.wishlist.ids.reduce((acc, item) => {
				if (product.id === item.product_id) {
					if (
						variationId === item.product_variation_id ||
						variationId === null
					) {
						return acc;
					}
				}
				return (acc = [...acc, item]);
			}, []);
			// Optimistically remove product from wishlist
			commit('SET_DATA', {
				wishlist: {
					...state.wishlist,
					products: newProducts,
					ids: newIds,
				},
			});

			const response = isPageWishlist
				? await removeProductFromWishlist(product.id, variationId)
				: await removeProductFromWishlistModeId(
						product.id,
						variationId
				  );

			const wishlist = response.user.wishlist;

			if (!wishlist.isSuccess) {
				commit('SET_DATA', {
					wishlist: {
						...state.wishlist,
						products: originalWishlist,
						ids: originalWishlistIds,
					},
				});
			}

			commit('SET_DATA', {
				wishlist,
			});

			dispatch('getCounters');
		},
		async addProductVariationToWishlist(
			{ dispatch, commit, state },
			{ product, variationId }
		) {
			const originalWishlist = state.wishlist;

			commit('SET_DATA', {
				wishlist: {
					...state.wishlist,
					products: [
						...state.wishlist.products,
						{ ...product, variation_id: variationId },
					],
				},
			});

			const response = await addVariationToWishlist(
				product.id,
				variationId
			);

			const wishlist = response.user.wishlist;

			// If response has failed - remove product from wishlist
			if (!wishlist.isSuccess) {
				commit('SET_DATA', {
					wishlist: originalWishlist,
				});
			}

			commit('SET_DATA', {
				wishlist,
			});

			dispatch('getCounters');
		},

		async updateProductVariationInWishlist(
			{ dispatch, commit, state },
			{ product, variationId, oldVariationId }
		) {
			const originalWishlist = state.wishlist.products;
			const originalWishlistIds = state.wishlist.ids;

			const response = await updateVariationInWishlist(
				product.id,
				variationId,
				oldVariationId
			);

			const wishlist = response.user.wishlist;

			// If response has failed - remove product from wishlist
			if (!wishlist.isSuccess) {
				commit('SET_DATA', {
					...state.wishlist,
					products: originalWishlist,
					ids: originalWishlistIds,
				});
			}
			commit('SET_DATA', {
				wishlist,
			});

			dispatch('getCounters');
		},

		async getCounters({ commit }) {
			try {
				const response = await this.$axios.$get('user:counters');
				const counters = response.user.counters;

				commit('SET_DATA', { counters });
			} catch (error) {}
		},
		async getWishlist({ commit }) {
			try {
				const response = await this.$axios.$get(
					'user:wishlist&mode=id'
				);
				const wishlist = response.user.wishlist;

				commit('SET_DATA', { wishlist });

				return wishlist;
			} catch (error) {}
		},
		async getPersonalInfo({ commit }) {
			try {
				const response = await this.$axios.$get(
					'user:personalInformation'
				);
				const personalInformation = response.user.personalInformation;

				commit('SET_DATA', { personalInformation });

				return personalInformation;
			} catch (error) {}
		},

		async proceedFromAddressToCheckout({ commit }, params = {}) {
			try {
				const { user } = await this.$axios.$get('user:cart', {
					params,
				});

				const { cart, tax } = user;

				commit('SET_DATA', { cart, tax });

				return cart;
			} catch (error) {}
		},

		async assignCheckoutToCart({ commit }, params: CartDdpParams) {
			try {
				const { user } = await setCartWithDdp(params);

				commit('SET_DATA', { cart: user.cart });

				return user.cart;
			} catch (error) {}
		},
		updateRecentCategory({ state, commit }, payload) {
			commit('SET_DATA', {
				recentCategory: { ...state.recentCategory, ...payload },
			});
		},
		setUtmSourceCoookie({ commit }, route) {
			const utmSource = route.query.utm_source;

			if (typeof utmSource !== 'undefined') {
				commit('SET_COOKIE', {
					name: 'utm_source',
					data: utmSource,
				});
			}
		},
		setMetricUnit({ state, getters, commit }, isMetric) {
			let isMetricUnit = isMetric;

			if (typeof isMetric === 'undefined') {
				const isMetricUnitStored = getters.GET_LOCAL_STORAGE({
					name: state.localStorage.names.metricUnit,
				});

				if (isMetricUnitStored) {
					isMetricUnit = parseInt(isMetricUnitStored) !== 0;
				} else {
					isMetricUnit = !['US', 'CA', 'GB'].includes(
						state.localization.country
					);
				}
			}

			commit('SET_DATA', {
				isMetricUnit,
			});
			commit('SET_LOCAL_STORAGE', {
				name: state.localStorage.names.metricUnit,
				data: isMetricUnit ? 1 : 0,
			});
		},
		getCartHold({ state, getters, commit }) {
			const cartHold = getters.GET_LOCAL_STORAGE({
				name: state.localStorage.names.cartHold,
			});

			if (!cartHold) {
				return;
			}

			const cartHoldUts = parseInt(cartHold);

			if (!cartHoldUts || Number.isNaN(cartHoldUts)) {
				return;
			}

			commit('SET_DATA', {
				cartHoldUts,
			});
		},
		setCartHold({ state, commit }) {
			const cartHoldDuration = 10 * 60 * 1000;

			const cartHoldUts = Date.now() + cartHoldDuration;

			commit('SET_DATA', {
				cartHoldUts,
			});

			commit('SET_LOCAL_STORAGE', {
				name: state.localStorage.names.cartHold,
				data: cartHoldUts,
			});
		},
		async loadUserBasicInfo({ commit }) {
			try {
				const { user } = await this.$axios.$get<{
					user: {
						proline: any;
						welcomePopup: any;
						delivery: UserDelivery;
					};
				}>('user:proline&welcomePopup&delivery'); // KAN-2436 // user:proline&welcomePopup&delivery in past

				welcomePopupClient({
					// KAN-2436
					route: this.$router.currentRoute,
					store: this,
				});

				commit('SET_PROLINE', user.proline);
				commit('SET_WELCOME_POPUP', user.welcomePopup); // KAN-2436
				commit('SET_DELIVERY', user.delivery);
			} catch (error) {}
		},

		async setLocalization({ commit, dispatch }, { name, selectedItem }) {
			// @ts-ignore
			$nuxt.$loading.start();
			// @ts-ignore
			commit('ui/toggleLocalizationLoading', null, { root: true });

			// this.app.$accessor.ui.toggleLocalizationLoading(null);
			const selectedIsoCode =
				selectedItem.tagName === 'SELECT'
					? selectedItem.value
					: selectedItem.dataset.isoCode;

			try {
				await Promise.all([
					this.$router.replace(
						`${this.$router.currentRoute.path}?${name}=${selectedIsoCode}`
					),
					dispatch('common/loadNavigation', null, { root: true }),
					dispatch('loadUserBasicInfo'),
				]);
			} catch {
				// Do nothing. Vue-Router might throw a redirect error if product not find
				// but this is by design
			} finally {
				// @ts-ignore
				$nuxt.$loading.finish();
				// @ts-ignore
				commit('ui/toggleLocalizationLoading', null, { root: true });
			}
		},
	}
);
