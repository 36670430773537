<template>
	<form
		:class="['newsletter-form', { 'has-form-errors': form.errors.any() }]"
		novalidate
		@keydown="form.errors.clear($event.target.name)"
		@submit.prevent="form.submit(method, url)"
	>
		<div class="form-field form-field--required">
			<input
				:id="$_uid + '-email'"
				v-model="form.fields.email"
				type="email"
				size="18"
				name="email"
				autocorrect="off"
				autocapitalize="off"
				spellcheck="false"
				class="form-input--singleline"
			/>
			<label :for="$_uid + '-email'" class="form-label">
				<span class="form-label-title">{{ $__('Your E-mail') }}:</span>
				<span
					v-if="form.errors.has('email')"
					class="form-label-error"
					v-text="form.errors.get('email')"
				/>
			</label>
		</div>
		<button
			ref="SubscribeButton"
			type="submit"
			class="form-cta--secondary"
			:disabled="form.errors.any() || form.isLoading"
			:class="{ 'is-loading': form.isLoading }"
			v-text="$__('Subscribe')"
		/>
		<Spinner v-if="form.isLoading" class="form-cta--secondary-spinner" />
	</form>
</template>

<script>
	import Form from '@/use/form.js';
	export default {
		data() {
			return {
				form: new Form({
					$axios: this.$axios,
					fields: {
						email: '',
					},
					onSuccess: this.onSuccess,
				}),
				method: 'get',
			};
		},
		computed: {
			url() {
				return `user:subscribeNewsletter`;
			},
		},
		methods: {
			onSuccess(response) {
				this.form.isLoading = false;

				const subscribeNewsletter = response.user.subscribeNewsletter;

				this.$store.dispatch('ui/toggleOverlay', {
					overlayData: {
						title: subscribeNewsletter.title,
						content: subscribeNewsletter.message,
					},
				});

				this.$track('newsletter-sign-up', {
					email: this.form.fields.email,
					path: this.$route.fullPath,
					context: 'footer',
				});

				this.form.reset();

				setTimeout(() => {
					this.$refs.SubscribeButton.focus();
					this.$refs.SubscribeButton.blur();
				}, 20);
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/newsletter-form.scss';
</style>
