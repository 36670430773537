<template>
	<div
		v-click-outside="close"
		:class="['localization-dropdown', 'localization-dropdown--' + name]"
	>
		<button
			:id="'localization-dropdown-toggle--' + name"
			type="button"
			class="localization-dropdown-toggle"
			:title="label"
			:aria-label="label"
			:aria-expanded="isOpen ? 'true' : 'false'"
			:data-iso-code="selected"
			aria-haspopup="true"
			:disabled="isLoading"
			@click="toggle()"
		>
			<span
				v-if="name === 'country'"
				:class="['localization-dropdown-flag']"
				:style="selectedFlagStyle"
			/>
			{{ selectedText }}
		</button>
		<transition name="localization-dropdown-transition">
			<div
				v-if="isOpen"
				:aria-labelledby="'localization-dropdown-toggle--' + name"
				:class="['localization-dropdown-inner']"
			>
				<ul :class="['localization-dropdown-list']">
					<li
						v-for="(item, index) in list"
						:key="`localization-dropdown-item--${index}`"
					>
						<button
							type="button"
							dir="auto"
							:class="[
								'localization-dropdown-button',
								{
									'localization-dropdown-button--active':
										selected === item.isoCode,
								},
							]"
							:data-iso-code="item.isoCode"
							@mouseover="hoverDropdownItem($event)"
							@mouseleave="leaveDropdownItem($event)"
							@click="setLocalization($event, item.isoCode)"
							@keyup.enter="setLocalization($event, item.isoCode)"
							@keyup.space="setLocalization($event, item.isoCode)"
							v-text="getItemText(item)"
						/>
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script>
	import dropdown from '@/mixins/dropdown.js';
	export default {
		mixins: [dropdown],
		props: {
			name: String,
		},
		data() {
			return {
				flagImageSrcBase: `${process.env.CDN_URL}/static/images/flags/`,
			};
		},
		computed: {
			list() {
				return this.$store.state.common.localizations[this.name] || [];
			},
			label() {
				switch (this.name) {
					case 'country':
						return this.$__('Your country');

					case 'currency':
						return this.$__('Your currency');

					case 'language':
						return this.$__('Your language');

					default:
						return '';
				}
			},
			localization() {
				return this.$store.state.user.localization;
			},
			selected: {
				get() {
					return this.localization[this.name];
				},
				set(isoCode) {
					this.$store.commit('user/SET_LOCALIZATION', {
						...this.localization,
						[this.name]: isoCode,
					});
				},
			},
			selectedText() {
				const name = this.name;
				const selected = this.list.find((item) => {
					return item.isoCode === this.selected;
				});
				const selectedText = '';

				if (typeof selected === 'undefined') {
					return selectedText;
				}

				switch (name) {
					case 'country':
						return selected.title;

					case 'currency':
						return (
							selected.symbol +
							(selected.symbol !== selected.isoCode
								? ' ' + selected.isoCode
								: '')
						);

					case 'language':
						return selected.translation;

					default:
						return selectedText;
				}
			},
			selectedFlagStyle() {
				if (!this.selected) {
					return '';
				}

				return `background-image:url(${
					this.flagImageSrcBase
				}${this.selected.toLowerCase()}.svg)`;
			},
			isLoading() {
				return this.$store.state.ui.isLocalizationLoading;
			},
		},
		methods: {
			getItemText(item) {
				switch (this.name) {
					case 'country':
						return item.title;

					case 'currency':
						return item.symbol + ' (' + item.title + ')';

					case 'language':
						return item.translation + ' (' + item.title + ')';

					default:
						return '';
				}
			},
			setLocalization(event, isoCode) {
				switch (this.name) {
					case 'country':
						this.$track('country-select', {
							old: this.selected,
							new: isoCode,
						});
						break;

					case 'currency':
						this.$track('currency-select', {
							old: this.selected,
							new: isoCode,
						});
						break;

					default:
						this.$track('country-select', {
							old: this.selected,
							new: isoCode,
						});
						break;
				}

				this.selected = isoCode;
				const selectedItem = event.currentTarget;
				setTimeout(() => {
					this.$store.dispatch('user/setLocalization', {
						name: this.name,
						selectedItem,
					});
					// for future use, change deltaImp based on country
					// this.$deltaImp.parseForCookie(this.$store);
				}, 500);

				this.close();
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/localization-dropdown.scss';
</style>
