export const get = (store) => {
	const name = store.state.user.localStorage.names.gdprConsent;

	return store.getters['user/GET_LOCAL_STORAGE']({
		name,
	});
};

export const set = (store, isConsentGranted) => {
	const name = store.state.user.localStorage.names.gdprConsent;

	return store.commit('user/SET_LOCAL_STORAGE', {
		name,
		data: isConsentGranted ? 1 : 0,
	});
};

export const isValid = (consent) => {
	return ['0', '1'].includes(consent);
};

export const isGranted = (consent) => {
	return consent === '1';
};
