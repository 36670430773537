<template>
	<ul class="footer-block--categories">
		<li v-for="category in categories" :key="category.group">
			<div class="footer-block--categories-title">
				{{ category.group }}
			</div>
			<ul class="footer-block--subcategories">
				<li v-for="child in category.children" :key="child.url">
					<NuxtLink
						:to="child.url"
						@click.native="navigate(child.url)"
					>
						{{ child.name }}
					</NuxtLink>
				</li>
			</ul>
		</li>
	</ul>
</template>

<script>
	import animatedScrollTo from 'animated-scroll-to';

	export default {
		computed: {
			categories() {
				return this.$store.state.common.footerMenu;
			},
		},
		methods: {
			navigate(url) {
				if (this.$route.path === url) {
					animatedScrollTo(0, { speed: 250 });
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.footer-block {
		&--categories {
			@include media-breakpoint-down(sm) {
				display: block;
			}
			@extend %has-paddings;
			@extend %has-paddings-b;
			flex: 1 1 100%;
			display: flex;
			justify-content: space-between;
			gap: 20px;
			&-title {
				@extend %has-half-paddings-b;
				font-weight: bold;
			}
		}
		&--subcategories {
			@include media-breakpoint-down(sm) {
				@extend %has-paddings-b;
			}
			display: flex;
			flex-wrap: wrap;
			& > li {
				flex-basis: 50%;
				max-width: 50%;
				text-overflow: ellipsis;
				overflow-x: hidden;
				white-space: nowrap;
			}
		}
	}
</style>
