<template>
	<transition
		name="slide-menu-transition"
		mode="out-in"
		@before-enter="onEnterTransitionBegins()"
		@after-enter="onEnterTransitionEnds()"
		@after-leave="onLeaveTransitionEnds()"
	>
		<div v-if="$store.state.mobileNavigation.isMenuOn" class="slide-menu">
			<div class="slide-menu-container">
				<OverlayNavMobileV2 />
			</div>
		</div>
	</transition>
</template>

<script>
	import OverlayNavMobileV2 from '@/components/Navigation/Mobile/OverlayNavMobileV2';
	export default {
		components: {
			OverlayNavMobileV2,
		},
		beforeDestroy() {
			this.$store.dispatch('ui/toggleBodyfix', false);
		},
		methods: {
			onEnterTransitionBegins() {
				this.$store.dispatch('ui/toggleBodyfix', true);

				if (!process.browser) return;

				window.scrollTo(0, 0);
				this.previouslyFocusedElement = document.activeElement;
				if (this.$mq.is('md')) {
					this.$store.commit('ui/SET_DATA', {
						isNavMobileBackdropOn: true,
					});
				}
			},
			onEnterTransitionEnds() {},
			onLeaveTransitionEnds() {
				this.$store.dispatch('ui/toggleBodyfix', false);
				if (this.$mq.is('md')) {
					this.$store.commit('ui/SET_DATA', {
						isNavMobileBackdropOn: false,
					});
				}
			},
		},
	};
</script>

<style lang="scss">
	.slide-menu {
		& {
			@include media-breakpoint-up(lg) {
				display: none;
			}
			@include media-breakpoint-only(md) {
				top: 0;
				height: 100%;
				width: 40%;
			}
			position: fixed;
			top: 50px;
			left: 0;
			z-index: 105;
			width: 100%;
			height: calc(100% - 110px);
			overflow-y: none;
			background-color: white;
		}
		&-container {
			overflow-y: scroll;
			&::-webkit-scrollbar {
				opacity: 0;
				width: 0;
				display: none;
				background-color: transparent;
			}
			height: 100%;
		}
		&-body {
			height: 100%;
			background-color: $white;
		}
		&-transition {
			&-enter-active {
				transition: transform $nav--mobile-transition-duration
					$overlay-backdrop-transition-duration $transition-style;
			}
			&-leave-active {
				transition: transform $transition-duration $transition-style;
			}
			&-enter,
			&-leave-to {
				transform: translate3d(-100%, 0, 0);
				@include rtl() {
					transform: translate3d(100%, 0, 0);
				}
			}
		}
	}
</style>
