<template>
	<transition name="backdrop-transition">
		<div
			v-if="isVisible"
			:class="['backdrop', { 'has-spinner': hasSpinner }]"
		>
			<Spinner v-if="hasSpinner" />
		</div>
	</transition>
</template>

<script>
	export default {
		computed: {
			isVisible() {
				return (
					this.$store.state.ui.isBackdropOn ||
					this.$store.state.ui.isNavMobileBackdropOn
				);
			},
			hasSpinner() {
				return (
					this.$store.state.ui.isOverlayOn &&
					!this.$store.state.ui.overlayData
				);
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/backdrop.scss';
</style>
