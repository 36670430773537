export default ({ route, store }) => {
	const popup = store.state.user.welcomePopup;

	if (Array.isArray(popup)) return;

	const isFunctionalPopup = popup.type === 'overlay';

	const localStorageName = 'welcome_popup';
	const localStorageValue = String(popup.id);

	const isNewsletter = popup.type === 'newsletter-popup';
	const isInCheckout = route.fullPath.startsWith('/checkout');
	const hasBeenShown =
		localStorageValue ===
		store.getters['user/GET_LOCAL_STORAGE']({
			name: localStorageName,
		});

	if (!isFunctionalPopup && (isInCheckout || hasBeenShown)) {
		return;
	}

	setTimeout(() => {
		show();
	}, popup.delay);

	const show = () => {
		const overlayType =
			!isFunctionalPopup && (isNewsletter ? 'newsletter' : 'picture');
		const overlayData = popup;

		if (store.state.ui.isOverlayOn) {
			setTimeout(() => {
				show();
			}, 2000);
			return;
		}
		store.dispatch('ui/toggleOverlay', {
			overlayType,
			overlayData,
		});

		store.commit('user/SET_LOCAL_STORAGE', {
			name: localStorageName,
			data: encodeURIComponent(localStorageValue),
		});
	};
};
