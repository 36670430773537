export default (_, inject) => {
	const rakuten = {
		init() {
			const id = '115388';

			if ('false' === 'true') {
				console.log(
					'[rakuten-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					'init',
					{ id }
				);
			}

			if ('false' === 'true') {
				return;
			}

			(function (url) {
				if (!window.DataLayer) {
					window.DataLayer = {};
				}
				if (!DataLayer.events) {
					DataLayer.events = {};
				}
				DataLayer.events.SPIVersion =
					DataLayer.events.SPIVersion || '3.4';
				DataLayer.events.SiteSection = '1';
				let loc;
				const ct = document.createElement('script');
				ct.type = 'text/javascript';
				ct.async = true;
				ct.src = url;
				loc = document.getElementsByTagName('script')[0];
				loc.parentNode.insertBefore(ct, loc);
			})(
				document.location.protocol + `//tag.rmp.rakuten.com/${id}.ct.js`
			);
		},
		conversion(order) {
			const rm_trans = getConvertionDataLayer(order);

			if ('false' === 'true') {
				console.log(
					'[rakuten-module]',
					`(${
						'false' === 'true'
							? 'disabled'
							: 'enabled'
					})`,
					'conversion',
					rm_trans
				);
			}

			if ('false' === 'true') {
				return;
			}

			window.DataLayer
				? ((DataLayer.events = DataLayer.events || {}),
				  (DataLayer.Sale = DataLayer.Sale || { Basket: rm_trans }),
				  (DataLayer.Sale.Basket = DataLayer.Sale.Basket || rm_trans))
				: (window.DataLayer = {
						Sale: { Basket: rm_trans },
						events: {},
				  }),
				(DataLayer.events.SPIVersion = '3.4'),
				(DataLayer.Sale.Basket.Ready = !0);
		},
		load() {
			this.init();
		},
	};

	inject('rakuten', rakuten);
};

export const getConvertionDataLayer = (order) => {
	const ranMID = '45838';

	return {
		affiliateConfig: {
			ranMID,
			discountType: 'item',
			includeStatus: 'false',
			taxRate: 20,
			removeTaxFromDiscount: true,
		},
		orderid: order.summary.reference,
		currency: 'EUR',
		customerStatus: 'Existing',
		conversionType: 'Sale',
		discountAmount: getOrderDiscountAmount(order),
		discountCode: getOrderDiscountCode(order),
		lineitems: getOrderItems(order),
		optionalData: {
			altord: order.summary.id,
		},
	};
};

export const getOrderItems = (order) => {
	return order.products.map((product) => {
		return {
			quantity: product.variation.quantity,
			unitPrice: product.variation.unitPriceEuroReducedRaw,
			SKU: product.variation.id,
			productName: product.title,
			optionalData: {
				brand: product.brand.title,
				cat: getOrderItemCategories(product),
				issale: getOrderItemSaleStatus(product),
			},
		};
	});
};

export const getOrderDiscountAmount = (order) => {
	const amount = order.summary.total.amounts.discountEuroRaw;

	return amount ? -1 * amount : 0;
};

export const getOrderDiscountCode = (order) => {
	return order.summary.voucher.entries
		.map((voucher) => voucher.code)
		.join(',');
};

export const getOrderItemCategories = (product) => {
	return product.categories.join('>');
};

export const getOrderItemSaleStatus = (product) => {
	return product.variation.unitPriceEuroRegularRaw >
		product.variation.unitPriceEuroReducedRaw
		? 'Y'
		: 'N';
};
