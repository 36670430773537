export default {
	containerClass: 'has-objectfit',
	containerFallbackClass: 'has-objectfit-fallback',
	imageClass: 'is-objectfit',
	fallback(image) {
		if (
			!image.classList.contains(this.imageClass) ||
			!process.client ||
			window.Modernizr.objectfit
		) {
			return;
		}

		const container = this.getContainerEl(image);
		const fit = image.getAttribute('object-fit') || 'cover';
		const position =
			image.getAttribute('object-position') || 'center center';
		const src = image.getAttribute('src');

		if (src) {
			image.style.opacity = '0';
			container.style.backgroundSize = fit;
			container.style.backgroundPosition = position;
			container.style.backgroundImage = 'url(' + src + ')';
			container.style.backgroundRepeat = 'no-repeat';
			container.classList.add(this.containerFallbackClass);
		}
	},
	getContainerEl(el) {
		while (el.parentNode) {
			el = el.parentNode;
			if (el.classList.contains(this.containerClass)) {
				return el;
			}
		}
		return null;
	},
};
