<template>
	<button
		type="button"
		:title="isNavTabletOn ? $__('Close main menu') : $__('Open main menu')"
		:aria-label="
			isNavTabletOn ? $__('Close main menu') : $__('Open main menu')
		"
		@click.stop="onClick()"
	>
		<slot />
	</button>
</template>

<script>
	export default {
		computed: {
			isNavTabletOn() {
				return this.$store.state.ui.isNavTabletOn;
			},
		},
		methods: {
			onClick() {
				this.$accessor.mobileNavigation.TOGGLE_NAV_MOBILE();
			},
		},
	};
</script>
