<template>
	<div :class="`getapp-${position}-container`">
		<div v-for="appLink in appLinks" :key="appLink.url">
			<a :href="appLink.url" target="_blank" @click="onClick">
				<img :src="appLink.img" :class="`getapp-${position}-img`" />
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['position'],
		computed: {
			device() {
				return this.$store.state.ui.device;
			},

			utmSource() {
				const list = {
					footer: 'banner_footer_web',
					menu: 'menu_mobile_web',
				};
				return list[this.position];
			},
			appLinks() {
				return [
					{
						url: `https://apps.apple.com/app/apple-store/id1597308965?pt=120458505&ct=${this.utmSource}&mt=8`,
						img: `${process.env.CDN_URL}/static/images/getapp/appstore.svg`,
					},
					{
						url: `https://play.google.com/store/apps/details?id=com.bambinifashion.production&utm_source=${this.utmSource}`,
						img: `${process.env.CDN_URL}/static/images/getapp/playstore.svg`,
					},
				];
			},
		},
		methods: {
			onClick() {
				this.$track('get-app-button', {
					utm: this.utmSource,
					device: this.device.isDesktop
						? 'Desktop'
						: this.device.isIOS
						? 'iPhone'
						: 'Android',
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.getapp-footer {
		&-container {
			@include media-breakpoint-down(sm) {
				order: 0;
				flex-direction: row;
				justify-content: space-between;
			}
			@extend %has-paddings-x;
			@extend %has-double-paddings-t;
			@extend %has-half-paddings-b;
			display: flex;
			justify-content: space-evenly;
			flex-direction: column;
			align-items: center;
			order: 1;
		}
		&-img {
			height: 45px;
		}
	}
	.getapp-menu {
		&-container {
			@include media-breakpoint-down(sm) {
				flex-direction: row;
				justify-content: space-between;
			}
			@extend %has-half-paddings-t;
			flex: 1 1 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		&-img {
			height: 45px;
		}
	}
</style>
