import isStorageAvailable from 'storage-available';

const lsPrefix = 'bf-ad-params-';
const lsName = 'current-source';

export const state = () => ({
	params: {},
});

export const getters = {
	getParams() {
		if (!isStorageAvailable('localStorage')) return;
		return JSON.parse(localStorage.getItem(lsPrefix + lsName));
	},
};

export const mutations = {
	SET_LOCAL_STORAGE(state, payload) {
		if (!isStorageAvailable('localStorage')) return;

		localStorage.setItem(lsPrefix + lsName, JSON.stringify(payload));
	},
	SET_PARAMS(state, payload) {
		state.params = payload;
	},
};

export const actions = {
	async saveQueryParams({ dispatch, commit, getters }, params) {
		if (!params) {
			commit('SET_PARAMS', getters.getParams);
			return;
		}
		commit('SET_LOCAL_STORAGE', params);
		commit('SET_PARAMS', params);
	},
};
