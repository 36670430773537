export const isToday = (day, dateNow) => {
	return day.dayOfWeek === dateNow.getDay();
};

export const isOpen = (day, dateNow) => {
	if (!isToday(day, dateNow)) {
		return false;
	}

	const timeZone = 'Europe/Vienna';

	try {
		const viennaTime = new Date().toLocaleString('en-US', {
			timeZone,
		});
		const dateVienna = new Date(viennaTime);
		const nowViennaMinutes =
			dateVienna.getUTCHours() * 60 + dateVienna.getUTCMinutes();
		const nowHereMinutes =
			dateNow.getUTCHours() * 60 + dateNow.getUTCMinutes();
		const offsetViennaMinutes = nowHereMinutes - nowViennaMinutes;
		const startMinutes =
			parseInt(day.start.hh) * 60 +
			parseInt(day.start.mm) +
			offsetViennaMinutes;
		const endMinutes =
			parseInt(day.end.hh) * 60 +
			parseInt(day.end.mm) +
			offsetViennaMinutes;
		const nowHereMinutesWithOffset =
			nowHereMinutes - dateNow.getTimezoneOffset();

		return (
			startMinutes <= nowHereMinutesWithOffset &&
			endMinutes > nowHereMinutesWithOffset
		);
	} catch (error) {
		return false;
	}
};
