import { getProduct } from '@/api/get-product';

export const state = () => ({
	content: {},
	meta: {},
});

export const getters = {
	getRecursiveCategory: (state, getters) => (id, categories) => {
		for (let i = 0; i < categories.length; i++) {
			const category = categories[i];
			if (category.id === id) {
				return category;
			}

			if (category.categories) {
				const result = getters.getRecursiveCategory(
					id,
					category.categories
				);
				if (result !== false) {
					return result;
				}
			}
		}

		return false;
	},

	getCategoryById: (state, getters) => (id) => {
		if (!state.content.filter || !state.content.filter.sections) {
			return null;
		}

		const categorySection = state.content.filter.sections.find((e) =>
			['category', 'subcategory'].includes(e.type)
		);
		if (!categorySection) {
			return null;
		}
		const categories = categorySection.categories;
		if (!categories) {
			return null;
		}

		return getters.getRecursiveCategory(id, categories);
	},
	GET_META: (state, getters, rootState) => (route) => {
		const page =
			Object.keys(state.meta).length !== 0
				? state.meta
				: {
						title: 'Kids Designer Clothes',
						description:
							'Shop the latest kids designer clothes, lifestyle accessories &amp; shoes for babies, boys &amp; girls with 240+ luxury brands at BAMBINIFASHION.COM.',
				  };

		const entries = [
			{ charset: 'utf-8' },
			{
				hid: 'application-name',
				name: 'application-name',
				content: 'BAMBINI',
			},
			{
				hid: 'viewport',
				name: 'viewport',
				content:
					'width=device-width, initial-scale=1, shrink-to-fit=no',
			},

			{
				hid: 'apple-mobile-web-app-capable',
				name: 'apple-mobile-web-app-capable',
				content: 'yes',
			},
			{
				hid: 'apple-mobile-web-app-title',
				name: 'apple-mobile-web-app-title',
				content: 'BAMBINI',
			},
			{
				hid: 'apple-mobile-web-app-status-bar-style',
				name: 'apple-mobile-web-app-status-bar-style',
				content: 'white',
			},
			{
				hid: 'format-detection',
				name: 'format-detection',
				content: 'telephone=no',
			},
			{
				hid: 'msapplication-TileColor',
				name: 'msapplication-TileColor',
				content: '#e0f4ee',
			},
			{ hid: 'theme-color', name: 'theme-color', content: '#ffffff' },
			{
				hid: 'description',
				name: 'description',
				content: page.description,
			},
			{
				hid: 'og:type',
				property: 'og:type',
				content: 'article',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: page.title,
			},
			{
				hid: 'og:url',
				property: 'og:url',
				content: `${process.env.HOST_URL}${route.fullPath}`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: page.description,
			},
			{
				hid: 'og:image',
				property: 'og:image',
				content: `${process.env.HOST_URL}/apple-touch-icon.png`,
			},
			{
				hid: 'og:image:type',
				property: 'og:image:type',
				content: 'image/png',
			},
			{
				hid: 'og:image:width',
				property: 'og:image:width',
				content: 180,
			},
			{
				hid: 'og:image:height',
				property: 'og:image:height',
				content: 180,
			},
		];
		const canonicalUrl = page.canonicalUrl
			? page.canonicalUrl
			: process.env.HOST_URL + route.path.replace(/\/page=\d+/, '');
		return {
			title: `${page.title} | BAMBINIFASHION.COM`,
			canonicalUrl,
			entries,
		};
	},
};

export const actions = {
	async loadProduct({ dispatch, commit }, productId) {
		const response = await getProduct(productId);

		commit('SET_DATA', response.page);
	},
};

export const mutations = {
	SET_DATA(state, data) {
		state = Object.assign(state, data);
		if (data?.content?.queryId) {
			if (process.browser) {
				localStorage.setItem('queryId', data?.content?.queryId);
			}
		}
	},
	SET_CATEGORY_LIST_INFINITE_PAGE(state, { isLeading, page = null }) {
		const pages = state.content.infinite;

		if (!pages) {
			return;
		}

		if (page) {
			if (isLeading) {
				pages.unshift(page);
			} else {
				pages.push(page);
			}
		} else if (isLeading) {
			pages.pop();
		} else {
			pages.shift();
		}
	},
	SET_EXPANDED_FILTERS(state, payload) {
		const expandedSections = Object.entries(payload).reduce(
			(acc, [item, value]) => {
				return value ? [item, ...acc] : acc;
			},
			[]
		);
		state.content.filter.sections.map((section) => {
			expandedSections.includes(section.name)
				? (section.isExpanded = true)
				: (section.isExpanded = false);
			return section;
		});
	},
};
