var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],class:['localization-dropdown', 'localization-dropdown--' + _vm.name]},[_c('button',{staticClass:"localization-dropdown-toggle",attrs:{"id":'localization-dropdown-toggle--' + _vm.name,"type":"button","title":_vm.label,"aria-label":_vm.label,"aria-expanded":_vm.isOpen ? 'true' : 'false',"data-iso-code":_vm.selected,"aria-haspopup":"true","disabled":_vm.isLoading},on:{"click":function($event){return _vm.toggle()}}},[(_vm.name === 'country')?_c('span',{class:['localization-dropdown-flag'],style:(_vm.selectedFlagStyle)}):_vm._e(),_vm._v("\n\t\t"+_vm._s(_vm.selectedText)+"\n\t")]),_vm._v(" "),_c('transition',{attrs:{"name":"localization-dropdown-transition"}},[(_vm.isOpen)?_c('div',{class:['localization-dropdown-inner'],attrs:{"aria-labelledby":'localization-dropdown-toggle--' + _vm.name}},[_c('ul',{class:['localization-dropdown-list']},_vm._l((_vm.list),function(item,index){return _c('li',{key:`localization-dropdown-item--${index}`},[_c('button',{class:[
							'localization-dropdown-button',
							{
								'localization-dropdown-button--active':
									_vm.selected === item.isoCode,
							},
						],attrs:{"type":"button","dir":"auto","data-iso-code":item.isoCode},domProps:{"textContent":_vm._s(_vm.getItemText(item))},on:{"mouseover":function($event){return _vm.hoverDropdownItem($event)},"mouseleave":function($event){return _vm.leaveDropdownItem($event)},"click":function($event){return _vm.setLocalization($event, item.isoCode)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.setLocalization($event, item.isoCode)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.setLocalization($event, item.isoCode)}]}})])}),0)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }