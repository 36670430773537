export default ({ app, store }) => {
	app.router.beforeEach((to, from, next) => {
		if (store.state.ui.isFilterPage) {
			const page = { ...store.state.page };

			store.dispatch('user/updateRecentCategory', {
				path: from.path,
				scrollPosition: window.scrollY,
				page,
			});
		}
		next();
	});
};
