<template>
	<div
		:class="['proline', { 'has-highlight': isHighlight }]"
		:style="{ color: highlightColor }"
	>
		<div
			class="proline-highlight"
			:style="{ backgroundColor: highlightBackground }"
		/>
		<div v-if="!isEmpty" class="proline-container">
			<div class="proline-left" v-text="proline.left" />
			<Rotaterator
				v-if="hasRotateratorItems"
				class="proline-center"
				:items="proline.center.items"
				@highlight-parent="highlight"
			/>
			<div class="proline-right" v-text="proline.right" />
		</div>
	</div>
</template>

<script>
	import Rotaterator from './Rotaterator.vue';
	export default {
		components: {
			Rotaterator,
		},
		data() {
			return {
				isHighlight: false,
				highlightColor: null,
				highlightBackground: null,
			};
		},
		computed: {
			proline() {
				return this.$store.state.user.proline;
			},
			hasRotateratorItems() {
				return (
					this.proline.center && this.proline.center.items.length > 0
				);
			},
			isEmpty() {
				return (
					!this.proline ||
					(!this.proline.left &&
						!this.proline.right &&
						!this.proline.hasRotateratorItems)
				);
			},
		},
		methods: {
			highlight({ isHighlight, backgroundColor, textColor }) {
				this.isHighlight = isHighlight;
				this.highlightColor = isHighlight ? textColor : null;
				this.highlightBackground = isHighlight ? backgroundColor : null;
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/proline.scss';
</style>
