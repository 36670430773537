import nodeCrypto from 'crypto';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padding from 'crypto-js/pad-zeropadding';

export default ({ app, store }) => {
	const cookieName = `bf_${store.state.user.cookie.names.hash}`;

	let hash = app.$cookies.get(cookieName);

	if (typeof hash === 'undefined') {
		hash = `web${getRandom(10)}`;

		app.$cookies.set(cookieName, hash, {
			domain: process.env.COOKIE_DOMAIN,
			sameSite: 'lax',
			secure: 1,
			path: '/',
			expires: new Date('31 Dec 2099 23:59:59 UTC'),
		});
	}

	store.commit('user/SET_DATA', { hash });
};

export const getRandom = (length, radix = 36) => {
	if (parseInt(length) === 0 || typeof length !== 'number') {
		return '';
	}

	const random = process.browser
		? crypto.getRandomValues(new Uint8Array(Math.ceil(length / 2)))
		: nodeCrypto.webcrypto.getRandomValues(
				new Uint8Array(Math.ceil(length / 2))
		  );

	return [...new Uint8Array(random.buffer)]
		.map((x) => x.toString(radix).padStart(2, '0'))
		.join('')
		.substr(0, length);
};

export const getSalted = (payload) => {
	if (payload == null) {
		return payload;
	}

	const salt = [getRandom(10, 16), getRandom(10, 16), getRandom(10, 16)];
	const key = getRandom(2, 16);

	const saltBytes = encHex.parse([...salt, '17'].join(''));
	const iv = encHex.parse([...salt, key].join(''));

	const encrypted = aes
		.encrypt(JSON.stringify(payload), saltBytes, {
			iv,
			padding,
		})
		.toString();

	return [encrypted, salt[2], salt[0], salt[1], key];
};
