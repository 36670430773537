export const getFormattedCount = ({ duration, hasGlitch = false }) => {
	let seconds = Math.floor(duration % 60);
	let minutes = Math.floor((duration / 60) % 60);
	let hours = Math.floor(duration / (60 * 60));

	if (hasGlitch) {
		hours = hours >= 25 ? (hours % 24) + 1 : hours;
	}

	hours = hours < 10 ? '0' + hours : hours;
	minutes = minutes < 10 ? '0' + minutes : minutes;
	seconds = seconds < 10 ? '0' + seconds : seconds;

	return `${hours}:${minutes}:${seconds}`;
};
