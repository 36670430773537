import useAppMeta from '@/use/app-meta';

export function addClientMetaHeader(path, token, params) {
	const { name, content } = useAppMeta({ path, token, params });

	const meta = document.createElement('meta');
	meta.name = name;
	meta.content = content;
	document.getElementsByTagName('head')[0].appendChild(meta);
}
